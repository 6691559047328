/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Alert, Box, Link, Popover, styled } from "@mui/material";
import { AI_CREDIT_PLAN_KEY } from "utils/plans";
import { useUsers } from "api/userService";
import { useSelector } from "react-redux";
import { selectUser } from "store/features/session/slice";
import { isAdmin } from "models/components/Permissions.models";
import { companyFeatures } from "company-config";
import { useNavigate } from "react-router-dom";
import routePaths from "routes/routePaths";
import { Trans, useTranslation } from "react-i18next";

const Wrapper = styled(Box)(() => ({
  width: "450px",
}));

const RestrictionPopover: React.FC<{
  organizationId: number;
  accessMessage: string;
  upgradeMessage: string;
  planTier: number;
  anchor: Element;
  onClose: () => void;
}> = ({
  organizationId,
  accessMessage,
  upgradeMessage,
  planTier,
  anchor,
  onClose,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const planName = AI_CREDIT_PLAN_KEY[planTier];
  const { users } = useUsers(organizationId);
  const currentUserRole = users?.find((u) => u.id === user?.id)?.role;

  return (
    <Popover
      open
      anchorEl={anchor}
      onClose={() => onClose()}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      sx={{
        zIndex: 1301,
      }}
    >
      <Wrapper>
        <Alert severity="warning">
          {companyFeatures.settings.canUpgradePlans ? (
            <>
              {t("plan_requirement", { planName, accessMessage })}{" "}
              {isAdmin(currentUserRole) ? (
                <>
                  <Trans
                    i18nKey="upgrade_plan_prompt"
                    values={{
                      upgradeMessage,
                    }}
                    components={{
                      a: (
                        <Link
                          color="secondary"
                          fontWeight={500}
                          underline="hover"
                          onClick={() => {
                            navigate(routePaths.workspaceBilling);
                          }}
                        />
                      ),
                    }}
                  />
                </>
              ) : (
                t("contact_workspace_admin")
              )}
            </>
          ) : (
            t("feature_restrictions")
          )}
        </Alert>
      </Wrapper>
    </Popover>
  );
};

export default RestrictionPopover;
