import React, {
  CSSProperties,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { DocumentReadResponse, Tag } from "models/api/response.types";
import {
  Box,
  Checkbox,
  Tooltip,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import detexify, { applyDocumentDetexification } from "utils/detexify";
import { useSelector } from "react-redux";
import SimCardAlertOutlinedIcon from "@mui/icons-material/SimCardAlertOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  selectCurrentOrganizationId,
  selectUser,
} from "store/features/session/slice";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import AnnouncementOutlinedIcon from "@mui/icons-material/AnnouncementOutlined";
import ManageHistoryOutlinedIcon from "@mui/icons-material/ManageHistoryOutlined";
import CloudSyncOutlinedIcon from "@mui/icons-material/CloudSyncOutlined";
import FormatClearOutlinedIcon from "@mui/icons-material/FormatClearOutlined";
import RunningWithErrorsOutlinedIcon from "@mui/icons-material/RunningWithErrorsOutlined";
import SyncProblemIcon from "@mui/icons-material/SyncProblem";
import displayTextWithMath from "utils/displayTextWithMath";
import {
  getDocumentStatus,
  isStub as isStubType,
  isTable as isTableType,
} from "utils/documentStatus";
import { companyFeatures } from "company-config";
import { searchTextConverter, setAuthorsData } from "utils/documentListHelpers";
import useTelemetry, { telemetryAction } from "utils/useTelemetry";
import { bibtexEntry2html } from "utils/bibtex";
import clsx from "clsx";
import { SearchType, selectIsSearch } from "store/features/browser/slice";
import TagsListStandalone from "components/Tags/tagsCellRenderer";
import { isGuest } from "models/components/Permissions.models";
import { useTags } from "api/tagService";
import { useUsers } from "api/userService";
import { useLocation } from "react-router-dom";
import routePaths from "routes/routePaths";
import { useTranslation } from "react-i18next";

const Wrapper = styled(Box)(({ theme }) => ({
  overflow: "hidden",
  "& .document-item": {
    display: "flex",
    flexDirection: "row",
    transition: "background 0.15s, opacity 0.15s",
    backgroundColor: theme.background.light,
    borderBottom: `1px solid ${theme.grey.light}`,
    borderLeft: `1px solid ${theme.grey.light}`,
    borderRight: `1px solid ${theme.grey.light}`,
    padding: 0,
    paddingLeft: "0.5rem",
    cursor: "default",
    position: "relative",
    "&.canSelect": {
      cursor: "pointer",
    },
    "&:not(.selected):hover": {
      background: theme.document.hover.main,
    },
    "&.selected": {
      background: theme.document.selected.main,
      "&:hover": {
        background: theme.document.selected.secondary,
      },
    },
    "& .document-checkbox": {
      padding: "6px",
      height: "fit-content",
      marginTop: "0.25rem",
      "&:not(.Mui-checked)": {
        color: "rgba(0, 0, 0, 0.12)",
      },
    },
    "& .doc-type-icon": {
      padding: "10px 0",
      marginRight: "0.5rem",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "0.3rem",
      opacity: 0,
      "&.visible": {
        opacity: 1,
      },
    },
    "& .summary": {
      padding: "8px",
      paddingLeft: "0",
      flex: 1,
      "& .title": {
        fontSize: "16px",
        display: "-webkit-box",
        width: "fit-content",
        WebkitLineClamp: "1",
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        cursor: "default",
        "&.document": {
          color: theme.palette.secondary.main,
        },
        "&.table-source": {
          color: "#52796f",
        },
        "&.active": {
          cursor: "pointer",
          "&:hover": {
            textDecoration: "underline",
          },
        },
      },
      "& .secondary-data": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "0.5rem",
        width: "100%",
        "& .authors, & .search-snippet, & .description": {
          minWidth: "80px",
          fontSize: "14px",
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        "& .authors": {
          WebkitLineClamp: "1",
          color: theme.document.compact.authors,
        },
        "& .description": {
          WebkitLineClamp: "1",
          color: theme.palette.text.secondary,
        },
        "& .search-snippet": {
          color: "#6c6c6c",
          WebkitLineClamp: "2",
          "& em": {
            fontStyle: "normal",
          },
        },
        "& .tags-wrapper": {
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
        },
        "& .compact-document-data": {
          minWidth: "200px",
          textAlign: "right",
          display: "-webkit-box",
          WebkitLineClamp: "1",
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          fontSize: "14px",
          "& .journal": {
            color: theme.document.compact.journal,
          },
          "& .year": {
            color: theme.document.compact.year,
          },
        },
      },
    },
    "& .error-container": {
      padding: "0 16px",
      display: "flex",
      alignItems: "center",
      borderLeft: `1px solid ${theme.grey.light}`,
      "& .status-icon": {
        width: "20px !important",
        height: "20px !important",
      },
    },
    "& em": {
      backgroundColor: theme.notice.main,
      fontStyle: "normal",
    },
  },
}));

const getError = (document: DocumentReadResponse) => {
  const { t } = useTranslation();
  const { failed, in_progress } = getDocumentStatus(document);
  const inTrash = document.is_trash;
  const embeddingsInProgress = document?.status?.embeddings < 2;
  const embeddingsFailed = document?.status?.embeddings > 2;
  const needManualIndexing = document?.status?.embeddings === 4;

  if (inTrash) {
    return (
      <Tooltip
        placement="top"
        enterDelay={500}
        title={t("trash_documents_not_available_ai")}
      >
        <DeleteOutlineOutlinedIcon
          className="status-icon"
          color="error"
          fontSize="small"
        />
      </Tooltip>
    );
  }
  if (isStubType(document)) {
    return (
      <Tooltip
        placement="top"
        enterDelay={500}
        title={t("stub_documents_not_available_ai")}
      >
        <SimCardAlertOutlinedIcon
          className="status-icon"
          color="warning"
          fontSize="small"
        />
      </Tooltip>
    );
  }
  if (failed) {
    return (
      <Tooltip
        placement="top"
        enterDelay={500}
        title={t("failed_to_process_document")}
      >
        <SyncProblemIcon
          className="status-icon"
          color="error"
          fontSize="small"
        />
      </Tooltip>
    );
  }
  if (in_progress) {
    return (
      <Tooltip
        placement="top"
        enterDelay={500}
        title={t("document_extraction_in_progress")}
      >
        <CloudSyncOutlinedIcon
          className="status-icon"
          color="secondary"
          fontSize="small"
        />
      </Tooltip>
    );
  }
  if (embeddingsInProgress) {
    return (
      <Tooltip
        placement="top"
        enterDelay={500}
        title={t("document_still_being_analyzed")}
      >
        <ManageHistoryOutlinedIcon
          className="status-icon"
          color="secondary"
          fontSize="small"
        />
      </Tooltip>
    );
  }
  if (embeddingsFailed) {
    <Tooltip
      placement="top"
      enterDelay={500}
      title={t("ai_text_analysis_failed")}
    >
      <RunningWithErrorsOutlinedIcon
        className="embedding-icon"
        color="error"
        fontSize="small"
      />
    </Tooltip>;
  }
  return (
    <Tooltip placement="top" enterDelay={500} title={t("cannot_extract_text")}>
      <FormatClearOutlinedIcon
        className="status-icon"
        color="error"
        fontSize="small"
      />
    </Tooltip>
  );
};

const AIContextDialogDocument: React.FC<{
  document: DocumentReadResponse;
  selectedDocuments?: DocumentReadResponse[];
  style?: CSSProperties;
  showErrorIfExist?: boolean;
  canSelect?: boolean;
  searchQuery?: string;
  searchTypeQuery?: SearchType;
  setTableDocumentToUpdate: (doc: DocumentReadResponse) => void;
  setSelectedDocuments?: (documents: DocumentReadResponse[]) => void;
  multiSelectDocuments?: (id: number, e: any) => void;
}> = ({
  document: propDocument,
  selectedDocuments,
  style,
  showErrorIfExist,
  setTableDocumentToUpdate,
  canSelect,
  searchQuery = "",
  searchTypeQuery,
  setSelectedDocuments,
  multiSelectDocuments,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const user = useSelector(selectUser);
  const isSearch = useSelector(selectIsSearch);
  const { logAction } = useTelemetry();
  const document = applyDocumentDetexification(propDocument);
  const [searchSnippet, setSearchSnippet] = useState<string>("");
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const { getCachedTagById } = useTags(currentOrganizationId);
  const summaryRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const [tagsCount, setTagsCount] = useState(5);

  const { users } = useUsers(currentOrganizationId);
  const currentUserRole = users?.find((u) => u.id === user?.id)?.role;

  const { isStub, isTable, defaultTitle, defaultDescription } = useMemo(() => {
    return {
      isStub: isStubType(document),
      isTable: isTableType(document),
      defaultTitle:
        document?.ui?.show_file_name?.includes(user?.id) && document.filename
          ? document.filename
          : document?.detexiFiedMeta?.title ||
            document?.meta?.title ||
            document.filename ||
            "",
      defaultDescription:
        document?.detexiFiedMeta?.abstract || document?.meta?.abstract || "",
    };
  }, [document]);

  const docStatus = getDocumentStatus(document);
  const isDocViewerEnabled =
    companyFeatures.app.guests.enableDocumentViewer && docStatus.can_be_viewed;
  const authors = setAuthorsData(
    document?.detexiFiedMeta?.author || document?.meta?.author
  );
  const isSelected = selectedDocuments?.some((doc) => doc.id === document.id);

  const displayedTitle =
    !isSearch || searchTypeQuery === SearchType.semantic
      ? defaultTitle
      : searchTextConverter(searchQuery, defaultTitle);

  const referenceInfo = useMemo(() => {
    const formatted = bibtexEntry2html(
      document.detexiFiedMeta
        ? {
            ...document.detexiFiedMeta,
            author: Array.isArray(document?.detexiFiedMeta?.author)
              ? document.detexiFiedMeta.author.join(" and ")
              : `${document?.detexiFiedMeta?.author}`,
          }
        : {
            ...document.meta,
            author: Array.isArray(document?.meta?.author)
              ? document.meta.author.join(" and ")
              : `${document?.meta?.author}`,
          },
      true
    );
    if (
      formatted.remainder.includes("()") ||
      formatted.remainder.includes("undefined")
    ) {
      return "";
    }
    return formatted.remainder;
  }, [document]);

  useEffect(() => {
    if (summaryRef.current?.offsetWidth) {
      const width = summaryRef.current?.offsetWidth;
      if (width > 560) {
        setTagsCount(
          isSearch && searchTypeQuery === SearchType.fulltext ? 2 : 4
        );
      } else if (width > 420) {
        setTagsCount(2);
      } else {
        setTagsCount(1);
      }
    }
  }, [summaryRef.current?.offsetWidth]);

  useEffect(() => {
    if (document?.snippet && document.snippet !== searchSnippet) {
      let shownSnippet = document.snippet;
      if (document.snippet !== "") {
        const emIndex = document.snippet.indexOf("<em>");
        if (emIndex > 35) {
          shownSnippet = `...${document.snippet.slice(emIndex - 35)}`;
        }
      }
      setSearchSnippet(shownSnippet);
    }
  }, [document]);

  const openDocumentViewer = (method: string) => {
    const newPageUrl = `/document/${document.id}/view?organizationId=${document.organization_id}`;
    logAction(telemetryAction.open_document_viewer, {
      document_id: document.id,
      method,
    });
    window.open(newPageUrl, "_blank");
  };

  return (
    <Wrapper style={style}>
      <Box
        className={clsx("document-item", {
          canSelect,
          selected: isSelected,
        })}
        sx={{
          height:
            isSearch && searchTypeQuery === SearchType.fulltext
              ? "80px"
              : "60px",
        }}
        onClick={(e) => {
          if (canSelect && selectedDocuments) {
            if (e.shiftKey || e.ctrlKey || e.metaKey) {
              window?.getSelection()?.removeAllRanges();
              if (selectedDocuments.length === 0 && setSelectedDocuments) {
                setSelectedDocuments([document]);
              } else if (multiSelectDocuments) {
                multiSelectDocuments(document.id, e);
              }
            } else if (setSelectedDocuments) {
              if (selectedDocuments.some((doc) => doc.id === document.id)) {
                const filteredDocs = selectedDocuments.filter(
                  (doc) => doc.id !== document.id
                );
                setSelectedDocuments(filteredDocs);
              } else {
                setSelectedDocuments([...selectedDocuments, document]);
              }
            }
          }
        }}
      >
        {canSelect && selectedDocuments && setSelectedDocuments && (
          <Checkbox
            className="document-checkbox"
            color="primary"
            checked={isSelected}
            onChange={(e) => {
              e.stopPropagation();
              if (isSelected) {
                setSelectedDocuments(
                  selectedDocuments.filter((doc) => doc.id !== document.id)
                );
              } else {
                setSelectedDocuments([...selectedDocuments, document]);
              }
            }}
            size="small"
          />
        )}
        <Box
          className={clsx("doc-type-icon", {
            visible: !isStub,
          })}
        >
          {isTable ? (
            <TableChartOutlinedIcon fontSize="small" color="action" />
          ) : (
            <AttachFileIcon fontSize="small" color="action" />
          )}
          {isTable && docStatus.ready && !defaultDescription && (
            <Tooltip
              enterDelay={500}
              placement="bottom"
              title={t("table_missing_descriptions")}
            >
              <AnnouncementOutlinedIcon
                style={{
                  color: theme.palette.error.dark,
                  width: "1.25rem",
                  height: "1.25rem",
                }}
              />
            </Tooltip>
          )}
        </Box>
        <Box className="summary" ref={summaryRef}>
          {!isSearch ? (
            <Typography
              className={clsx("title", {
                document: docStatus.can_be_viewed,
                active: isDocViewerEnabled || isTable,
                "table-source": isTable,
              })}
              onClick={(e) => {
                e.stopPropagation();
                if (isDocViewerEnabled) {
                  openDocumentViewer("ai_table_title_click");
                } else if (isTable) {
                  setTableDocumentToUpdate(document);
                }
              }}
            >
              {displayTextWithMath(displayedTitle)}
            </Typography>
          ) : (
            <Typography
              className={clsx("title", {
                document: docStatus.can_be_viewed,
                active: isDocViewerEnabled || isTable,
                "table-source": isTable,
              })}
              onClick={(e) => {
                e.stopPropagation();
                if (isDocViewerEnabled) {
                  openDocumentViewer("ai_table_title_click");
                } else if (isTable) {
                  setTableDocumentToUpdate(document);
                }
              }}
              dangerouslySetInnerHTML={{
                __html: displayedTitle,
              }}
            />
          )}
          <Box className="secondary-data">
            {location.pathname === routePaths.aiChat ? (
              <Typography variant="body2">
                {t("amount_of_characters", {
                  amount: (
                    document?.meta?.text_character_count || 0
                  ).toLocaleString(),
                })}
              </Typography>
            ) : isTable ? (
              <Typography variant="body2" className="description">
                {defaultDescription}
              </Typography>
            ) : !isSearch || searchTypeQuery !== SearchType.fulltext ? (
              <Typography variant="body2" className="authors">
                {authors.length > 0 ? authors.join(", ") : t("no_authors")}
              </Typography>
            ) : (
              <Box className="search-snippet">
                <Typography
                  variant="body2"
                  dangerouslySetInnerHTML={{
                    __html:
                      document?.snippet && document.snippet !== ""
                        ? searchSnippet
                        : t("matched_text_not_available_or_metadata"),
                  }}
                />
              </Box>
            )}
            {location.pathname === routePaths.aiChat ? (
              <Box className="tags-wrapper">
                {document.tag_ids.length > 0 ? (
                  <TagsListStandalone
                    tags={document.tag_ids.reduce((arr: Tag[], id) => {
                      const [tag] = getCachedTagById(id);
                      if (tag) arr.push(tag);
                      return arr;
                    }, [])}
                    tagsCount={tagsCount}
                    isUserPermitted={!isGuest(currentUserRole)}
                  />
                ) : (
                  <Typography
                    variant="body2"
                    className="tags"
                    color="textSecondary"
                    fontStyle="italic"
                  >
                    {t("no_tags")}
                  </Typography>
                )}
              </Box>
            ) : !isTable && referenceInfo ? (
              <Box
                className="compact-document-data"
                dangerouslySetInnerHTML={{
                  __html: detexify(referenceInfo, false),
                }}
              />
            ) : (
              ""
            )}
          </Box>
        </Box>
        {showErrorIfExist && (
          <Box className="error-container">{getError(document)}</Box>
        )}
      </Box>
    </Wrapper>
  );
};

export default AIContextDialogDocument;
