import { DocumentReadResponse } from "models/api/response.types";
import { normalizeAuthor } from "./bibtex";

/**
 * Convert document.meta.author to a list of names.
 * @param value The value of document.meta.author
 * @returns List of author names
 */
export const parseAuthorList = (authors: string | string[]): string[] => {
  const valueString = `${authors}`;
  let authorsArray: string[] = [];
  if (!authors) {
    return [];
  }
  if (Array.isArray(authors)) {
    authorsArray = authors as string[];
  } else if (valueString.includes(" and ")) {
    authorsArray = valueString.split(" and ");
  } else if (valueString.includes(" AND ")) {
    authorsArray = valueString.split(" AND ");
  } else {
    authorsArray = [authors as string];
  }
  return authorsArray
    .map((author) => author.trim())
    .map((author) => normalizeAuthor(author));
};

/**
 * Returns the last name of the author for sorting
 * @param value Name of author in "Baz, Foo B." or "Foo B.~Baz" format
 * @returns
 */
const parseAuthorLastName = (value: string): string => {
  // if name is empty, return empty string
  if (value.length === 0) return "";
  // if name contains comma, then first token is last name
  let lastName: string;
  if (value.includes(",")) {
    const index = value.indexOf(",");
    const beforeComma = value.substring(0, index);
    lastName = beforeComma.trim();
  }
  const tokens = value.split(/\s+/);
  lastName = tokens[tokens.length - 1];
  return lastName;
};

export interface ISortOrder {
  key: string;
  order: "ascending" | "descending";
}

interface SortOption {
  label: string;
  compare: (
    a: DocumentReadResponse,
    b: DocumentReadResponse,
    order: string
  ) => number;
}

export const sortOptions: Record<string, SortOption> = {
  filename: {
    label: "filename",
    compare: (a, b, order) => {
      if (a.filename !== undefined && b.filename !== undefined) {
        const list = a.filename
          .toLocaleLowerCase()
          .localeCompare(b.filename.toLocaleLowerCase());
        return order === "ascending" ? list : -list;
      }
      if (a.filename !== undefined) {
        return order === "ascending" ? 1 : -1;
      }
      if (b.filename !== undefined) {
        return order === "ascending" ? -1 : 1;
      }
      return 0;
    },
  },
  relevance: {
    label: "relevance",
    compare: (a, b) => {
      if (a.ranking !== undefined && b.ranking !== undefined) {
        return a.ranking - b.ranking;
      }
      return a.ranking !== undefined ? 1 : b.ranking !== undefined ? -1 : 0;
    },
  },
  title: {
    label: "title",
    compare: (a, b, order) => {
      if (a.meta.title !== undefined && b.meta.title !== undefined) {
        const list = a.meta.title
          .toLocaleLowerCase()
          .localeCompare(b.meta.title.toLocaleLowerCase());
        return order === "ascending" ? list : -list;
      }
      if (a.meta.title !== undefined) {
        return order === "ascending" ? 1 : -1;
      }
      if (b.meta.title !== undefined) {
        return order === "ascending" ? -1 : 1;
      }
      return 0;
    },
  },
  "character-count": {
    label: "character_count",
    compare: (a, b, order) => {
      const count1 = a?.meta?.text_character_count
        ? a.meta.text_character_count
        : 0;
      const count2 = b?.meta?.text_character_count
        ? b.meta.text_character_count
        : 0;
      if (count1 && count2) {
        return order === "ascending" ? count1 - count2 : count2 - count1;
      }
      if (count1) {
        return order === "ascending" ? 1 : -1;
      }
      if (count2) {
        return order === "ascending" ? -1 : 1;
      }
      return 0;
    },
  },
  "date-created": {
    label: "date_created",
    compare: (a, b, order) => {
      if (a.created_at && b.created_at) {
        const list = a.created_at
          .toLocaleLowerCase()
          .localeCompare(b.created_at.toLocaleLowerCase());
        return order === "ascending" ? list : -list;
      }
      if (a.created_at) {
        return order === "ascending" ? 1 : -1;
      }
      if (b.created_at) {
        return order === "ascending" ? -1 : 1;
      }

      return 0;
    },
  },
  "first-author": {
    label: "first_author",
    compare: (a, b, order) => {
      const aList = parseAuthorList(a.meta.author);
      const bList = parseAuthorList(b.meta.author);
      if (aList.length > 0 && bList.length > 0) {
        const aLastName = parseAuthorLastName(aList[0]);
        const bLastName = parseAuthorLastName(bList[0]);
        const list = aLastName
          .toLocaleLowerCase()
          .localeCompare(bLastName.toLocaleLowerCase());
        return order === "ascending" ? list : -list;
      }
      if (aList.length > 0) {
        return order === "ascending" ? 1 : -1;
      }
      if (bList.length > 0) {
        return order === "ascending" ? -1 : 1;
      }
      return 0;
    },
  },
  "last-author": {
    label: "last_author",
    compare: (a, b, order) => {
      const aList = parseAuthorList(a.meta.author);
      const bList = parseAuthorList(b.meta.author);
      if (aList.length > 0 && bList.length > 0) {
        const aLastName = parseAuthorLastName(aList[aList.length - 1]);
        const bLastName = parseAuthorLastName(bList[bList.length - 1]);
        const list = aLastName
          .toLocaleLowerCase()
          .localeCompare(bLastName.toLocaleLowerCase());
        return order === "ascending" ? list : -list;
      }
      if (aList.length > 0) {
        return order === "ascending" ? 1 : -1;
      }
      if (bList.length > 0) {
        return order === "ascending" ? -1 : 1;
      }
      return 0;
    },
  },
  journal: {
    label: "journal",
    compare: (a, b, order) => {
      if (a.meta.journal !== undefined && b.meta.journal !== undefined) {
        const list = a.meta.journal
          .toLocaleLowerCase()
          .localeCompare(b.meta.journal.toLocaleLowerCase());
        return order === "ascending" ? list : -list;
      }
      if (a.meta.journal !== undefined) {
        return order === "ascending" ? 1 : -1;
      }
      if (b.meta.journal !== undefined) {
        return order === "ascending" ? -1 : 1;
      }
      return 0;
    },
  },
  year: {
    label: "year",
    compare: (a, b, order) => {
      const yearA =
        typeof a.meta.year === "number"
          ? a.meta.year
          : parseInt(a.meta.year as string, 10) || NaN;
      const yearB =
        typeof b.meta.year === "number"
          ? b.meta.year
          : parseInt(b.meta.year as string, 10) || NaN;
      if (yearA && yearB) {
        return order === "ascending" ? yearA - yearB : yearB - yearA;
      }
      if (yearA) {
        return order === "ascending" ? 1 : -1;
      }
      if (yearB) {
        return order === "ascending" ? -1 : 1;
      }
      return 0;
    },
  },
};
