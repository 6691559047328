import React from "react";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DataToRun } from "pages/AiTable";
import { useTranslation } from "react-i18next";

const AiTableOverwriteDialog: React.FC<{
  data?: DataToRun;
  open: boolean;
  proceed: (proceed_forward: boolean, empty_cells_only?: boolean) => void;
}> = ({ open, proceed, data }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle>
        Overwriting Existing Data
        <IconButton onClick={() => proceed(false)}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Alert severity="warning">
          {data?.type === "column" &&
            `${t("column_contains_data_warning")}${
              data.emptyData.length > 0
                ? `${t("run_query_empty_cells_only")}`
                : "."
            } `}
          {data?.type === "cell" && t("cell_contains_data_warning")}
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="text"
          size="medium"
          onClick={() => proceed(false)}
        >
          {t("cancel")}
        </Button>
        {data?.type === "column" && data.emptyData.length > 0 && (
          <Button
            color="primary"
            variant="contained"
            size="medium"
            onClick={() => proceed(true, true)}
          >
            {t("empty_cells_only")}
          </Button>
        )}
        <Button
          color="primary"
          variant="contained"
          size="medium"
          onClick={() => proceed(true)}
        >
          {data?.type === "column" ? t("overwrite_all") : t("overwrite")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AiTableOverwriteDialog;
