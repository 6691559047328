/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Container,
  IconButton,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import routePaths from "routes/routePaths";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { isGuest } from "models/components/Permissions.models";
import { isChrome, isFirefox, isSafari } from "react-device-detect";
import UploadManager from "components/Browser/AddDocuments/UploadManager";
import ManualEntryDialog from "components/Dialogs/ManualEntryDialog";
import { setSideBarOpen } from "store/features/browser/slice";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentOrganizationId,
  selectUser,
} from "store/features/session/slice";
import { useUsers } from "api/userService";
import ImportBibTex from "components/Browser/AddDocuments/ImportBibTex/ImportBibTex";
import AddDocumentsOnboarding from "components/Onboarding/AddDocumentsOnboarding";
import { addAlert } from "store/features/general/slice";
import { APP_TITLE, companyFeatures } from "company-config";
import { useTranslation } from "react-i18next";

type SelectedPath = "computer" | "bibtex" | "identifier" | "table" | "manually";

interface WrapperProps {
  selectedpath?: string;
}

const Wrapper = styled(Container)<WrapperProps>(({ theme, selectedpath }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
  ...(selectedpath && {
    justifyContent: "center",
  }),
  paddingBottom: "1rem",
  paddingTop: "1rem",
  gap: "1rem",
  overflow: "hidden",
  "& .header": {
    display: "flex",
    gap: "1rem",
    alignItems: "flex-start",
    "& .onboarding-message": {
      "& .title": {
        marginBottom: "1rem",
      },
    },
  },
  "& .add-container": {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(400px, 1fr))",
    gap: "2rem",
    overflow: "auto",
    padding: "4px",
    [theme.breakpoints.down("xl")]: {
      gap: "1rem",
      gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
    },
    "& .add-option-card": {
      position: "relative",
      border: "1px solid transparent",
      background: theme.background.light,
      borderRadius: "4px",
      boxShadow: "1px 1px 4px rgba(0, 0, 0, 0.2)",
      padding: "1rem 2rem",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "1rem",
      transition: "all 0.3s",
      [theme.breakpoints.down("xl")]: {
        padding: "1rem",
      },
      "& .title": {
        textAlign: "center",
      },
      cursor: "pointer",
      "&:hover": {
        borderColor: theme.palette.primary.main,
      },
      "& .add-icon": {
        width: "40px",
        height: "40px",
      },
      "& .coming-soon": {
        position: "absolute",
        top: "5px",
        right: "20px",
        border: `1px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
        borderRadius: "4px",
        padding: "0.3rem 0.5rem",
      },
    },
  },
  "& .workspace-button": {
    width: "fit-content",
    margin: "0 auto",
  },
}));

const AddDocuments: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const smScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const { users } = useUsers(currentOrganizationId);
  const currentUserRole = users?.find((u) => u.id === user?.id)?.role;
  const [selectedPath, setSelectedPath] = useState<SelectedPath | undefined>(
    undefined
  );

  // guests can't access add route
  useEffect(() => {
    if (currentUserRole && isGuest(currentUserRole)) {
      dispatch(
        addAlert({
          severity: "warning",
          autoHideDuration: 5000,
          alert: {
            message: t("no_permission_add_documents"),
          },
        })
      );
      navigate(routePaths.documents);
    }
  }, [currentUserRole]);

  const addOptions = useMemo(() => {
    const options = [
      {
        icon: (
          <img
            className="add-icon"
            src="/img/add/add-computer.svg"
            alt={t("upload_from_computer")}
          />
        ),
        title: t("upload_from_computer"),
        description: t("auto_identify_info"),
        path: "computer",
      },
      {
        icon: (
          <img
            className="add-icon"
            src="/img/add/add-table.svg"
            alt={t("import_table")}
          />
        ),
        title: t("import_table"),
        description: t("import_csv_xls"),
        path: "table",
      },
      {
        icon: (
          <img
            className="add-icon"
            src="/img/add/add-bibtex.svg"
            alt={t("import_via_bibtex")}
          />
        ),
        title: t("import_via_bibtex"),
        description: t("upload_bibtex"),
        path: "bibtex",
      },
      {
        icon: (
          <img
            className="add-icon"
            src="/img/add/add-identifier.svg"
            alt={t("import_doi_pmid_arxiv")}
          />
        ),
        title: t("import_doi_pmid_arxiv"),
        description: t("specify_article_identifier"),
        path: "identifier",
      },
      {
        icon: (
          <img
            className="add-icon"
            src="/img/add/add-manually.svg"
            alt={t("add_entry_manually")}
          />
        ),
        title: t("add_entry_manually"),
        description: t("manual_metadata_entry"),
        path: "manually",
      },
    ];

    if (!companyFeatures.isWhiteLabel) {
      options.push({
        icon: (
          <img
            className="add-icon"
            src="/img/add/add-web-importer.svg"
            alt={t("web_importer")}
          />
        ),
        title: t("web_importer"),
        description: t("save_pdfs_webpages", { appTitle: APP_TITLE }),
        path: "web-importer",
      });
    }

    return options;
  }, []);

  return (
    <Wrapper
      maxWidth={
        selectedPath === "identifier" || selectedPath === "manually"
          ? "md"
          : "xl"
      }
      selectedpath={selectedPath ? "true" : undefined}
    >
      {(smScreen || selectedPath) && (
        <Box className="header">
          {smScreen && (
            <IconButton
              className="sidebar-close-icon"
              size="medium"
              onClick={() => dispatch(setSideBarOpen(true))}
              color="primary"
            >
              <MenuOutlinedIcon fontSize="medium" color="action" />
            </IconButton>
          )}
          {selectedPath && (
            <Button
              color="secondary"
              size="medium"
              variant="text"
              startIcon={<KeyboardBackspaceIcon fontSize="medium" />}
              onClick={() => {
                setSelectedPath(undefined);
              }}
            >
              {t("back")}
            </Button>
          )}
        </Box>
      )}
      {!selectedPath ? (
        <>
          <Box className="add-container">
            {addOptions.map((addItem) => (
              <Box
                key={addItem.path}
                className="add-option-card"
                onClick={() => {
                  if (addItem.path !== "web-importer") {
                    setSelectedPath(addItem.path as SelectedPath);
                  }
                  if (addItem.path === "web-importer") {
                    if (isSafari) {
                      window.open(
                        "https://apps.apple.com/us/app/petal-cite-web-importer/id1629755454?mt=12",
                        "_blank"
                      );
                    } else if (isChrome) {
                      window.open(
                        "https://chrome.google.com/webstore/detail/petal-cite-web-importer/dikbfpemggjmcidjecbjgbikghaaigei?hl=en&authuser=0",
                        "_blank"
                      );
                    } else if (isFirefox) {
                      window.open(
                        "https://addons.mozilla.org/en-US/firefox/addon/kaleidoglobe-assistant/",
                        "_blank"
                      );
                    } else {
                      window.open(
                        "https://www.petal.org/reference-manager/browser-extension",
                        "_blank"
                      );
                    }
                  }
                }}
              >
                {addItem.icon}
                <Typography
                  className="title"
                  variant="h6"
                  fontWeight={500}
                  color="textSecondary"
                >
                  {addItem.title}
                </Typography>
                <Typography
                  variant="body2"
                  textAlign="center"
                  color="textSecondary"
                >
                  {addItem.description}
                </Typography>
              </Box>
            ))}
          </Box>
          <Button
            size="large"
            color="primary"
            variant="contained"
            className="workspace-button"
            onClick={() => {
              navigate(routePaths.documents);
            }}
          >
            {t("go_to_workspace_library")}
          </Button>
        </>
      ) : (
        <>
          {selectedPath === "computer" && <UploadManager />}
          {selectedPath === "table" && <UploadManager isTable />}
          {selectedPath === "manually" && (
            <ManualEntryDialog option="manually" />
          )}
          {selectedPath === "identifier" && (
            <ManualEntryDialog option="identifier" />
          )}
          {selectedPath === "bibtex" && <ImportBibTex />}
        </>
      )}
      {currentUserRole &&
        !user?.meta?.citeOnboarding?.addDocuments?.completed && (
          <AddDocumentsOnboarding />
        )}
    </Wrapper>
  );
};

export default AddDocuments;
