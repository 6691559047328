import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  TextField,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import referralService from "api/referralService";
import { useDispatch } from "react-redux";
import { addAlert } from "store/features/general/slice";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";

const DialogWrapper = styled(Dialog)(({ theme }) => ({
  "& .MuiInputLabel-root": {
    color: "#272727",
    position: "relative",
    fontSize: "14px",
    transform: "none",
    marginBottom: "8px",
  },
  "& #friend": {
    background: theme.background.light,
    padding: "11px 14px",
  },
  "& .description": {
    margin: "1rem 0 0.5rem 0",
  },
  "& .learn-more": {
    display: "inline-block",
    textDecoration: "underline",
    cursor: "pointer",
  },
}));

const ReferFriendDialog: React.FC<{
  setOpen: (open: boolean) => void;
}> = ({ setOpen }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const createReferral = () => {
    setLoading(true);
    referralService
      .createReferral({ email })
      .then(() => {
        dispatch(
          addAlert({
            severity: "success",
            autoHideDuration: 5000,
            alert: {
              message: t("referral_link_sent"),
            },
          })
        );
        setEmail("");
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err?.response?.data?.error) {
          setEmailError(err?.response?.data?.error as string);
        } else {
          dispatch(
            addAlert({
              severity: "error",
              autoHideDuration: 5000,
              alert: {
                message: t("something_went_wrong_please"),
              },
            })
          );
        }
      });
  };

  return (
    <DialogWrapper open onClose={() => setOpen(false)} fullWidth maxWidth="sm">
      <DialogTitle>
        {t("refer_a_friend")}
        <IconButton onClick={() => setOpen(false)}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormControl variant="standard" fullWidth>
          <InputLabel shrink htmlFor="friend">
            {t("enter_referee_email")}
          </InputLabel>
          <TextField
            id="friend"
            color="primary"
            variant="outlined"
            value={email}
            type="email"
            size="medium"
            autoFocus
            error={!!emailError}
            helperText={emailError}
            onChange={(e) => {
              setEmailError("");
              setEmail(e.target.value);
            }}
            onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
              if (event.key === "Enter" && email.length > 0 && !loading) {
                event.preventDefault();
                createReferral();
              }
            }}
          />
        </FormControl>
        <Typography
          className="description"
          variant="body2"
          color="textSecondary"
        >
          {t("referral_bonus_info")}
        </Typography>
        <Tooltip
          placement="top"
          enterDelay={10}
          title={
            <>
              <p>{t("referral_qualification_steps")}</p>
              <ul>
                <li> {t("signup_with_link")}</li>
                <li>{t("authenticate_and_create_account")}</li>
                <li>{t("upload_documents")}</li>
              </ul>
              <p>{t("bonus_info")}</p>
              <p>{t("upgrade_bonus_info")}</p>
            </>
          }
        >
          <Typography className="learn-more" variant="body2" color="secondary">
            {t("learn_more")}
          </Typography>
        </Tooltip>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          onClick={createReferral}
          color="primary"
          size="medium"
          variant="contained"
          loading={loading}
          disabled={email.length === 0 || loading}
        >
          {t("send_referral")}
        </LoadingButton>
      </DialogActions>
    </DialogWrapper>
  );
};

export default ReferFriendDialog;
