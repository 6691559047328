/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  TextField,
} from "@mui/material";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Close } from "@mui/icons-material";
import { DraggableArea } from "react-draggable-tags";
import { useTranslation } from "react-i18next";

const Wrapper = styled(Dialog)(({ theme }) => ({
  "& .DraggableTags": {
    display: "flex",
    gap: theme.spacing(1),
    flexWrap: "wrap",
    "& .DraggableTags-tag": {
      color: "#666",
      fontSize: "14px",
      margin: 0,
      "& .tag": {
        whiteSpace: "nowrap",
        padding: "0.3em 0.5em",
        fontSize: "16px",
        position: "relative",
        borderRadius: "0.2em",
        background: "#fafafa",
        border: "1px solid #0001",
        display: "flex",
        alignItems: "center",
      },
    },
  },
  "& .MuiCollapse-wrapperInner": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "2rem",
    marginBottom: "1rem",
    "& .field-actions": {
      display: "flex",
      gap: "1rem",
      flexWrap: "nowrap",
    },
  },
}));

const ListPropertyDialog: React.FC<{
  propertyName: string;
  currentValue: string[];
  currentDetexifiedValue?: string[];
  onClose: () => void;
  onChange: (newValue?: string[]) => void;
}> = ({
  propertyName,
  currentValue,
  currentDetexifiedValue,
  onClose,
  onChange,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<string[]>(
    currentDetexifiedValue || currentValue
  );

  const [openField, setOpenField] = useState<number>(-2);
  const [fieldInput, setFieldInput] = useState<string>("");

  // on value change trigger instant update
  useEffect(() => {
    onChange(value);
    setFieldInput("");
    if (value.length === 0) {
      setOpenField(-1);
    }
  }, [value]);

  useEffect(() => {
    setValue(currentDetexifiedValue || currentValue);
  }, [currentDetexifiedValue, currentValue]);

  return (
    <Wrapper
      maxWidth="sm"
      fullWidth
      open
      onClose={() => {
        onClose();
      }}
    >
      <DialogTitle>
        {t("editing_property", { propertyName })}
        <IconButton onClick={() => onClose()}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Collapse orientation="vertical" in={!!(openField >= -1)}>
          <TextField
            variant="outlined"
            color="primary"
            size="small"
            value={fieldInput}
            fullWidth
            autoFocus
            onChange={(e) => {
              setFieldInput(e.target.value);
            }}
            placeholder={t("enter_field_value")}
          />
          <Box className="field-actions">
            {value.length !== 0 && (
              <IconButton
                color="primary"
                size="small"
                onClick={() => {
                  setOpenField(-2);
                  setFieldInput("");
                }}
              >
                <ClearOutlinedIcon fontSize="small" />
              </IconButton>
            )}
            <Button
              disabled={fieldInput.length === 0}
              variant="contained"
              size="small"
              color="primary"
              onClick={() => {
                if (openField === -1) {
                  setValue([...value, fieldInput]);
                } else {
                  const newValue = [...value];
                  newValue[openField] = fieldInput;
                  setValue(newValue);
                }
                setOpenField(-2);
              }}
            >
              {t("add")}
            </Button>
          </Box>
        </Collapse>
        {value.length > 0 && (
          <DraggableArea
            tags={[
              ...value.map((field, index) => ({
                name: field,
                id: `${index} - ${field}`,
              })),
            ]}
            render={({ tag, index }) => (
              <Box key={tag.id} className="tag">
                {tag.name}
                <IconButton
                  sx={{ marginLeft: "0.5rem" }}
                  size="small"
                  color="primary"
                  onClick={() => {
                    setOpenField(index);
                    setFieldInput(tag.name);
                  }}
                >
                  <ModeEditOutlineOutlinedIcon fontSize="small" />
                </IconButton>
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => {
                    setValue(value.filter((_v, idx) => index !== idx));
                  }}
                >
                  <HighlightOffIcon fontSize="small" />
                </IconButton>
              </Box>
            )}
            onChange={(tags) => {
              setValue(tags.map((tag) => tag.name));
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        {value.length > 0 && (
          <Button
            size="medium"
            variant="contained"
            color="primary"
            disabled={!!(openField >= -1)}
            onClick={() => {
              setOpenField(-1);
            }}
          >
            {t("add_new")}
          </Button>
        )}
      </DialogActions>
    </Wrapper>
  );
};
export default ListPropertyDialog;
