/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable import/no-extraneous-dependencies */
import React, {
  useEffect,
  useState,
  createContext,
  useMemo,
  useRef,
} from "react";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  IconButton,
  LinearProgress,
  Skeleton,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ClearOutlined } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import DocumentToolbar from "components/Browser/Documents/DocumentToolbar/DocumentToolbar";
import {
  SearchType,
  getSelectedCollectionId,
  selectIsSearch,
  selectSearchQuery,
  selectSearchTypeQuery,
  selectTagsModeBrowse,
  setFocusSearchInput,
  setIsSearch,
  setSearchQuery,
  setSideBarOpen,
  selectDocumentSort,
  setDocumentSort,
  getSelectedDocumentFilters,
  setSelectedDocumentFilters,
} from "store/features/browser/slice";
import {
  selectCurrentOrganizationId,
  selectUser,
} from "store/features/session/slice";
import {
  DocumentCollection,
  DocumentReadResponse,
  Tag,
} from "models/api/response.types";
import { v4 as uuidv4 } from "uuid";
import { useDocuments } from "api/documentService";
import DocumentFilter, {
  DocumentFilters,
  defaultDocumentFiltersData,
} from "components/Browser/Documents/DocumentFilter";
import { useHotkeys } from "react-hotkeys-hook";
import { useDocumentCollections } from "api/documentCollectionService";
import { tagsModes } from "utils/tagsModes";
import { ISortOrder, sortOptions } from "utils/documentSort";
import { getDocumentStatus } from "utils/documentStatus";
import useTelemetry, { telemetryAction } from "utils/useTelemetry";
import {
  AutoSizer,
  ListRowProps,
  List as VirtualizedList,
} from "react-virtualized";
import DocumentListItem from "components/Browser/Documents/ListItem/DocumentListItem";
import SortDocumentMenu from "components/Browser/Documents/SortDocumentMenu";
import { RootContainer } from "components/Browser/Documents/Library-styles";
import LoadingOverlay from "components/helpers/LoadingOverlay";
import { searchParentCollectionTreeName } from "utils/collections";
import DocumentActions from "components/Browser/Documents/DocumentActions";
import SnackBar from "components/helpers/SnackBar";
import AddToCollectionDialog from "components/Dialogs/AddToCollectionDialog";
import BibTeXExportDialog from "components/Dialogs/BibTeXExportDialog";
import EditDocumentTagsDialog from "components/Dialogs/Tags/EditDocumentTagsDialog";
import AddTagsDialog from "components/Dialogs/Tags/AddTagsDialog";
import CopyDocumentsDialog from "components/Dialogs/CopyDocumentsDialog";
import BibMergeDialog from "components/Dialogs/BibMergeDialog";
import SpecialFileUploaderDialog, {
  SpecialFileToUpload,
} from "components/Dialogs/SpecialFileUploaderDialog";
import DocumentRightSideBar from "components/Browser/Documents/DocumentRightSideBar/DocumentRightSideBar";
import { useNavigate } from "react-router-dom";
import routePaths from "routes/routePaths";
import { useUsers } from "api/userService";
import DocumentsOnboarding from "components/Onboarding/DocumentsOnboarding";
import { isGuest } from "models/components/Permissions.models";
import ManageTagsDialog from "components/Dialogs/ManageTagsDialog.tsx/ManageTagsDialog";
import { MultiDialogType } from "models/components/Browse.models";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import CustomDocumentDragLayer from "components/Browser/Documents/CustomDocumentDragLayer";
import { companyFeatures } from "company-config";
import RemoveTagsDialog from "components/Dialogs/Tags/RemoveTagsDialog";
import TableSourceDialog from "components/Dialogs/TableSourceDialog/TableSourceDialog";
import { getTabletype } from "utils/upload.helpers";
import { useTranslation } from "react-i18next";

const Container = styled(Box)(({ theme }) => ({
  flex: 1,
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  padding: `${theme.spacing(1.5)}`,
  "& .toolbar": {
    [theme.breakpoints.down("md")]: {
      alignItems: "flex-start",
    },
    width: "100%",
    display: "flex",
    gap: "1rem",
  },
  "& .document-container": {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    position: "relative",
    "& .document-container-actions": {
      overflowX: "auto",
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
      background: theme.background.light,
      padding: "0.5rem 1rem 0.5rem 0.5rem",
      display: "flex",
      gap: `${theme.spacing(1)}`,
      alignItems: "center",
      border: `1px solid ${theme.grey.light}`,
    },
    "& .search-box": {
      width: "100%",
      borderLeft: `1px solid ${theme.grey.light}`,
      borderRight: `1px solid ${theme.grey.light}`,
      borderTopLeftRadius: "4px",
      "& .MuiCollapse-wrapperInner": {
        padding: "0.5rem 1rem",
        background: theme.notice.main,
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
        "& .search-text": {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
    },
  },
  "& .document-list-container": {
    flex: 1,
    display: "flex",
    position: "relative",
  },
  "& .error-message": {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.5rem",
    "& img": {
      width: "250px",
      [theme.breakpoints.down("lg")]: {
        width: "200px",
      },
      [theme.breakpoints.down("md")]: {
        width: "150px",
      },
    },
  },
}));

export const DocumentsContext = createContext<{
  searchedDocuments: DocumentReadResponse[] | undefined;
  selectedDocuments: DocumentReadResponse[];
  trashMode: boolean;
  noCollectionMode: boolean;
  submitSearch: boolean;
  documentToShow: DocumentReadResponse | undefined;
  openAddTagsDialog: boolean;
  openRemoveTagsDialog: boolean;
  openCopyDocumentsDialog: MultiDialogType;
  openBibMergeDialog: MultiDialogType;
  openBibTeXExportDialog: MultiDialogType;
  openAddToCollectionDialog: MultiDialogType;
  editTagDialog: boolean;
  openManageTagsDialog: boolean;
  draggableDocumentId: number | undefined;
  triggerSpecialInputFile: (id: number) => void;
  loadFile: (fileName: string, loaded: number, failed: boolean) => void;
  setOpenAddToCollectionDialog: (data: MultiDialogType) => void;
  setOpenBibTeXExportDialog: (data: MultiDialogType) => void;
  setOpenBibMergeDialog: (data: MultiDialogType) => void;
  setOpenCopyDocumentsDialog: (data: MultiDialogType) => void;
  setOpenAddTagsDialog: (open: boolean) => void;
  setOpenRemoveTagsDialog: (open: boolean) => void;
  setOpenEditTagDialog: (open: boolean) => void;
  setDocumentToShow: (document?: DocumentReadResponse) => void;
  setSearchedDocuments: (documents: DocumentReadResponse[] | undefined) => void;
  setSubmitSearch: (submit: boolean) => void;
  setSelectedDocuments: (documents: DocumentReadResponse[]) => void;
  setNoCollectionMode: (setNoCollectionMode: boolean) => void;
  setTrashMode: (setTrash: boolean) => void;
  setOpenManageTagsDialog: (open: boolean) => void;
  setDraggableDocumentId: (id?: number) => void;
}>({
  draggableDocumentId: undefined,
  searchedDocuments: undefined,
  selectedDocuments: [],
  trashMode: false,
  noCollectionMode: false,
  submitSearch: false,
  documentToShow: undefined,
  openAddTagsDialog: false,
  openRemoveTagsDialog: false,
  openManageTagsDialog: false,
  openCopyDocumentsDialog: {
    open: false,
    type: "selected_documents",
  },
  openBibMergeDialog: {
    open: false,
    type: "selected_documents",
  },
  openBibTeXExportDialog: {
    open: false,
    type: "selected_documents",
  },
  openAddToCollectionDialog: {
    open: false,
    type: "selected_documents",
  },
  editTagDialog: false,
  triggerSpecialInputFile: () => {},
  loadFile: () => {},
  setOpenAddToCollectionDialog: () => {},
  setOpenBibTeXExportDialog: () => {},
  setOpenBibMergeDialog: () => {},
  setOpenRemoveTagsDialog: () => {},
  setOpenCopyDocumentsDialog: () => {},
  setOpenAddTagsDialog: () => {},
  setOpenEditTagDialog: () => {},
  setDocumentToShow: () => {},
  setSearchedDocuments: () => {},
  setSubmitSearch: () => {},
  setSelectedDocuments: () => {},
  setNoCollectionMode: () => {},
  setTrashMode: () => {},
  setOpenManageTagsDialog: () => {},
  setDraggableDocumentId: () => {},
});

const Documents: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const smScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const isSearch = useSelector(selectIsSearch);
  const searchTypeQuery = useSelector(selectSearchTypeQuery);
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const tagsModeBrowse = useSelector(selectTagsModeBrowse);
  const selectedCollectionId = useSelector(getSelectedCollectionId);
  const documentSort = useSelector(selectDocumentSort);
  const searchQuery = useSelector(selectSearchQuery);
  const selectedDocumentFilters = useSelector(getSelectedDocumentFilters);
  const { logAction } = useTelemetry();
  const { users } = useUsers(currentOrganizationId);
  const { documents, getCachedDocumentById } = useDocuments(
    currentOrganizationId
  );
  const { collections, getCachedCollectionById } = useDocumentCollections(
    currentOrganizationId
  );
  const [selectedCollection] = getCachedCollectionById(
    selectedCollectionId || -1
  );
  const [specialDoc, setSpecialDoc] = useState<number | undefined>(undefined);
  const [specialFileToUpload, setSpecialFileToUpload] = useState<
    SpecialFileToUpload | undefined
  >(undefined);
  const [noCollectionMode, setNoCollectionMode] = useState<boolean>(false);
  const [trashMode, setTrashMode] = useState<boolean>(false);
  const [submitSearch, setSubmitSearch] = useState<boolean>(false);
  const [openAddTagsDialog, setOpenAddTagsDialog] = useState<boolean>(false);
  const [openRemoveTagsDialog, setOpenRemoveTagsDialog] =
    useState<boolean>(false);
  const [openManageTagsDialog, setOpenManageTagsDialog] =
    useState<boolean>(false);
  const [openCopyDocumentsDialog, setOpenCopyDocumentsDialog] =
    useState<MultiDialogType>({
      open: false,
      type: "selected_documents",
    });
  const [openBibMergeDialog, setOpenBibMergeDialog] = useState<MultiDialogType>(
    {
      open: false,
      type: "selected_documents",
    }
  );
  const [openBibTeXExportDialog, setOpenBibTeXExportDialog] =
    useState<MultiDialogType>({
      open: false,
      type: "selected_documents",
    });
  const [openAddToCollectionDialog, setOpenAddToCollectionDialog] =
    useState<MultiDialogType>({
      open: false,
      type: "selected_documents",
    });
  const [draggableDocumentId, setDraggableDocumentId] = useState<
    number | undefined
  >(undefined);
  const [editTagDialog, setOpenEditTagDialog] = useState<boolean>(false);
  const [selectedDocuments, setSelectedDocuments] = useState<
    DocumentReadResponse[]
  >([]);
  const [tableDocumentToUpdate, setTableDocumentToUpdate] = useState<
    DocumentReadResponse | undefined
  >(undefined);
  const [documentToShow, setDocumentToShow] = useState<
    DocumentReadResponse | undefined
  >(undefined);
  const [searchedDocuments, setSearchedDocuments] = useState<
    DocumentReadResponse[] | undefined
  >(undefined);
  // all filters options
  const [documentFilters, setDocumentFilters] = useState<DocumentFilters>(
    defaultDocumentFiltersData
  );
  // selected filters options
  const isFiltersSelected = Object.values(selectedDocumentFilters).some(
    (v) => v.length > 0
  );
  const [documentsForFilter, setDocumentsForFilter] = useState<
    DocumentReadResponse[]
  >([]); // filteredArray by search, tags and filters
  const defaultRowHeight = 80;
  const defaultSearchRowHeight = 90;
  // load documents for the first time
  const [loading, setLoading] = useState<boolean>(true);
  const [openDocumentDownloadSnackBar, setOpenDocumentDownloadSnackBar] =
    useState(false);
  const [snackBarData, setSnackBarData] = useState({
    fileName: "",
    loaded: 0,
    failed: false,
  });
  const [isFirstRenderer, setIsFirstRenderer] = useState<boolean>(true);
  const [selectionRefersh, setSelectionRefresh] = useState<string>(uuidv4());
  const currentUserRole = users?.find((u) => u.id === user?.id)?.role;
  const showLoadingOverlay = submitSearch || loading || !documents;
  const specialFileInputRef = useRef<HTMLInputElement>(null);
  const enableDocumentActions =
    companyFeatures.app.guests.enableDocumentActions ||
    !isGuest(currentUserRole);

  useEffect(() => {
    setSelectionRefresh(uuidv4());
    // deselect documents on each state update
    if (selectedDocuments.length > 0) {
      setSelectedDocuments([]);
    }
    if (documentToShow) {
      setDocumentToShow(undefined);
    }
  }, [selectedCollectionId]);

  useEffect(() => {
    setSelectedDocuments([]);
    setDocumentToShow(undefined);
    setSearchedDocuments([]);
  }, [currentOrganizationId]);

  // reset filters when changing project
  useEffect(() => {
    if (isFirstRenderer) {
      setIsFirstRenderer(false);
      return;
    }
    dispatch(setSelectedDocumentFilters(defaultDocumentFiltersData));
  }, [currentOrganizationId, isSearch, selectedCollectionId]);

  // all changes that were made to documents need to reflect
  // selected documents and document to show
  useEffect(() => {
    if (documents && documents.length > 0 && selectedDocuments.length > 0) {
      setSelectedDocuments(
        selectedDocuments.map((doc) => {
          const [newDoc] = getCachedDocumentById(doc.id);
          if (newDoc) {
            return newDoc;
          }
          return doc;
        })
      );
      if (documentToShow) {
        const newDoc = documents.find((doc) => doc.id === documentToShow.id);
        if (newDoc) {
          setDocumentToShow(newDoc);
        }
      }
    }
  }, [documents]);

  // intercept ctrl+f to shift focus to search in app bar
  useHotkeys(
    "ctrl+f",
    (event): void => {
      event.preventDefault();
      dispatch(setFocusSearchInput(true));
    },
    {}
  );

  const searchCollectionTree = (currentId: number): DocumentCollection[] => {
    if (collections) {
      const [collection] = getCachedCollectionById(currentId);
      if (collection) {
        let array = [collection];
        const search = (c: DocumentCollection) => {
          const childs = collections.filter((coll) => coll.parent_id === c.id);
          if (childs.length > 0) {
            array = [...array, ...childs];
            childs.map((coll) => search(coll));
          }
        };
        search(collection);
        return array;
      }
      return [];
    }

    return [];
  };

  const filteredDocuments: DocumentReadResponse[] = useMemo(() => {
    if (documents && !submitSearch) {
      const filterByCase = (documentList: DocumentReadResponse[]) => {
        let result: DocumentReadResponse[];
        // 1. filtering by trashMode
        result = documentList.filter((document: DocumentReadResponse) =>
          trashMode ? document.is_trash : !document.is_trash
        );
        // 2. sort by docs NOT in a collection
        if (noCollectionMode && collections) {
          const parentItems = collections.filter(
            (collection) => collection.parent_id === 0
          );
          const docsInCollections: number[] = [];
          parentItems.forEach((item) => {
            const collectionTree = searchCollectionTree(item.id);
            collectionTree.forEach((collection) => {
              const newDocs = collection.document_ids.filter(
                (docId) => !docsInCollections.includes(docId)
              );
              if (newDocs.length > 0) {
                docsInCollections.push(...newDocs);
              }
            });
          });
          if (docsInCollections.length > 0) {
            result = result.filter(
              (item) => !docsInCollections.includes(item.id)
            );
          }
        }
        // 3. sort by collections
        if (selectedCollectionId && collections) {
          // deep search for child nodes
          const collectionTree = searchCollectionTree(selectedCollectionId);
          let treeCollectionsDocumentIds: number[] = [];
          collectionTree.forEach((collection) => {
            treeCollectionsDocumentIds = [
              ...treeCollectionsDocumentIds,
              ...collection.document_ids,
            ];
          });

          result = result.filter((item) =>
            treeCollectionsDocumentIds.includes(item.id)
          );
        }
        setDocumentsForFilter(result);
        result = result
          .filter((item) =>
            selectedDocumentFilters.titles.length > 0
              ? selectedDocumentFilters.titles.includes(item?.meta?.title)
              : `${item}`
          )
          .filter((item) => {
            if (selectedDocumentFilters.authors.length > 0) {
              const authors = new Set<string>();
              if (Array.isArray(item.meta.author)) {
                item.meta.author.forEach((author) => authors.add(author));
              } else if (item.meta.author) {
                if (item.meta.author.includes(" and ")) {
                  (item.meta.author as string)
                    .split(" and ")
                    .forEach((value) => value && authors.add(value));
                } else if (item.meta.author.includes(" AND ")) {
                  (item.meta.author as string)
                    .split(" AND ")
                    .forEach((value) => value && authors.add(value));
                } else {
                  authors.add(item.meta.author);
                }
              }
              return selectedDocumentFilters.authors.filter((author) => {
                return Array.from(authors).includes(author);
              }).length;
            }
            return item;
          })
          .filter((item) =>
            selectedDocumentFilters.journals.length > 0
              ? selectedDocumentFilters.journals.includes(item.meta.journal)
              : item
          )
          .filter((item) => {
            if (selectedDocumentFilters.years.length > 0) {
              return selectedDocumentFilters.years.includes(
                parseInt(item.meta.year, 10)
              );
            }
            return item;
          })
          .filter((item: DocumentReadResponse) => {
            if (
              selectedDocumentFilters?.tags &&
              selectedDocumentFilters?.tags.length > 0
            ) {
              const documentTagIds = new Set(item.tag_ids);
              const intersection = selectedDocumentFilters.tags.filter(
                (tag: Tag) => documentTagIds.has(tag.id)
              );
              if (
                tagsModeBrowse === tagsModes.or &&
                intersection.length === 0
              ) {
                return false;
              }
              if (
                tagsModeBrowse === tagsModes.and &&
                intersection.length < selectedDocumentFilters.tags.length
              ) {
                return false;
              }
              return true;
            }
            return item;
          });
        // 4. sort by sortOptions
        result = result.sort((a, b) =>
          sortOptions[documentSort.key].compare(a, b, documentSort.order)
        );
        if (loading) {
          setLoading(false);
        }
        return result;
      };

      if (searchedDocuments && isSearch && !trashMode) {
        // filter current docs base on search result
        const filteredDocs = documents
          .filter(
            (doc) => searchedDocuments.findIndex((d) => d.id === doc.id) > -1
          )
          .filter((doc) => {
            // for search docs show only ready documents
            const status = getDocumentStatus(doc);
            return status.ready;
          });
        // append to each doc snippet
        searchedDocuments.forEach((doc) => {
          const documentIndex = filteredDocs.findIndex((d) => d.id === doc.id);
          if (documentIndex > -1) {
            filteredDocs[documentIndex] = {
              ...filteredDocs[documentIndex],
              snippet: doc.snippet,
            };
            filteredDocs[documentIndex] = {
              ...filteredDocs[documentIndex],
              ranking: doc.ranking,
            };
          }
        });

        return filterByCase(filteredDocs);
      }
      return filterByCase(documents);
    }
    return [];
  }, [
    searchedDocuments,
    documents,
    searchQuery,
    documentSort,
    trashMode,
    noCollectionMode,
    tagsModeBrowse,
    selectedDocumentFilters,
    selectionRefersh,
    collections,
    isSearch,
    submitSearch,
  ]);

  const searchedCollection = useMemo(() => {
    if (selectedCollection) {
      return `"${searchParentCollectionTreeName(
        selectedCollection,
        collections
      ).join(" > ")}"`;
    }
    return noCollectionMode
      ? t("documents_not_in_collections")
      : t("all_documents");
  }, [selectedCollectionId, noCollectionMode]);

  // documents that can be selected
  const readyFilteredDocuments = useMemo(() => {
    return filteredDocuments.filter((doc) => {
      const { failed, in_progress, ocr_in_progress } = getDocumentStatus(doc);
      return !failed && !in_progress && !ocr_in_progress;
    });
  }, [filteredDocuments]);

  const logSelectedDocs = (docs: DocumentReadResponse[]) => {
    if (docs.length > 0) {
      logAction(telemetryAction.select_documents, {
        document_ids: docs.map((doc) => doc.id),
      });
    }
  };

  // re-uploading docs function works with
  // STUB files and failed to load files
  const triggerSpecialInputFile = (id: number) => {
    if (specialFileInputRef && specialFileInputRef.current) {
      specialFileInputRef.current.click();
      setSpecialDoc(id);
    }
  };

  // function for uploading document to special doctypes like "stub"
  const specialFileInputOnChange = () => {
    if (specialFileInputRef?.current?.files?.length) {
      const file = specialFileInputRef.current.files[0];
      const doctype = getTabletype(file);
      setSpecialFileToUpload({
        file,
        documentId: specialDoc || -1,
        isTable: !!doctype,
      });
      specialFileInputRef.current.value = "";
    }
  };

  const loadFile = (fileName: string, loaded: number, failed: boolean) => {
    setSnackBarData({ fileName, loaded, failed });
    setOpenDocumentDownloadSnackBar(true);
  };

  // pass props to snackbar
  const downloadDocumentSnackBarProps = {
    message: (
      <div className="loading-message">
        {!snackBarData.failed ? (
          <>
            {snackBarData.loaded === 0 && (
              <>
                <span style={{ marginRight: "1rem" }}>{t("loading")}</span>
                <LinearProgress
                  color="primary"
                  sx={{
                    minWidth: "200px",
                  }}
                />
              </>
            )}
            {snackBarData.loaded < 100 && snackBarData.loaded > 0 && (
              <>
                <span style={{ marginRight: "1rem" }}>{t("downloading")}</span>
                <LinearProgress
                  variant="determinate"
                  value={snackBarData.loaded}
                  color="primary"
                  sx={{
                    minWidth: "200px",
                  }}
                />
                <span>{snackBarData.loaded}%</span>
              </>
            )}
            {snackBarData.loaded === 100 && (
              <>
                <Typography variant="body2">
                  {t("downloaded")} {snackBarData.fileName}
                </Typography>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={() => setOpenDocumentDownloadSnackBar(false)}
                >
                  <CloseIcon color="action" fontSize="small" />
                </IconButton>
              </>
            )}
          </>
        ) : (
          <>
            <Typography
              variant="body2"
              style={{
                color: theme.red.main,
              }}
            >
              {snackBarData.fileName}
            </Typography>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setOpenDocumentDownloadSnackBar(false)}
            >
              <CloseIcon color="action" fontSize="small" />
            </IconButton>
          </>
        )}
      </div>
    ),
    anchorPosition: {
      vertical: "bottom",
      horizontal: "left",
    },
    autoHideDuration: snackBarData.loaded === 100 ? 5000 : null,
  };

  const multiSelectDocuments = (documentId: number, e: any) => {
    if (e.shiftKey && selectedDocuments) {
      let lastIndex;
      let firstIndex;
      const lastSelectedIndex = filteredDocuments
        .map((item) => item.id)
        .indexOf(documentId);
      let firstSelectedIndex = filteredDocuments
        .map((item) => item.id)
        .indexOf(selectedDocuments[0]?.id);
      if (firstSelectedIndex > lastSelectedIndex) {
        firstSelectedIndex = filteredDocuments
          .map((item) => item.id)
          .indexOf(selectedDocuments[selectedDocuments.length - 1]?.id);
        firstIndex = lastSelectedIndex;
        lastIndex = firstSelectedIndex;
      } else {
        firstIndex = firstSelectedIndex;
        lastIndex = lastSelectedIndex;
      }
      let newArray = filteredDocuments.slice(firstIndex, lastIndex + 1);
      // filter broken documents and doc in progress
      newArray = newArray.filter((doc) => {
        const { failed, in_progress, ocr_in_progress } = getDocumentStatus(doc);
        return !failed && !in_progress && !ocr_in_progress;
      });
      logSelectedDocs(newArray);
      setSelectedDocuments(newArray);
    }
    if (e.metaKey || (e.ctrlKey && selectedDocuments)) {
      const indexDoc = filteredDocuments
        .map((item) => item.id)
        .indexOf(documentId);
      if (indexDoc !== -1) {
        const doc = filteredDocuments[indexDoc];
        setSelectedDocuments([
          ...(selectedDocuments as DocumentReadResponse[]),
          doc,
        ]);
      }
    }
  };

  const showErrorMessage =
    !showLoadingOverlay &&
    (documents.length === 0 ||
      (filteredDocuments.length === 0 && !isFiltersSelected));

  // render rows from react-virtualized
  const rowRenderer = ({ index, key, style }: ListRowProps) => {
    return (
      <DocumentListItem
        key={key}
        style={style}
        document={filteredDocuments[index]}
        selectedDocuments={selectedDocuments || []}
        setTableDocumentToUpdate={setTableDocumentToUpdate}
        multiSelectDocuments={multiSelectDocuments}
        setSelectedDocuments={(docs: DocumentReadResponse[]) => {
          logSelectedDocs(docs);
          setSelectedDocuments(docs);
        }}
      />
    );
  };

  return (
    <DocumentsContext.Provider
      value={{
        noCollectionMode,
        setNoCollectionMode,
        trashMode,
        setTrashMode,
        selectedDocuments,
        setSelectedDocuments,
        searchedDocuments,
        setSearchedDocuments,
        submitSearch,
        setSubmitSearch,
        documentToShow,
        setDocumentToShow,
        openAddTagsDialog,
        openRemoveTagsDialog,
        setOpenRemoveTagsDialog,
        setOpenAddTagsDialog,
        openCopyDocumentsDialog,
        setOpenCopyDocumentsDialog,
        openBibMergeDialog,
        setOpenBibMergeDialog,
        openBibTeXExportDialog,
        setOpenBibTeXExportDialog,
        openAddToCollectionDialog,
        setOpenAddToCollectionDialog,
        editTagDialog,
        setOpenEditTagDialog,
        loadFile,
        triggerSpecialInputFile,
        openManageTagsDialog,
        setOpenManageTagsDialog,
        draggableDocumentId,
        setDraggableDocumentId,
      }}
    >
      <DndProvider backend={HTML5Backend}>
        <CustomDocumentDragLayer />
        <Container className="documents-container">
          <Box className="toolbar">
            {smScreen && (
              <IconButton
                className="sidebar-close-icon"
                size="medium"
                onClick={() => dispatch(setSideBarOpen(true))}
                color="primary"
              >
                <MenuOutlinedIcon fontSize="medium" color="action" />
              </IconButton>
            )}
            <DocumentToolbar />
          </Box>
          {showErrorMessage ? (
            <>
              {!showLoadingOverlay && documents.length === 0 && (
                <Box className="error-message">
                  <img src="/img/add-documents.svg" alt="Add documents" />
                  <Typography variant="h6" color="textSecondary">
                    {t("no_items_in_workspace")}
                  </Typography>
                  {!isGuest(currentUserRole) && (
                    <>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        fontWeight={400}
                      >
                        {t("start_adding_documents")}
                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        size="medium"
                        disabled={isGuest(currentUserRole)}
                        onClick={() => {
                          navigate(routePaths.add);
                        }}
                      >
                        {t("add_documents")}
                      </Button>
                    </>
                  )}
                </Box>
              )}
              {!showLoadingOverlay &&
                filteredDocuments.length === 0 &&
                documents.length > 0 && (
                  <Box className="error-message">
                    <img
                      src="/img/no-documents.svg"
                      alt={t("no_documents_found")}
                    />
                    <Typography
                      variant="h6"
                      color="textSecondary"
                      fontWeight={400}
                    >
                      {t("no_documents_found")}
                    </Typography>
                  </Box>
                )}
            </>
          ) : (
            <Box className="document-container">
              <Box className="document-container-actions">
                {!showLoadingOverlay ? (
                  <>
                    {enableDocumentActions && (
                      <Checkbox
                        color="primary"
                        className="document-checkbox"
                        size="small"
                        disabled={readyFilteredDocuments.length === 0}
                        checked={selectedDocuments.length > 0}
                        indeterminate={
                          selectedDocuments.length > 0 &&
                          selectedDocuments.length <
                            readyFilteredDocuments.length
                        }
                        onChange={() => {
                          if (selectedDocuments.length > 0) {
                            setSelectedDocuments([]);
                          } else {
                            setSelectedDocuments(readyFilteredDocuments);
                          }
                        }}
                      />
                    )}
                    <SortDocumentMenu
                      documentSort={documentSort}
                      setSortValue={(sort: ISortOrder) => {
                        dispatch(setDocumentSort(sort));
                      }}
                      searchEnabled={isSearch}
                    />
                    <DocumentFilter
                      selectedFilters={selectedDocumentFilters}
                      filteredDocuments={documentsForFilter}
                      filters={documentFilters}
                      setFilters={setDocumentFilters}
                      setSelectedDocumentFilters={(
                        filters: DocumentFilters
                      ) => {
                        dispatch(setSelectedDocumentFilters(filters));
                      }}
                      setSelectedDocuments={setSelectedDocuments}
                    />
                    {enableDocumentActions && <DocumentActions />}
                  </>
                ) : (
                  <>
                    <Skeleton variant="circular" width={38} height={38} />
                    <Skeleton variant="rounded" width={150} height={38} />
                    <Skeleton variant="circular" width={38} height={38} />
                  </>
                )}
              </Box>
              <Collapse
                className="search-box"
                in={!submitSearch && isSearch && !showLoadingOverlay}
              >
                <Typography variant="body1" className="search-text">
                  {t("results_for_search", {
                    query: searchQuery,
                    collection: searchedCollection,
                  })}
                </Typography>
                <IconButton
                  size="small"
                  onClick={() => {
                    if (isSearch) {
                      dispatch(setIsSearch(false));
                    }
                    dispatch(setSearchQuery(""));
                  }}
                >
                  <ClearOutlined fontSize="small" />
                </IconButton>
              </Collapse>
              <Box className="document-list-container">
                <RootContainer>
                  <AutoSizer>
                    {({ width, height }) => (
                      <VirtualizedList
                        height={height}
                        overscanRowCount={15}
                        noRowsRenderer={() =>
                          showLoadingOverlay ? <LoadingOverlay /> : <></>
                        }
                        rowCount={filteredDocuments.length}
                        rowHeight={
                          isSearch && searchTypeQuery === SearchType.fulltext
                            ? defaultSearchRowHeight
                            : defaultRowHeight
                        }
                        rowRenderer={rowRenderer}
                        width={width}
                      />
                    )}
                  </AutoSizer>
                </RootContainer>
                <DocumentRightSideBar />
              </Box>
            </Box>
          )}
        </Container>
      </DndProvider>
      {/* special input for stub and failed docs */}
      <input
        accept=".txt, .html, .pdf, .ppt, .pptx, .doc, .docx, .jpg, .png, .jpeg, .webp, .csv, .xlsx, .xls"
        id="upload_special_file"
        type="file"
        style={{ display: "none" }}
        onChange={specialFileInputOnChange}
        ref={specialFileInputRef}
      />
      {/* snackbar for downloading documents */}
      <SnackBar
        open={openDocumentDownloadSnackBar}
        snackBarProps={downloadDocumentSnackBarProps}
      />
      {specialFileToUpload && (
        <SpecialFileUploaderDialog
          setFileToUpload={setSpecialFileToUpload}
          fileToUpload={specialFileToUpload}
        />
      )}
      {openManageTagsDialog && <ManageTagsDialog />}
      {((openAddToCollectionDialog.open && documentToShow) ||
        (openAddToCollectionDialog.open && selectedDocuments.length > 0)) && (
        <AddToCollectionDialog
          documents={
            openAddToCollectionDialog.type === "document_to_show" &&
            documentToShow
              ? [documentToShow]
              : selectedDocuments
          }
          setOpen={(open: boolean) => {
            setOpenAddToCollectionDialog({
              open,
              type: "selected_documents",
            });
          }}
        />
      )}
      {((openBibTeXExportDialog.open && documentToShow) ||
        (openBibTeXExportDialog.open && selectedDocuments.length > 0)) && (
        <BibTeXExportDialog
          currentDocuments={
            openBibTeXExportDialog.type === "document_to_show" && documentToShow
              ? [documentToShow]
              : selectedDocuments
          }
          setOpen={(open: boolean) => {
            setOpenBibTeXExportDialog({
              open,
              type: "selected_documents",
            });
          }}
        />
      )}
      {editTagDialog && (
        <EditDocumentTagsDialog
          document={selectedDocuments[0]}
          setOpen={setOpenEditTagDialog}
        />
      )}
      {openRemoveTagsDialog && (
        <RemoveTagsDialog
          setOpen={setOpenRemoveTagsDialog}
          selectedDocuments={selectedDocuments}
        />
      )}
      {openAddTagsDialog && (
        <AddTagsDialog
          setOpen={setOpenAddTagsDialog}
          selectedDocuments={selectedDocuments}
        />
      )}
      {((openCopyDocumentsDialog.open && documentToShow) ||
        (openCopyDocumentsDialog.open && selectedDocuments.length > 0)) && (
        <CopyDocumentsDialog
          selectedDocuments={
            openCopyDocumentsDialog.type === "document_to_show" &&
            documentToShow
              ? [documentToShow]
              : selectedDocuments
          }
          setOpen={(open: boolean) => {
            setOpenCopyDocumentsDialog({
              open,
              type: "selected_documents",
            });
          }}
        />
      )}
      {((openBibMergeDialog.open && documentToShow) ||
        (openBibMergeDialog.open && selectedDocuments.length > 0)) && (
        <BibMergeDialog
          document={
            openBibMergeDialog.type === "document_to_show" && documentToShow
              ? documentToShow
              : selectedDocuments[0]
          }
          setOpen={(open: boolean) => {
            setOpenBibMergeDialog({
              open,
              type: "selected_documents",
            });
          }}
        />
      )}
      {tableDocumentToUpdate && (
        <TableSourceDialog
          document={tableDocumentToUpdate}
          close={() => {
            setTableDocumentToUpdate(undefined);
          }}
        />
      )}
      {filteredDocuments.length > 0 &&
        getDocumentStatus(filteredDocuments[0]).can_be_viewed &&
        !user?.meta?.citeOnboarding?.documentsCompleted && (
          <DocumentsOnboarding documents={filteredDocuments} />
        )}
    </DocumentsContext.Provider>
  );
};

export default Documents;
