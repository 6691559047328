import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import aiCreateService, { useAICreateQuestions } from "api/aiCreateService";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { addAlert } from "store/features/general/slice";

const AddExtractedKnowledgeQuestionDialog: React.FC<{
  projectId: number;
  documentId: number;
  show: boolean;
  close: () => void;
}> = ({ projectId, documentId, show, close }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [question, setQuestion] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { upsertCachedAICreateQuestion } = useAICreateQuestions(projectId);

  const handleClose = () => {
    close();
    setTimeout(() => {
      setQuestion("");
    }, 500);
  };

  const handleAddQuestion = () => {
    setLoading(true);
    aiCreateService
      .createAICreateQA(projectId, {
        document_id: documentId,
        qa_type: "user",
        query: question,
      })
      .then(({ data }) => {
        upsertCachedAICreateQuestion(data);
        setLoading(false);
        handleClose();
      })
      .catch(() => {
        setLoading(false);
        dispatch(
          addAlert({
            severity: "error",
            autoHideDuration: 5000,
            alert: {
              message: t("something_went_wrong_please"),
            },
          })
        );
      });
  };

  return (
    <Dialog open={show} onClose={close} fullWidth maxWidth="sm">
      <DialogTitle>
        {t("add_question")}
        <IconButton onClick={close}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          value={question}
          color="primary"
          variant="outlined"
          fullWidth
          type="text"
          placeholder="Type your question"
          size="small"
          onChange={(e) => {
            setQuestion(e.target.value);
          }}
          onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
            if (event.key === "Enter" && question.length > 0) {
              event.preventDefault();
              handleAddQuestion();
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          color="primary"
          size="medium"
          onClick={handleClose}
        >
          {t("cancel")}
        </Button>
        <LoadingButton
          onClick={handleAddQuestion}
          color="primary"
          size="medium"
          variant="contained"
          disabled={question.length === 0}
          loading={loading}
        >
          {t("add")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddExtractedKnowledgeQuestionDialog;
