import React, { useContext } from "react";
import { useDragLayer } from "react-dnd";
import { DocumentsContext } from "pages/Documents";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import { Box, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

const CustomDocumentDragLayer: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  // user can drag documents not from selected list, we need to show propert icon
  const { selectedDocuments, draggableDocumentId } =
    useContext(DocumentsContext);

  const { isDragging, currentOffset } = useDragLayer((monitor) => ({
    isDragging: monitor.isDragging(),
    currentOffset: monitor.getClientOffset(),
    item: monitor.getItem(),
  }));

  if (!isDragging) {
    return null;
  }

  const isMultipleDocs = selectedDocuments?.some(
    (doc) => doc.id === draggableDocumentId
  );

  return isDragging && currentOffset && draggableDocumentId ? (
    <Box
      sx={{
        // functional
        transform: `translate(${currentOffset.x - 5}px, ${
          currentOffset.y - 5
        }px)`,
        position: "fixed",
        top: 0,
        left: 0,
        pointerEvents: "none",
        zIndex: 10000,
        // design only
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0.5rem 1rem",
        color: theme.background.light,
        background: theme.palette.primary.main,
        borderRadius: "4px",
        gap: "0.5rem",
      }}
    >
      <ArticleOutlinedIcon fontSize="large" />
      <Typography variant="body1" fontWeight={500}>
        {t("move_documents", {
          documents: isMultipleDocs
            ? `${selectedDocuments.length} ${
                selectedDocuments.length > 1
                  ? t("documents_low_case")
                  : t("document_lowercase")
              }`
            : t("one_document"),
        })}
      </Typography>
    </Box>
  ) : null;
};

export default CustomDocumentDragLayer;
