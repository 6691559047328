import React, { useEffect } from "react";
import aiService from "api/aiService";
import { useDispatch } from "react-redux";
import { addAlert } from "store/features/general/slice";
import { useTranslation } from "react-i18next";

const OpenAiProvider: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const getStatus = () => {
    aiService.getAiStatus().then(({ data }) => {
      const aiError = sessionStorage.getItem("show-ai-error");
      if (!data.ai_online && aiError === "true") {
        dispatch(
          addAlert({
            severity: "warning",
            alert: {
              message: t("language_model_outage"),
            },
            autoHideDuration: 10000,
          })
        );
        sessionStorage.setItem("show-ai-error", String(false));
      }
    });
  };

  useEffect(() => {
    const aiError = sessionStorage.getItem("show-ai-error");
    if (!aiError) {
      sessionStorage.setItem("show-ai-error", String(true));
    }
    getStatus();
    setInterval(() => {
      getStatus();
    }, 5 * 60 * 1000);
  }, []);

  return null;
};

export default OpenAiProvider;
