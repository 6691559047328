import i18n from "../i18n";

export type AILanguage =
  | "English (US)"
  | "English (UK)"
  | "Chinese (Simplified)"
  | "Chinese (Traditional)"
  | "Korean"
  | "Japanese"
  | "German"
  | "French"
  | "Spanish"
  | "Italian"
  | "Ukrainian"
  | "Russian"
  | "Slovak"
  | "Czech"
  | "Polish";

export const AILanguages: AILanguage[] = [
  "English (US)",
  "English (UK)",
  "Chinese (Simplified)",
  "Chinese (Traditional)",
  "Korean",
  "Japanese",
  "German",
  "French",
  "Spanish",
  "Italian",
  "Ukrainian",
  "Russian",
  "Slovak",
  "Czech",
  "Polish",
];

export const AiLanguagesShortcuts: { [key: string]: string } = {
  "English (US)": "US",
  "English (UK)": "UK",
  "Chinese (Simplified)": "简体中文",
  "Chinese (Traditional)": "繁體中文",
  Korean: "한국어",
  Japanese: "日本語",
  German: "DE",
  French: "FR",
  Spanish: "ES",
  Italian: "IT",
  Ukrainian: "УK",
  Russian: "PУ",
  Slovak: "SK",
  Czech: "CS",
  Polish: "PL",
};

export type SelectedTextAiOperations =
  | "summarize"
  | "paraphrase"
  | "translate"
  | "explain"
  | "suggest"
  | "identify";

export const getSelectedTextAiOperationDescription = (
  action: SelectedTextAiOperations,
  language: AILanguage,
  text: string
) => {
  if (action === "summarize") {
    return i18n.t("summarize_text", { text });
  }
  if (action === "paraphrase") {
    return i18n.t("paraphrase_text", { text });
  }
  if (action === "translate") {
    return i18n.t("translate_text", { text, language });
  }
  if (action === "explain") {
    return i18n.t("explain_simpler_language", { text });
  }
  if (action === "suggest") {
    return i18n.t("suggest_search_terms_text", { text });
  }
  return i18n.t("identify_key_points_text", { text });
};

export const terminal_status_names = [
  "success",
  "failure",
  "revoked",
  "rejected",
  "dep_failure",
  "None",
];

export const maxAmountOfCharacters = 400;
