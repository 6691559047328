import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  TextField,
  useTheme,
} from "@mui/material";
import { useDocumentCollections } from "api/documentCollectionService";
import CloseIcon from "@mui/icons-material/Close";
import CollectionSelector from "components/helpers/CollectionSelector";
import { useSelector } from "react-redux";
import {
  selectCurrentOrganizationId,
  selectUser,
} from "store/features/session/slice";
import { useTranslation } from "react-i18next";

const CollectionDialog: React.FC<{
  selectCreatedCollection?: boolean;
  editCollectionId: number;
  setOpen: (id?: number) => void;
}> = ({ selectCreatedCollection, editCollectionId, setOpen }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const user = useSelector(selectUser);
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const {
    getCachedCollectionById,
    collections,
    updateCollectionMutation,
    createCollectionMutation,
  } = useDocumentCollections(currentOrganizationId);
  const [currentCollection] = getCachedCollectionById(editCollectionId);
  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [collectionData, setCollectionData] = useState({
    name: "",
    parent_id: 0,
    is_shared: false,
  });

  useEffect(() => {
    if (currentCollection) {
      setCollectionData({
        name: currentCollection.name,
        parent_id: currentCollection.parent_id,
        is_shared: !!currentCollection.is_shared,
      });
    }
  }, [currentCollection]);

  const isNameDuplicated = () => {
    if (collections) {
      const siblings = collections?.filter(
        (coll) =>
          coll.parent_id === collectionData.parent_id &&
          coll.id !== editCollectionId
      );
      if (siblings.some((sib) => sib.name === collectionData.name)) {
        return true;
      }
      return false;
    }
    return false;
  };

  const updateCollection = () => {
    if (isNameDuplicated()) {
      setNameErrorMessage(t("collection_name_taken"));
    } else {
      updateCollectionMutation.mutate({
        collections: [
          {
            id: editCollectionId,
            ...collectionData,
            name: collectionData.name,
            parent_id: collectionData.parent_id,
            ...(collectionData.parent_id === 0 && {
              is_shared: collectionData.is_shared,
            }),
          },
        ],
      });
      setOpen();
    }
  };

  const createCollection = () => {
    if (isNameDuplicated()) {
      setNameErrorMessage(t("collection_name_taken"));
    } else {
      createCollectionMutation.mutate(
        {
          organization_id: currentOrganizationId || 0,
          name: collectionData.name,
          parent_id: collectionData.parent_id,
          ...(collectionData.parent_id === 0 && {
            is_shared: collectionData.is_shared,
          }),
        },
        {
          onSuccess: ({ data }) => {
            setOpen(selectCreatedCollection ? data.id : undefined);
          },
        }
      );
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" onClose={() => setOpen()} open>
      <DialogTitle>
        {editCollectionId === -1
          ? t("create_new_collection")
          : t("edit_collection")}
        <IconButton aria-label="close" onClick={() => setOpen()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          value={collectionData.name}
          variant="outlined"
          color="primary"
          fullWidth
          name="name"
          type="text"
          placeholder={t("collection_name")}
          error={!!nameErrorMessage}
          helperText={nameErrorMessage}
          size="small"
          autoFocus
          sx={{
            marginBottom: theme.spacing(2),
          }}
          onChange={(e) => {
            setCollectionData({
              ...collectionData,
              name: e.target.value,
            });
            setNameErrorMessage("");
          }}
        />
        <CollectionSelector
          disabled={collectionData.is_shared}
          collectionId={editCollectionId > -1 ? editCollectionId : undefined}
          currentParentId={collectionData.parent_id}
          collectionToUse={(id?: number) => {
            setCollectionData({
              ...collectionData,
              parent_id: id || 0,
            });
          }}
        />
        {collectionData.parent_id === 0 &&
          (editCollectionId === -1 ||
            currentCollection?.user_id === user?.id) && (
            <FormGroup
              sx={{
                marginTop: theme.spacing(0.5),
                marginLeft: theme.spacing(0.5),
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={collectionData.is_shared}
                    onChange={() => {
                      setCollectionData({
                        ...collectionData,
                        is_shared: !collectionData.is_shared,
                        parent_id: 0,
                      });
                    }}
                  />
                }
                label={t("share_collection_within_workspace")}
                sx={{
                  width: "fit-content",
                }}
              />
            </FormGroup>
          )}
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          size="medium"
          disabled={!collectionData.name}
          onClick={() => {
            if (editCollectionId > -1) {
              updateCollection();
            } else {
              createCollection();
            }
          }}
        >
          {editCollectionId === -1 ? t("create") : t("update")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CollectionDialog;
