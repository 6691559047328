import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDocuments } from "api/documentService";
import { useTags } from "api/tagService";
import MetaField from "components/Tabs/DocumentPropertyTab/MetaField";
import moment from "moment";
import TagsSection from "components/Tags/TagsSection";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  DocumentReadResponse,
  Organization,
  OrganizationSchema,
} from "models/api/response.types";
import {
  defaultOrganizationSchema,
  EntryName,
  entryTypes,
  sortSchemaOrder,
} from "utils/bibtexSchema";
import VisibilityIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import displayTextWithMath from "utils/displayTextWithMath";
import { applyDocumentDetexification } from "utils/detexify";
import { useUsers } from "api/userService";
import { useSelector } from "react-redux";
import { selectUser } from "store/features/session/slice";
import { isGuest } from "models/components/Permissions.models";
import { isStub, isTable } from "utils/documentStatus";
import { useTranslation } from "react-i18next";

export const Wrapper = styled(Box)(() => ({
  height: "100%",
  overflowY: "auto",
  overflowX: "hidden",
  padding: "1px",
  "& .viewIcon": {
    "& svg": {
      marginRight: "18px",
      width: "17.5px",
      height: "17.5px",
    },
  },
  "& .stub-file-property": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  "& .descriptionTextArea": {
    cursor: "text",
    padding: "8px 16px 16px",
    lineHeight: 1.7,
    color: "#6C6C6C",
    fontFamily: "inherit",
    fontSize: "14px",
    "& mjx-math": {
      whiteSpace: "break-spaces",
    },
    "&:focus": {
      outline: "none",
      border: "none",
    },
  },
  "& .showAllFields": {
    borderRadius: 0,
    width: "100%",
    padding: "0.5rem",
  },
  "& .metadataButtons": {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    "& .mdi-icon": {
      width: "18px",
      height: "18px",
    },
    marginBottom: "0.5rem",
  },
  "& .MuiAccordion-root": {
    borderRadius: "4px",
    boxShadow: "1px 1px 4px rgba(0, 0, 0, 0.2)",
    "&::before": {
      display: "none",
    },
  },
  "& .MuiAccordion-root:not(:last-child)": {
    marginBottom: "1rem",
  },
  "& .fileText": {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
}));

const DocumentPropertyTab: React.FC<{
  document: DocumentReadResponse;
  organization?: Organization;
  triggerSpecialInputFile?: (docId: number) => void;
}> = ({ organization, document: passedDocument, triggerSpecialInputFile }) => {
  const { t } = useTranslation();
  // we need this for see socket updates
  const { getCachedDocumentById, updateDocumentMutation } = useDocuments(
    organization?.id
  );
  const user = useSelector(selectUser);
  const { getCachedTagById } = useTags(organization?.id);
  const [reactQueryDoc] = getCachedDocumentById(passedDocument.id);
  // if no organization, mean user is in public access
  // need to choose fetched document instead od docin react query
  const selectedDocument =
    organization && reactQueryDoc ? reactQueryDoc : passedDocument;
  const { users } = useUsers(organization?.id);
  const currentUserRole = users?.find((u) => u.id === user?.id)?.role;
  const document = applyDocumentDetexification(selectedDocument);
  const [showEmpty, setShowEmpty] = useState<boolean>(false);
  const [focusDescription, setFocusDescription] = useState<boolean>(false);
  const description = document?.meta.abstract || "";
  const meta = document?.meta || {};
  const detexifiedMeta = document?.detexiFiedMeta || {};

  const renderMetaFields = (schema: OrganizationSchema[]) => {
    return schema.map(({ name, type, label }) => {
      return (
        <MetaField
          document={document}
          // if public access, user can't update
          disabled={!organization || isGuest(currentUserRole)}
          key={`${document?.id}.${name}`}
          name={name}
          type={type}
          label={label}
          currentValue={meta[name] || ""}
          currentDetexifiedValue={detexifiedMeta[name] || ""}
          onChange={(newValue: unknown) => {
            const newMeta = { ...meta, [name]: newValue };
            updateDocumentMutation.mutate({
              id: document.id,
              payload: { meta: newMeta },
            });
          }}
          showFileName={(show: boolean) => {
            const showTo = document?.ui?.show_file_name || [];
            updateDocumentMutation.mutate({
              id: document.id,
              payload: {
                ui: {
                  ...document.ui,
                  show_file_name: show
                    ? [...showTo, user?.id]
                    : showTo.filter((id: number) => id !== user?.id),
                },
              },
            });
          }}
        />
      );
    });
  };

  const getOrganizationSchemaFields = () => {
    if (document) {
      let filteredSchema;
      if (organization) {
        filteredSchema = organization.schema.filter(({ name }) => {
          if (
            showEmpty === false &&
            !entryTypes[meta.entrytype as EntryName]?.required.includes(name)
          ) {
            if (
              meta[name] === undefined ||
              meta[name] === null ||
              meta[name] === ""
            ) {
              return false;
            }
          }

          return true;
        });
      } else {
        const defaultSchema: OrganizationSchema[] = [];
        const extraSchema: OrganizationSchema[] = [];
        Object.keys(document.meta).forEach((property: any) => {
          const index = defaultOrganizationSchema.findIndex(
            (defaultProp) => defaultProp.name === property
          );
          if (index > -1) {
            defaultSchema.push(defaultOrganizationSchema[index]);
          } else {
            extraSchema.push({
              name: property,
              label: property,
              type: Array.isArray(document.meta[property]) ? "list" : "text",
              default: false,
            });
          }
        });
        filteredSchema = [...defaultSchema, ...extraSchema];
      }

      const sortedSchema: OrganizationSchema[] = sortSchemaOrder(
        filteredSchema,
        document.meta.entrytype
      );
      return renderMetaFields(sortedSchema);
    }
    return null;
  };

  const getTableFields = () => {
    if (document) {
      const schema: OrganizationSchema[] = [
        {
          label: t("title"),
          name: "title",
          type: "text",
          default: true,
          description: t("title_of_work"),
        },
        {
          label: t("authors"),
          name: "author",
          type: "list",
          default: true,
          description: t("list_of_authors"),
        },
      ];
      return renderMetaFields(schema);
    }

    return null;
  };

  if (document) {
    const documentTags = document.tag_ids.filter((id: number) => {
      const [tag] = getCachedTagById(id);
      return tag;
    });

    const isStubType = isStub(document);
    const isTableType = isTable(document);

    return (
      <Wrapper>
        <Accordion square defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body2" lineHeight="inherit">
              {t("abstract_description")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              padding: 0,
            }}
          >
            <div
              className="descriptionTextArea"
              contentEditable={Boolean(
                !isGuest(currentUserRole) && organization
              )}
              suppressContentEditableWarning
              onBlur={(e) => {
                if (e.currentTarget.textContent !== meta?.abstract) {
                  const newMeta = {
                    ...meta,
                    abstract: e.currentTarget.textContent,
                  };
                  updateDocumentMutation.mutate({
                    id: document.id,
                    payload: { meta: newMeta },
                  });
                }
                if (focusDescription) {
                  setFocusDescription(false);
                }
              }}
              onFocus={(e) => {
                e.target.focus();
                setFocusDescription(true);
              }}
              onKeyDown={(e) => {
                e.stopPropagation();
                if (e.key === "Enter") {
                  e.preventDefault();
                  window?.getSelection()?.removeAllRanges();
                  setFocusDescription(false);
                  const newMeta = {
                    ...meta,
                    abstract: e.currentTarget.textContent,
                  };
                  updateDocumentMutation.mutate({
                    id: document.id,
                    payload: { meta: newMeta },
                  });
                }
              }}
            >
              {focusDescription
                ? description
                : description.length > 0
                ? displayTextWithMath(detexifiedMeta.abstract)
                : t("add_description")}
            </div>
          </AccordionDetails>
        </Accordion>
        {isTableType ? (
          <Accordion square defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="body2" lineHeight="inherit">
                {t("table_properties")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                padding: 0,
              }}
            >
              <div className="OrganizationSchema">{getTableFields()}</div>
            </AccordionDetails>
          </Accordion>
        ) : (
          <Accordion square defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="body2" lineHeight="inherit">
                {t("document_properties")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                padding: 0,
              }}
            >
              <div className="OrganizationSchema">
                {getOrganizationSchemaFields()}
                {organization && (
                  <Button
                    className="showAllFields"
                    size="small"
                    startIcon={
                      showEmpty ? (
                        <VisibilityOffOutlinedIcon fontSize="small" />
                      ) : (
                        <VisibilityIcon fontSize="small" />
                      )
                    }
                    onClick={() => setShowEmpty(!showEmpty)}
                  >
                    {showEmpty ? (
                      <Typography variant="body2">
                        {t("hide_empty_fields")}
                      </Typography>
                    ) : (
                      <Typography variant="body2">
                        {t("show_all_fields")}
                      </Typography>
                    )}
                  </Button>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        )}
        {organization && (
          <Accordion square defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="body2" lineHeight="inherit">
                {t("tags")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TagsSection
                document={document}
                sideBarTagIds={documentTags}
                userRole={currentUserRole}
              />
            </AccordionDetails>
          </Accordion>
        )}
        {!isTableType && (
          <Accordion square defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="body2" lineHeight="inherit">
                {t("file_properties")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {!isStubType ? (
                <>
                  <Typography
                    className="fileText"
                    variant="body2"
                    color="textSecondary"
                  >
                    <strong>{t("filename_col")}</strong> {document.filename}
                  </Typography>
                  <Typography
                    className="fileText"
                    variant="body2"
                    color="textSecondary"
                  >
                    <strong>{t("uploaded_col")}</strong>{" "}
                    {moment(document.created_at).format("l")}{" "}
                    {moment(document.created_at).format("LT")}
                  </Typography>
                  <Typography
                    className="fileText"
                    variant="body2"
                    color="textSecondary"
                  >
                    <strong>{t("uploaded_by_col")}</strong> {document.user.name}
                  </Typography>
                  <Typography
                    className="fileText"
                    variant="body2"
                    color="textSecondary"
                  >
                    <strong>{t("filetype_col")}</strong>{" "}
                    {document.doctype.toUpperCase()} {t("document")}
                  </Typography>
                  <Typography
                    className="fileText"
                    variant="body2"
                    color="textSecondary"
                  >
                    <strong>{t("size_col")}</strong>{" "}
                    {(document.file_size / 1e6).toFixed(2)}
                    &nbsp;{t("mb")}
                  </Typography>
                  <Typography
                    className="fileText"
                    variant="body2"
                    color="textSecondary"
                  >
                    <strong>{t("length_col")}</strong> {document.pages}&nbsp;
                    {document.pages > 1
                      ? t("pages_lowercase")
                      : t("page_lowercase")}
                  </Typography>
                </>
              ) : (
                <Box className="stub-file-property">
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    fontStyle="italic"
                  >
                    {t("no_attached_document")}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => {
                      if (triggerSpecialInputFile) {
                        triggerSpecialInputFile(document.id);
                      }
                    }}
                  >
                    {t("attach_document")}
                  </Button>
                </Box>
              )}
            </AccordionDetails>
          </Accordion>
        )}
      </Wrapper>
    );
  }

  return <></>;
};

export default DocumentPropertyTab;
