import { OrganizationSchema } from "models/api/response.types";
import i18n from "../i18n";

export type EntryName =
  | "article"
  | "book"
  | "booklet"
  | "inproceedings"
  | "conference"
  | "inbook"
  | "incollection"
  | "manual"
  | "mastersthesis"
  | "misc"
  | "phdthesis"
  | "proceedings"
  | "techreport"
  | "unpublished";

interface Entry {
  label: string;
  description: string;
  required: string[];
  optional: string[];
}

export interface SchemaData {
  label: string;
  name: string;
  type: string;
  default: boolean;
  description: string;
}

export const entryTypes: Record<EntryName, Entry> = {
  article: {
    label: i18n.t("article"),
    description: i18n.t("article_description"),
    required: ["author", "title", "journal", "year"],
    optional: ["volume", "number", "pages", "month", "note"],
  },
  book: {
    label: i18n.t("book"),
    description: i18n.t("book_description"),
    required: ["author", "editor", "title", "publisher", "year"],
    optional: [
      "volume",
      "number",
      "series",
      "address",
      "edition",
      "month",
      "note",
    ],
  },
  booklet: {
    label: i18n.t("booklet"),
    description: i18n.t("booklet_description"),
    required: ["title"],
    optional: ["author", "howpublished", "address", "month", "year", "note"],
  },
  inproceedings: {
    label: i18n.t("inproceedings"),
    description: i18n.t("inproceedings_description"),
    required: ["author", "title", "booktitle", "year"],
    optional: [
      "editor",
      "volume",
      "number",
      "series",
      "pages",
      "address",
      "month",
      "organization",
      "publisher",
      "note",
    ],
  },
  conference: {
    label: i18n.t("conference"),
    description: i18n.t("conference_description"),
    required: ["author", "title", "booktitle", "year"],
    optional: [
      "editor",
      "volume",
      "number",
      "series",
      "pages",
      "address",
      "month",
      "organization",
      "publisher",
      "note",
    ],
  },
  inbook: {
    label: i18n.t("inbook"),
    description: i18n.t("inbook_description"),
    required: [
      "author",
      "editor",
      "title",
      "chapter",
      "pages",
      "publisher",
      "year",
    ],
    optional: [
      "volume",
      "number",
      "series",
      "type",
      "address",
      "edition",
      "month",
      "note",
    ],
  },
  incollection: {
    label: i18n.t("incollection"),
    description: i18n.t("incollection_description"),
    required: ["author", "title", "booktitle", "publisher", "year"],
    optional: [
      "editor",
      "volume",
      "number",
      "series",
      "type",
      "chapter",
      "pages",
      "address",
      "edition",
      "month",
      "note",
    ],
  },
  manual: {
    label: i18n.t("manual"),
    description: i18n.t("manual_description"),
    required: ["title"],
    optional: [
      "author",
      "organization",
      "address",
      "edition",
      "month",
      "year",
      "note",
    ],
  },
  mastersthesis: {
    label: i18n.t("mastersthesis"),
    description: i18n.t("mastersthesis_description"),
    required: ["author", "title", "school", "year"],
    optional: ["type", "address", "month", "note"],
  },
  misc: {
    label: i18n.t("misc"),
    description: i18n.t("misc_description"),
    required: [],
    optional: ["type", "address", "month", "note"],
  },
  phdthesis: {
    label: i18n.t("phdthesis"),
    description: i18n.t("phdthesis_description"),
    required: ["author", "title", "school", "year"],
    optional: ["type", "address", "month", "note"],
  },
  proceedings: {
    label: i18n.t("proceedings"),
    description: i18n.t("proceedings_description"),
    required: ["title", "year"],
    optional: [
      "editor",
      "volume",
      "number",
      "series",
      "address",
      "month",
      "organization",
      "publisher",
      "note",
    ],
  },
  techreport: {
    label: i18n.t("techreport"),
    description: i18n.t("techreport_description"),
    required: ["author", "title", "institution", "year"],
    optional: ["type", "number", "address", "month", "note"],
  },
  unpublished: {
    label: i18n.t("unpublished"),
    description: i18n.t("unpublished_description"),
    required: ["author", "title", "note"],
    optional: ["month", "year"],
  },
};

export const defaultOrganizationSchema: SchemaData[] = [
  {
    label: i18n.t("entry_type"),
    name: "entrytype",
    type: "entrytype",
    default: true,
    description: i18n.t("entry_type_description"),
  },
  {
    label: i18n.t("cite_key"),
    name: "citekey",
    type: "text",
    default: true,
    description: i18n.t("entry_type"),
  },
  {
    label: i18n.t("title"),
    name: "title",
    type: "text",
    default: true,
    description: i18n.t("title_description"),
  },
  {
    label: i18n.t("booktitle"),
    name: "booktitle",
    type: "text",
    default: true,
    description: i18n.t("booktitle_description"),
  },
  {
    label: i18n.t("authors"),
    name: "author",
    type: "list",
    default: true,
    description: i18n.t("authors_description"),
  },
  {
    label: i18n.t("journal"),
    name: "journal",
    type: "text",
    default: true,
    description: i18n.t("journal_description"),
  },
  {
    label: i18n.t("year"),
    name: "year",
    type: "text",
    default: true,
    description: i18n.t("year_description"),
  },
  {
    label: i18n.t("month"),
    name: "month",
    type: "text",
    default: true,
    description: i18n.t("month_description"),
  },
  {
    label: i18n.t("series"),
    name: "series",
    type: "text",
    default: true,
    description: i18n.t("series_description"),
  },
  {
    label: i18n.t("volume"),
    name: "volume",
    type: "text",
    default: true,
    description: i18n.t("volume_description"),
  },
  {
    label: i18n.t("number"),
    name: "number",
    type: "text",
    default: true,
    description: i18n.t("number_description"),
  },
  {
    label: i18n.t("number_description"),
    name: "edition",
    type: "text",
    default: true,
    description: "edition number of a book",
  },
  {
    label: i18n.t("pages"),
    name: "pages",
    type: "text",
    default: true,
    description: i18n.t("pages_description"),
  },
  {
    label: i18n.t("chapter"),
    name: "chapter",
    type: "text",
    default: true,
    description: i18n.t("chapter_description"),
  },
  {
    label: i18n.t("annotation"),
    name: "annote",
    type: "text",
    default: true,
    description: i18n.t("annotation_description_bib"),
  },
  {
    label: i18n.t("editor"),
    name: "editor",
    type: "text",
    default: true,
    description: i18n.t("editor_description"),
  },
  {
    label: i18n.t("institution"),
    name: "institution",
    type: "text",
    default: true,
    description: i18n.t("institution_description"),
  },
  {
    label: i18n.t("doi"),
    name: "doi",
    type: "text",
    default: true,
    description: i18n.t("doi_description"),
  },
  {
    label: i18n.t("isbn"),
    name: "isbn",
    type: "text",
    default: true,
    description: i18n.t("isbn_description"),
  },
  {
    label: i18n.t("issn"),
    name: "issn",
    type: "text",
    default: true,
    description: i18n.t("issn_description"),
  },
  {
    label: i18n.t("url"),
    name: "url",
    type: "text",
    default: true,
    description: i18n.t("url_description"),
  },
  {
    label: i18n.t("note"),
    name: "note",
    type: "text",
    default: true,
    description: i18n.t("note_description"),
  },
  {
    label: i18n.t("publisher"),
    name: "publisher",
    type: "text",
    default: true,
    description: i18n.t("publisher_description"),
  },
  {
    label: i18n.t("address"),
    name: "address",
    type: "text",
    default: true,
    description: i18n.t("address_description"),
  },
  {
    label: i18n.t("organization"),
    name: "organization",
    type: "text",
    default: true,
    description: i18n.t("organization_description"),
  },
  {
    label: i18n.t("school"),
    name: "school",
    type: "text",
    default: true,
    description: i18n.t("school_description"),
  },
  {
    label: i18n.t("type"),
    name: "type",
    type: "text",
    default: true,
    description: i18n.t("type_description"),
  },
  {
    label: i18n.t("publication_note"),
    name: "howpublished",
    type: "text",
    default: true,
    description: i18n.t("publication_note_description"),
  },
];

export const getSchema = (
  name: string
): {
  label: string;
  name: string;
  type: string;
  default: boolean;
  description: string;
} => {
  const filtered = defaultOrganizationSchema.filter(
    (schema) => schema.name === name
  );
  if (filtered.length > 0) {
    return filtered[0];
  }
  return {
    name,
    label: name.charAt(0).toUpperCase() + name.slice(1),
    type: "text",
    default: false,
    description: "",
  };
};

// sort separately "required", "optional", "others"
export const sortOrder = (
  a: string,
  b: string,
  entryType?: string,
  type?: string
): number => {
  // "required", "optional" - sort according to order defined in entrytypes,
  // "others" - in defaultOrganizationSchema order
  const schemaSearch =
    entryType && type
      ? (entryTypes as any)[entryType][type]
      : defaultOrganizationSchema.map((item) => item.name);

  const aIndex = schemaSearch.findIndex(
    (schema: any) => schema.toLowerCase() === a.toLowerCase()
  );
  const bIndex = schemaSearch.findIndex(
    (schema: any) => schema.toLowerCase() === b.toLowerCase()
  );
  // both are in the list;
  if (aIndex >= 0 && bIndex >= 0) {
    return aIndex - bIndex;
  }
  if (aIndex === -1 && bIndex === -1) {
    return a.localeCompare(b);
  }
  if (bIndex === -1) {
    return -1;
  }
  if (aIndex === -1) {
    return 1;
  }
  return 0;
};

// sort full array of OrganizationSchemas
export const sortSchemaOrder = (
  array: OrganizationSchema[],
  entryType: string
): OrganizationSchema[] => {
  const entrytypeItem: OrganizationSchema = defaultOrganizationSchema[0];
  const citekeyItem: OrganizationSchema = defaultOrganizationSchema[1];
  const requiredArray: OrganizationSchema[] = [];
  const optionalArray: OrganizationSchema[] = [];
  let othersArray: OrganizationSchema[] = [];
  if (entryType in entryTypes) {
    const { required, optional } = (entryTypes as Record<string, Entry>)[
      entryType
    ];
    array.forEach((item) => {
      if (required.includes(item.name.toLowerCase())) {
        requiredArray.push(item);
      } else if (optional.includes(item.name.toLowerCase())) {
        optionalArray.push(item);
      } else if (
        item.name !== "entrytype" &&
        item.name !== "citekey" &&
        item.name !== "abstract"
      ) {
        othersArray.push(item);
      }
    });
  } else {
    othersArray = array.filter(
      (item) =>
        item.name !== "entrytype" &&
        item.name !== "citekey" &&
        item.name !== "abstract"
    );
  }
  return [
    entrytypeItem,
    ...requiredArray.sort((a, b) =>
      sortOrder(a.name, b.name, entryType, "required")
    ),
    ...optionalArray.sort((a, b) =>
      sortOrder(a.name, b.name, entryType, "optional")
    ),
    ...othersArray.sort((a, b) => sortOrder(a.name, b.name)),
    citekeyItem,
  ];
};
