import { Dispatch } from "react";
import axios from "axios";
import { Document, DocumentReadResponse } from "models/api/response.types";
import handleAxiosError from "./handleAxiosAlert";
import i18n from "../i18n";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function downloadDocuments({
  selectedDocumentIds,
  dispatch,
  loadFile,
  documentListOfCurrentOrganization,
}: {
  selectedDocumentIds: number[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>;
  loadFile: (fileName: string, loaded: number, failed: boolean) => void;
  documentListOfCurrentOrganization?: DocumentReadResponse[];
}) {
  loadFile("", 0, false);
  axios
    .request({
      url: `/api/document/download?document_ids=${selectedDocumentIds.join(
        "%2C"
      )}`,
      method: "get",
      responseType: "blob",
      onDownloadProgress: (progressEvent) => {
        if (progressEvent.total) {
          loadFile(
            "",
            Math.round((100 * progressEvent.loaded) / progressEvent.total),
            false
          );
        }
      },
    })
    .then((response) => {
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(response.data);
      if (
        selectedDocumentIds.length === 1 &&
        documentListOfCurrentOrganization
      ) {
        const documents = documentListOfCurrentOrganization.filter(
          (document: Document) => document.id === selectedDocumentIds[0]
        );
        if (documents.length > 0) {
          const fileName =
            documents[0].filename ||
            `${i18n.t("document_lowercase")}-${documents[0].id}.pdf`;
          loadFile(fileName, 100, false);
          link.setAttribute("download", fileName);
        } else {
          loadFile(
            `${i18n.t("document_lowercase")}-${selectedDocumentIds[0]}.pdf`,
            100,
            false
          );
          link.setAttribute(
            "download",
            `${i18n.t("document_lowercase")}-${selectedDocumentIds[0]}.pdf`
          );
        }
      } else {
        loadFile(`${i18n.t("documents_low_case")}.zip`, 100, false);
        link.setAttribute("download", `${i18n.t("documents_low_case")}.zip`);
      }
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      handleAxiosError(error, dispatch);
      loadFile(i18n.t("failed_load_documents"), 0, true);
    });
}

export default downloadDocuments;
