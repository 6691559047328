/* eslint-disable react/no-array-index-key */
import React, { useEffect } from "react";
import { Box, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ListPropertyDialog from "../../Dialogs/ListPropertyDialog";

const ListInputControl: React.FC<{
  hoveredField: boolean;
  disabled: boolean;
  propertyName: string;
  currentValue: string[];
  currentDetexifiedValue?: string[];
  onChange: (newValue: string[]) => void;
}> = ({
  hoveredField,
  disabled,
  currentValue,
  currentDetexifiedValue,
  onChange,
  propertyName,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  // prevent from having empty first value
  useEffect(() => {
    if (currentValue.length === 1 && currentValue[0] === "") {
      onChange([]);
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const triggerChange = (newValue?: string[]) => {
    if (
      newValue?.filter(Boolean) &&
      JSON.stringify(newValue) !== JSON.stringify(currentValue)
    ) {
      onChange(newValue.filter(Boolean));
    }
  };

  const listData = currentDetexifiedValue || currentValue;

  return (
    <>
      <Paper
        onClick={() => {
          if (!disabled) {
            setOpen(true);
          }
        }}
        elevation={0}
        style={{
          height: "100%",
          cursor: "pointer",
          padding: "0.3rem",
          background: "transparent",
          display: "flex",
          gap: "0.5rem",
          flexWrap: "wrap",
          minHeight: "32px",
        }}
      >
        {listData.length === 0 && hoveredField && (
          <Typography variant="body2" color="textSecondary">
            {t("enter_new_property", { propertyName })}
          </Typography>
        )}
        {listData.filter(Boolean).map((value, index) => {
          return (
            <Box
              key={`${index}${value}`}
              sx={{
                whiteSpace: "nowrap",
                padding: "0.3em 0.5em",
                fontSize: "14px",
                position: "relative",
                borderRadius: "0.2em",
                background: "#fafafa",
                border: "1px solid #0001",
                display: "flex",
                alignItems: "center",
              }}
            >
              {value}
            </Box>
          );
        })}
      </Paper>
      {open && (
        <ListPropertyDialog
          propertyName={propertyName}
          currentValue={currentValue}
          currentDetexifiedValue={currentDetexifiedValue}
          onClose={handleClose}
          onChange={triggerChange}
        />
      )}
    </>
  );
};

export default ListInputControl;
