/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from "react";
import { AIColumnList } from "models/api/response.types";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setUser } from "store/features/session/slice";
import Joyride, { CallBackProps } from "react-joyride";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { isSidebarOpen, setSideBarOpen } from "store/features/browser/slice";
import useTelemetry, { telemetryAction } from "utils/useTelemetry";
import { authService } from "api";
import { useTranslation } from "react-i18next";
import OnboardingTooltip from "./Tooltip";

const AiTableOnboarding: React.FC<{ columns?: AIColumnList }> = ({
  columns,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const sideBarOpen = useSelector(isSidebarOpen);
  const user = useSelector(selectUser);
  const smScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const { logAction } = useTelemetry();
  const [isAddContainerRendered, setIsAddContainerRendered] = useState(false);
  const runFirstStep =
    isAddContainerRendered &&
    !user?.meta?.citeOnboarding?.aiTable?.pageOnboardingCompleted;
  const runQuestionStep =
    !!user?.meta?.citeOnboarding?.aiTable?.pageOnboardingCompleted &&
    columns &&
    columns?.length > 0;

  // Check if '.add-container' is rendered in the DOM
  useEffect(() => {
    const checkIfAddContainerRendered = () => {
      const addContainer = document.querySelector(".add-container");
      setIsAddContainerRendered(!!addContainer);
    };
    checkIfAddContainerRendered();
  });

  useEffect(() => {
    const element = document.getElementById("main-content");
    if (element && runFirstStep) {
      setTimeout(() => {
        element.scrollTop = 0;
        element.scrollTo({
          left: element.scrollWidth,
          behavior: "smooth",
        });
      });
    }
  }, [runFirstStep]);

  const completeCurrentOnboarding = (action: string) => {
    if (user) {
      authService
        .updateUser(user.id, {
          meta: {
            ...user.meta,
            citeOnboarding: {
              ...user.meta.citeOnboarding,
              aiTable: {
                ...user.meta.citeOnboarding.aiTable,
                [action]: new Date().toISOString(),
              },
            },
          },
        })
        .then(({ data }) => {
          dispatch(setUser({ ...user, ...data }));
        })
        .catch((err) => err);
    }
  };

  useEffect(() => {
    if (
      user?.meta?.citeOnboarding?.aiTable?.pageOnboardingCompleted &&
      user?.meta?.citeOnboarding?.aiTable?.questionStepColmpleted
    ) {
      completeCurrentOnboarding("completed");
    }
  }, [user]);

  const firstStepsOnboarding = [
    {
      target: ".add-container",
      placement: "left" as const,
      disableBeacon: true,
      disableOverlayClose: true,
      disableCloseOnEsc: true,
      content: (
        <Box
          className="onboarding-step"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Typography variant="h6">{t("add_question")}</Typography>
          <Typography variant="body1" color="textSecondary">
            {t("add_question_instruction")}
          </Typography>
        </Box>
      ),
    },
  ];

  const questionStepOnboarding = [
    {
      target: ".actions-container",
      placement: "bottom" as const,
      disableBeacon: true,
      disableOverlayClose: true,
      disableCloseOnEsc: true,
      content: (
        <Box
          className="onboarding-step"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Typography variant="h6">{t("generate_answer")}</Typography>
          <Typography variant="body1" color="textSecondary">
            {t("generate_answer_instruction")}
          </Typography>
        </Box>
      ),
    },
    {
      target: ".manage-sources",
      placement: "bottom-start" as const,
      disableBeacon: true,
      disableOverlayClose: true,
      disableCloseOnEsc: true,
      content: (
        <Box
          className="onboarding-step"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Typography variant="h6">{t("manage_sources")}</Typography>
          <Typography variant="body1" color="textSecondary">
            {t("manage_sources_instruction")}
          </Typography>
        </Box>
      ),
    },
  ];

  const firstStepCallback = (data: CallBackProps) => {
    const { action, lifecycle, index } = data;
    if (action === "start" && lifecycle === "init" && index === 0) {
      if (sideBarOpen && smScreen) {
        dispatch(setSideBarOpen(false));
      }
    }
    if (action === "close" && lifecycle === "complete") {
      logAction(telemetryAction.onboarding_flow, {
        method: "ai_table_page_onboarding_completed",
      });
      completeCurrentOnboarding("pageOnboardingCompleted");
    }
  };

  const questionStepCallback = (data: CallBackProps) => {
    const { action, lifecycle, index } = data;
    if (action === "start" && lifecycle === "init" && index === 0) {
      const divElement = document.getElementById("actions-container-1");
      divElement?.classList.add("onboarding");

      if (sideBarOpen && smScreen) {
        dispatch(setSideBarOpen(false));
      }
    }
    if (action === "close" && lifecycle === "complete") {
      const divElement = document.getElementById("actions-container-1");
      divElement?.classList.remove("onboarding");
      logAction(telemetryAction.onboarding_flow, {
        method: "ai_table_page_onboarding_completed",
      });
      completeCurrentOnboarding("questionStepColmpleted");
    }
  };

  if (runQuestionStep) {
    return (
      <Joyride
        run
        steps={questionStepOnboarding}
        tooltipComponent={OnboardingTooltip}
        callback={questionStepCallback}
        floaterProps={{
          styles: {
            wrapper: {
              zIndex: 1501,
            },
          },
        }}
        styles={{
          options: {
            zIndex: 1500,
          },
        }}
      />
    );
  }

  if (runFirstStep) {
    return (
      <Joyride
        run
        steps={firstStepsOnboarding}
        tooltipComponent={OnboardingTooltip}
        callback={firstStepCallback}
        floaterProps={{
          styles: {
            wrapper: {
              zIndex: 1501,
            },
          },
        }}
        styles={{
          options: {
            zIndex: 1500,
          },
        }}
      />
    );
  }

  return null;
};

export default AiTableOnboarding;
