/* eslint-disable no-lone-blocks */
/* eslint-disable react/no-array-index-key */
import React, { useMemo, useState } from "react";
import { Box, Button, Skeleton, Typography, styled } from "@mui/material";
import { useSelector } from "react-redux";
import { selectUser } from "store/features/session/slice";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import {
  getIndividualsPlans,
  individualFreePlan,
  teamsPlans,
} from "utils/plans";
import validateEduEmailAddress from "utils/validateEduEmailAddress";
import clsx from "clsx";
import moment from "moment";
import CancelSubscriptionDialog from "components/Dialogs/CancelSubscriptionDialog";
import { useOrg } from "pages/WorkspaceSettings";
import { useTranslation } from "react-i18next";

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "1rem",
  border: `1px solid ${theme.grey.light}`,
  borderRadius: "4px",
  padding: "0.5rem 1rem",
  "& .color-border": {
    height: "50px",
    width: "5px",
    borderRadius: "50px",
    backgroundColor: theme.palette.primary.main,
    "&.next": {
      backgroundColor: theme.palette.success.main,
    },
  },
  "& .actions": {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    "& .period": {
      padding: "0.5rem",
      border: "1px solid",
      borderRadius: "12px",
      "&.expiring": {
        borderColor: theme.palette.warning.dark,
        backgroundColor: `${theme.palette.warning.main}10`,
      },
      "&.next": {
        borderColor: "#1c54b2",
        backgroundColor: `#1c54b210`,
      },
    },
  },
}));

const Plan: React.FC<{
  lookupKey?: string;
  canCancel?: boolean;
  subscriptionId?: string;
  isCanceled?: boolean;
  cancelTime?: string;
  showNext?: boolean;
  nextTime?: string;
  isYearly?: boolean;
  loading?: boolean;
  isOld?: boolean;
}> = ({
  lookupKey,
  canCancel,
  subscriptionId,
  isCanceled,
  cancelTime,
  showNext,
  nextTime,
  isYearly,
  loading,
  isOld,
}) => {
  const { t } = useTranslation();
  const { org: currentOrg } = useOrg();
  const user = useSelector(selectUser);
  const [cancelPlanDialog, setCancelPlanDialog] = useState<boolean>(false);
  const planToShow = useMemo(() => {
    const isStudent = validateEduEmailAddress(user?.email || "");
    const individualsPlans = getIndividualsPlans(isStudent);
    const result = [
      individualFreePlan,
      ...individualsPlans,
      ...teamsPlans,
    ].find((plan) => {
      return (
        plan.lookupKey === lookupKey ||
        plan.wechatKey === lookupKey ||
        (isYearly && plan?.yearlyKey === lookupKey) ||
        (isYearly && plan?.wechatYearlyKey === lookupKey)
      );
    });
    if (result) {
      return result;
    }
    return individualFreePlan;
  }, [lookupKey]);

  const price = isOld
    ? isYearly
      ? `$${planToShow.oldYearlyPrice}`
      : `$${planToShow.oldPrice}`
    : isYearly
    ? `${planToShow.yearlyPrice}`
    : `${planToShow.price}`;

  const formattedCancelTime = cancelTime
    ? moment(cancelTime).format("MMMM D, YYYY, hh:mm:ss A")
    : "";
  const formattedNextTime = nextTime
    ? moment(nextTime).format("MMMM D, YYYY, hh:mm:ss A")
    : "";

  return (
    <>
      <Wrapper>
        <Box className={clsx("color-border", showNext ? "next" : "")} />
        <Box>
          {!loading ? (
            <>
              <Typography variant="h6">{planToShow.name}</Typography>
              <Typography variant="body1" color="textSecondary">
                {price} {t("price_per_month")}
              </Typography>
            </>
          ) : (
            <>
              <Skeleton variant="rectangular" width={150} height={22} />
              <Skeleton
                sx={{ mt: "0.9rem" }}
                variant="rectangular"
                width={130}
                height={20}
              />
            </>
          )}
        </Box>
        <Box className="actions">
          {isCanceled && cancelTime && (
            <Typography fontSize="small" className="period expiring">
              {t("expiring", { expirationDate: formattedCancelTime })}
            </Typography>
          )}
          {showNext && nextTime && (
            <Typography fontSize="small" className="period next">
              {t("starting", { startDate: formattedNextTime })}
            </Typography>
          )}
          {(canCancel || loading) && (
            <Button
              disabled={loading}
              size="small"
              variant="outlined"
              color="primary"
              onClick={() => {
                setCancelPlanDialog(true);
              }}
              startIcon={<ClearOutlinedIcon fontSize="small" />}
            >
              {t("cancel")}
            </Button>
          )}
        </Box>
      </Wrapper>
      <CancelSubscriptionDialog
        open={cancelPlanDialog}
        organization={currentOrg}
        subscriptionId={subscriptionId || ""}
        close={() => {
          setCancelPlanDialog(false);
        }}
      />
    </>
  );
};

export default Plan;
