import React, { useState, useRef } from "react";
import { useTags } from "api/tagService";
import EditIcon from "@mui/icons-material/EditOutlined";
import { DocumentReadResponse } from "models/api/response.types";
import TagsEditDialog from "components/Dialogs/Tags/TagsEditDialog";
import { TagProps } from "components/Tags/tagsCellRenderer";
import { Box, IconButton, Typography } from "@mui/material";
import { isGuest } from "models/components/Permissions.models";
import EditDocumentTagsDialog from "components/Dialogs/Tags/EditDocumentTagsDialog";
import { useTranslation } from "react-i18next";
import InlineTagById from "./InlineTagById";

interface TagsSectionProps {
  document: DocumentReadResponse;
  sideBarTagIds: number[];
  userRole?: string;
}

const TagsSection: React.FC<TagsSectionProps> = ({
  document,
  sideBarTagIds,
  userRole,
}) => {
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);
  const [currentTag, setCurrentTag] = useState<TagProps>({
    id: 0,
    color: "",
    name: "",
  });
  const { getCachedTagById } = useTags(document.organization_id);
  const cardEl = useRef<HTMLDivElement>(null);
  const [addTagDialog, setOpenAddTagDialog] = useState(false);
  const setTag = (id: number) => {
    const [tag] = getCachedTagById(id);
    if (tag) setCurrentTag(tag);
  };

  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        gap: "0.5rem 0.3rem",
        marginTop: "0.25rem",
        minHeight: "30px",
      }}
      ref={cardEl}
    >
      {sideBarTagIds.length > 0 &&
        sideBarTagIds.map((id) => (
          <InlineTagById
            organizationId={document.organization_id}
            id={id}
            key={id}
            disabled={isGuest(userRole)}
            onClick={() => {
              if (!isGuest(userRole)) {
                setOpenDialog(true);
                setTag(id);
              }
            }}
          />
        ))}
      {sideBarTagIds.length === 0 && (
        <Typography fontStyle="italic" variant="body2">
          {t("no_tags")}
        </Typography>
      )}
      {openDialog && (
        <TagsEditDialog setOpenDialog={setOpenDialog} currentTag={currentTag} />
      )}
      {addTagDialog && (
        <EditDocumentTagsDialog
          document={document}
          setOpen={setOpenAddTagDialog}
        />
      )}
      {!addTagDialog && !isGuest(userRole) && (
        <IconButton size="small" onClick={() => setOpenAddTagDialog(true)}>
          <EditIcon fontSize="small" className="plus-icon" color="action" />
        </IconButton>
      )}
    </Box>
  );
};

export default TagsSection;
