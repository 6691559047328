import i18n from "../i18n";

const formatBytes = (bytes: number, decimals = 2) => {
  if (!bytes || bytes < 0) return "0 Bytes";

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = [
    i18n.t("bytes"),
    i18n.t("kb"),
    i18n.t("mb"),
    i18n.t("gb"),
    i18n.t("tb"),
    i18n.t("pb"),
    i18n.t("eb"),
    i18n.t("zb"),
    i18n.t("yb"),
  ];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export default formatBytes;
