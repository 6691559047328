import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  TextField,
  styled,
  Box,
} from "@mui/material";
import { TwitterPicker } from "react-color";
import { useSelector } from "react-redux";
import { Close } from "@mui/icons-material";
import { selectCurrentOrganizationId } from "store/features/session/slice";
import { useTranslation } from "react-i18next";
import { colorsToCreate } from "../../../utils/customColorPalette";
import { useTags } from "../../../api/tagService";

const StyledDialog = styled(Dialog)(() => ({
  "& .tag-name": {
    display: "flex",
    alignItems: "center",
    height: "42px",
    "& > div": {
      marginRight: "5px",
    },
    padding: "0 15px",
  },
  "& .dialog-content": {
    padding: "0 1rem 1rem 1rem",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  "& .color-picker": {
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
    gap: "0.5rem",
    width: "100%",
  },
}));

const TagsEditDialog: React.FC<{
  setOpenDialog: (value: boolean) => void;
  currentTag?: { id: number; color: string; name: string };
}> = ({ setOpenDialog, currentTag }) => {
  const { t } = useTranslation();
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const { tags, updateTagMutation, createTagMutation } = useTags(
    currentOrganizationId
  );
  const defaultTagName = currentTag?.name || "";
  const defaultTagColor = currentTag?.color || "#EE6A59";
  const [newName, setNewName] = useState(defaultTagName);
  const [errorMessage, setErrorMessage] = useState("");
  const [color, setColor] = useState(defaultTagColor);

  useEffect(() => {
    if (currentTag) {
      setNewName(currentTag.name);
      setColor(currentTag.color);
    }
  }, [currentTag]);

  const handleChangeColor = (currentColor: { hex: string }) => {
    setColor(currentColor.hex);
  };

  const clearUp = () => {
    setOpenDialog(false);
    setNewName(defaultTagName);
    setColor(defaultTagColor);
  };

  const handleUpdateTag = () => {
    if (
      tags?.some(
        (tag) =>
          (currentTag && tag.name === newName && tag.id !== currentTag.id) ||
          (!currentTag && tag.name === newName)
      )
    ) {
      setErrorMessage(t("tag_name_exists"));
    } else if (currentTag) {
      clearUp();
      const newTag = {
        ...currentTag,
        name: newName,
        color,
      };
      updateTagMutation.mutate(newTag);
    } else if (currentOrganizationId) {
      clearUp();
      createTagMutation.mutate({
        organization_id: currentOrganizationId,
        name: newName,
        color,
      });
    }
  };

  return (
    <StyledDialog open onClose={() => clearUp()} fullWidth maxWidth="sm">
      <DialogTitle>
        {currentTag ? t("edit_tag") : t("create_tag")}
        <IconButton onClick={clearUp}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className="dialog-content">
        <TextField
          color="primary"
          size="small"
          value={newName}
          onChange={(event) => {
            setErrorMessage("");
            setNewName(event.target.value);
          }}
          className="textfield"
          fullWidth
          autoFocus
          error={!!errorMessage}
          helperText={errorMessage}
        />
        <div className="color-picker">
          <TwitterPicker
            color={color}
            onChange={handleChangeColor}
            triangle="hide"
            colors={colorsToCreate}
            styles={{
              default: {
                card: {
                  boxShadow: "none",
                },
                body: {
                  padding: 0,
                },
                input: {
                  width: 60,
                  height: 22,
                },
                hash: {
                  height: 24,
                  width: 24,
                },
                swatch: {
                  height: 24,
                  width: 24,
                  borderRadius: "50px",
                },
              },
            }}
          />
          <Box
            sx={{
              width: "24px",
              height: "24px",
              background: color,
              borderRadius: "50px",
            }}
          />
        </div>
      </DialogContent>
      <DialogActions className="buttons" sx={{ paddingTop: 0 }}>
        <Button
          variant="text"
          color="primary"
          size="medium"
          onClick={() => clearUp()}
        >
          {t("cancel")}
        </Button>
        <Button
          onClick={() => handleUpdateTag()}
          variant="contained"
          color="primary"
          size="medium"
          disabled={newName.length === 0}
        >
          {t("save")}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default TagsEditDialog;
