/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext, useState } from "react";
import RemoveDoneOutlinedIcon from "@mui/icons-material/RemoveDoneOutlined";
import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";
import CopyAllOutlinedIcon from "@mui/icons-material/CopyAllOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import StyleOutlinedIcon from "@mui/icons-material/StyleOutlined";
import LibraryAddCheckOutlinedIcon from "@mui/icons-material/LibraryAddCheckOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import { searchChildCollectionTree } from "utils/collections";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import BookmarkRemoveOutlinedIcon from "@mui/icons-material/BookmarkRemoveOutlined";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import {
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  styled,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedCollectionId } from "store/features/browser/slice";
import downloadDocuments from "utils/downloadDocuments";
import { useDocuments } from "api/documentService";
import { useDocumentCollections } from "api/documentCollectionService";
import { isGuest } from "models/components/Permissions.models";
import { DocumentsContext } from "pages/Documents";
import {
  selectCurrentOrganizationId,
  selectUser,
} from "store/features/session/slice";
import { useUsers } from "api/userService";
import { addAlert } from "store/features/general/slice";
import { getDocumentStatus, isStub, isTable } from "utils/documentStatus";
import AISessionDialog from "components/Dialogs/AISessionDialog";
import { useNavigate } from "react-router-dom";
import routePaths from "routes/routePaths";
import AITableDialog from "components/Dialogs/AITableDialog";
import { canUseMultidocChat, isFreePlan, isTrialExist } from "utils/plans";
import {
  useOrganizationUsage,
  useOrganizations,
} from "api/organizationService";
import ConfirmationDialog from "components/Dialogs/ConfirmationDialog";
import { useTranslation } from "react-i18next";

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
  alignItems: "center",
  flex: 1,
  marginLeft: "0.5rem",
  "& .MuiButton-root": {
    whiteSpace: "nowrap",
  },
  "& .MuiButton-startIcon": {
    marginRight: 0,
  },
  "& .text-underline": {
    background: "transparent",
    textDecoration: "underline",
    "&:hover": {
      background: "transparent",
      textDecoration: "underline",
    },
  },
  "& .ai-container": {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
    "& .ai-icon": {
      width: "28px",
      height: "28px",
    },
  },
}));

const DocumentActions: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const {
    selectedDocuments,
    trashMode,
    setOpenAddTagsDialog,
    setOpenCopyDocumentsDialog,
    setOpenBibMergeDialog,
    setOpenBibTeXExportDialog,
    loadFile,
    documentToShow,
    setDocumentToShow,
    triggerSpecialInputFile,
    setOpenAddToCollectionDialog,
    setSelectedDocuments,
    setOpenEditTagDialog,
    setOpenManageTagsDialog,
    setOpenRemoveTagsDialog,
  } = useContext(DocumentsContext);
  const selectedCollectionId = useSelector(getSelectedCollectionId);
  const { users } = useUsers(currentOrganizationId);
  const { organizationUsage } = useOrganizationUsage(currentOrganizationId);
  const { getCachedOrganizationById } = useOrganizations(user?.id);
  const [currentOrganization] = getCachedOrganizationById(
    currentOrganizationId || -1
  );
  const {
    documents,
    moveDocumentsToTrashMutation,
    restoreDocumentsFromTrashMutation,
    deleteMultipleDocumentsMutation,
  } = useDocuments(currentOrganizationId);
  const { collections, getCachedCollectionById, updateCollectionMutation } =
    useDocumentCollections(currentOrganizationId);
  const currentUserRole = users?.find((u) => u.id === user?.id)?.role;
  const [moveAnchorEl, setMoveAnchorEl] = useState<Element | null>(null);
  const [tagModifyAnchorEl, setTagModifyAnchorEl] = useState<Element | null>(
    null
  );
  const [exportAnchorEl, setExportAnchorEl] = useState<Element | null>(null);
  const [confirmDocumentsToTrash, setConfirmDocumentsToTrash] =
    useState<boolean>(false);
  const [aiAnchorEl, setAiAnchorEl] = useState<Element | null>(null);
  const [documentsForMultiDocChat, setDocumentsForMultiDocChat] = useState<
    number[] | undefined
  >(undefined);
  const [documentsForAITable, setDocumentsForAITable] = useState<
    number[] | undefined
  >(undefined);

  const moveDocumentListOptions = [
    {
      index: 0,
      icon: <CopyAllOutlinedIcon fontSize="small" />,
      label: t("copy_to"),
      onClick: () => {
        setOpenCopyDocumentsDialog({
          open: true,
          type: "selected_documents",
        });
      },
    },
    {
      index: 1,
      icon: <LibraryAddCheckOutlinedIcon fontSize="small" />,
      label: t("add_to_collection"),
      onClick: () => {
        setOpenAddToCollectionDialog({
          open: true,
          type: "selected_documents",
        });
      },
    },
    {
      index: 2,
      icon: <RemoveDoneOutlinedIcon fontSize="small" />,
      label: t("remove_from_collection"),
      onClick: () => {
        if (selectedCollectionId) {
          const collectionTree = searchChildCollectionTree(
            selectedCollectionId,
            collections
          );
          const selectedIds = selectedDocuments.map((doc) => doc.id);
          collectionTree.forEach((collectionId: number) => {
            const [collection] = getCachedCollectionById(collectionId);
            if (collection) {
              updateCollectionMutation.mutate({
                collections: [
                  {
                    id: collection.id,
                    document_ids: collection?.document_ids.filter(
                      (item) => !selectedIds.includes(item)
                    ),
                  },
                ],
              });
            }
          });
        }
        setSelectedDocuments([]);
      },
    },
  ];

  const moveDocumentListItems = moveDocumentListOptions.filter((option) => {
    if (selectedDocuments.length === 0) {
      return false;
    }

    if (isGuest(currentUserRole) || trashMode) {
      return false;
    }

    if (option.index === 2 && !selectedCollectionId) {
      return false;
    }

    return true;
  });

  // show only when user is not guest
  const tagModifyDocumentListOptions = [
    {
      index: 0,
      icon: <StyleOutlinedIcon fontSize="small" />,
      label: t("add_tags"),
      onClick: () => {
        setOpenAddTagsDialog(true);
      },
    },
    {
      index: 1,
      icon: <BookmarkRemoveOutlinedIcon fontSize="small" />,
      label: t("remove_tags"),
      onClick: () => {
        setOpenRemoveTagsDialog(true);
      },
    },
    {
      index: 2,
      icon: <LocalOfferOutlinedIcon fontSize="small" />,
      label: t("edit_tags"),
      onClick: () => {
        setOpenEditTagDialog(true);
      },
    },
    {
      index: 3,
      icon: <SyncOutlinedIcon fontSize="small" />,
      label: t("update_with_doi_pmid_arxivid"),
      onClick: () => {
        setOpenBibMergeDialog({
          open: true,
          type: "selected_documents",
        });
      },
    },
    {
      index: 4,
      icon: <EditOutlinedIcon fontSize="small" />,
      label: t("manage_all_tags"),
      onClick: () => {
        setOpenManageTagsDialog(true);
      },
    },
  ];

  const tagModifyDocumentListItems = tagModifyDocumentListOptions.filter(
    (option) => {
      if (isGuest(currentUserRole)) {
        return false;
      }
      const singleDocumentOptions = [2, 3];
      const noDocument = [4];

      if (
        !selectedDocuments.some((doc) => doc.tag_ids.length > 0) &&
        option.index === 1
      ) {
        return false;
      }

      if (
        selectedDocuments.length === 0 &&
        !noDocument.includes(option.index)
      ) {
        return false;
      }

      // update properties not available for table
      if (
        option.index === 3 &&
        selectedDocuments.length === 1 &&
        isTable(selectedDocuments[0])
      ) {
        return false;
      }

      // can do only for single doc
      if (
        selectedDocuments.length > 1 &&
        singleDocumentOptions.includes(option.index)
      ) {
        return false;
      }

      return true;
    }
  );

  const exportDocumentListOptions = [
    {
      index: 0,
      icon: <ExitToAppOutlinedIcon fontSize="small" />,
      label: t("export_bibtex"),
      onClick: () => {
        setOpenBibTeXExportDialog({
          open: true,
          type: "selected_documents",
        });
      },
    },
    {
      index: 1,
      icon: <DownloadOutlinedIcon fontSize="small" />,
      label: t("download"),
      onClick: () => {
        const documentIdsToUpload = selectedDocuments
          .filter((doc) => !isStub(doc))
          .map((doc) => doc.id);
        const filteredAmount =
          selectedDocuments.length - documentIdsToUpload.length;
        if (filteredAmount > 0) {
          if (documentIdsToUpload.length === 0) {
            dispatch(
              addAlert({
                severity: "info",
                autoHideDuration: 5000,
                alert: {
                  message: t("selected_documents_not_available_for_download"),
                },
              })
            );
          } else {
            dispatch(
              addAlert({
                severity: "info",
                autoHideDuration: 5000,
                alert: {
                  message: t("some_documents_not_available_for_download"),
                },
              })
            );
          }
        }
        if (documentIdsToUpload.length > 0) {
          downloadDocuments({
            selectedDocumentIds: documentIdsToUpload,
            documentListOfCurrentOrganization: documents || [],
            dispatch,
            loadFile,
          });
        }
      },
    },
  ];

  const exportDocumentListItems = exportDocumentListOptions.filter((option) => {
    if (selectedDocuments.length === 0) {
      return false;
    }

    if (option.index === 1 && isGuest(currentUserRole)) {
      return false;
    }

    return true;
  });

  const aiSelection = (
    type: "ai-chat" | "ai-table",
    setDocuments: (documentIds: number[]) => void
  ) => {
    const documentsToUse = selectedDocuments.filter((doc) => {
      const { ready } = getDocumentStatus(doc);
      if (ready && doc.doctype !== "stub" && doc.status.embeddings === 2) {
        return true;
      }
      return false;
    });

    if (documentsToUse.length > 0) {
      setDocuments(documentsToUse.map((doc) => doc.id));
      if (documentsToUse.length !== selectedDocuments.length) {
        dispatch(
          addAlert({
            severity: "info",
            autoHideDuration: 5000,
            alert: {
              message: t("some_documents_not_available_case", {
                case: type === "ai-chat" ? t("multi_doc_chat") : t("ai_table"),
              }),
            },
          })
        );
      }
    } else {
      dispatch(
        addAlert({
          severity: "info",
          autoHideDuration: 5000,
          alert: {
            message: t("documents_not_available_case", {
              case: type === "ai-chat" ? t("multi_doc_chat") : t("ai_table"),
            }),
          },
        })
      );
    }
  };

  const aiListOptions = [
    {
      index: 0,
      icon: <TableChartOutlinedIcon fontSize="small" />,
      label: t("create_ai_table"),
      onClick: () => {
        aiSelection("ai-table", setDocumentsForAITable);
      },
    },
    {
      index: 1,
      icon: <ChatOutlinedIcon fontSize="small" />,
      label: t("create_multi_doc_chat"),
      onClick: () => {
        aiSelection("ai-chat", setDocumentsForMultiDocChat);
      },
    },
  ];

  const aiListItems = aiListOptions.filter((option) => {
    if (selectedDocuments.length === 0 || trashMode) {
      return false;
    }
    const readOnlyTable =
      (isFreePlan(currentOrganization?.meta?.plans || []) &&
        !isTrialExist(currentOrganization?.created_at)) ||
      isGuest(currentUserRole);
    if (option.index === 0 && readOnlyTable) {
      return false;
    }
    const readOnlyAiChat =
      !canUseMultidocChat(organizationUsage?.usage_limits?.ai_credit_limit) &&
      !isTrialExist(currentOrganization?.created_at);
    if (option.index === 1 && readOnlyAiChat) {
      return false;
    }
    return true;
  });

  const renderMenu = (
    menuAnchorEl: Element | null,
    setMenuAnchorEl: (prop: Element | null) => void,
    menuOptions: any
  ) => {
    return (
      <Menu
        anchorEl={menuAnchorEl}
        open={!!menuAnchorEl}
        onClose={() => {
          setMenuAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {menuOptions.map((option: any) => (
          <MenuItem
            key={option.index}
            onClick={() => {
              option.onClick();
              setMenuAnchorEl(null);
            }}
          >
            <ListItemIcon>{option.icon}</ListItemIcon>
            <ListItemText>
              <Typography variant="body2">{option.label}</Typography>
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
    );
  };

  return (
    <>
      <Wrapper className="document-actions">
        {moveDocumentListItems.length > 0 && (
          <>
            <Button
              size="small"
              color="primary"
              variant="outlined"
              endIcon={<KeyboardArrowDownIcon fontSize="small" />}
              onClick={(e) => {
                setMoveAnchorEl(e.currentTarget);
              }}
            >
              Move
            </Button>
            {renderMenu(moveAnchorEl, setMoveAnchorEl, moveDocumentListItems)}
          </>
        )}
        {tagModifyDocumentListItems.length > 0 && (
          <>
            <Button
              size="small"
              color="primary"
              variant="outlined"
              endIcon={<KeyboardArrowDownIcon fontSize="small" />}
              onClick={(e) => {
                setTagModifyAnchorEl(e.currentTarget);
              }}
            >
              {selectedDocuments.length > 0 ? t("tag_modify") : t("tag")}
            </Button>
            {renderMenu(
              tagModifyAnchorEl,
              setTagModifyAnchorEl,
              tagModifyDocumentListItems
            )}
          </>
        )}
        {exportDocumentListItems.length > 0 && (
          <>
            <Button
              size="small"
              color="primary"
              variant="outlined"
              endIcon={<KeyboardArrowDownIcon fontSize="small" />}
              onClick={(e) => {
                setExportAnchorEl(e.currentTarget);
              }}
            >
              {t("export")}
            </Button>
            {renderMenu(
              exportAnchorEl,
              setExportAnchorEl,
              exportDocumentListItems
            )}
          </>
        )}
        {aiListItems.length > 0 && (
          <>
            <Button
              size="small"
              color="primary"
              variant="outlined"
              endIcon={<KeyboardArrowDownIcon fontSize="small" />}
              onClick={(e) => {
                setAiAnchorEl(e.currentTarget);
              }}
            >
              {t("ai")}
            </Button>
            {renderMenu(aiAnchorEl, setAiAnchorEl, aiListItems)}
          </>
        )}
        {selectedDocuments.length === 1 &&
          isStub(selectedDocuments[0]) &&
          !isGuest(currentUserRole) && (
            <Button
              className="text-underline"
              color="primary"
              onClick={() => {
                triggerSpecialInputFile(selectedDocuments[0].id);
              }}
            >
              {t("upload_document")}
            </Button>
          )}
        {selectedDocuments.length > 0 && (
          <>
            {!trashMode && !isGuest(currentUserRole) && (
              <>
                <Button
                  className="text-underline"
                  color="primary"
                  onClick={() => {
                    setConfirmDocumentsToTrash(true);
                  }}
                >
                  {t("move_to_trash")}
                </Button>
              </>
            )}
          </>
        )}
        {trashMode &&
          !isGuest(currentUserRole) &&
          selectedDocuments.length > 0 && (
            <>
              <Button
                className="text-underline"
                color="primary"
                onClick={() => {
                  restoreDocumentsFromTrashMutation.mutate(
                    selectedDocuments.map((doc) => doc.id)
                  );
                  setSelectedDocuments([]);
                  if (
                    selectedDocuments.some(
                      (doc) => doc.id === documentToShow?.id
                    )
                  ) {
                    setDocumentToShow(undefined);
                  }
                }}
              >
                {t("restore")}
              </Button>
              <Button
                className="text-underline"
                color="primary"
                onClick={() => {
                  deleteMultipleDocumentsMutation.mutate(
                    selectedDocuments.map((doc) => doc.id)
                  );
                  setSelectedDocuments([]);
                  if (
                    selectedDocuments.some(
                      (doc) => doc.id === documentToShow?.id
                    )
                  ) {
                    setDocumentToShow(undefined);
                  }
                }}
              >
                {t("delete")}
              </Button>
            </>
          )}
      </Wrapper>
      <ConfirmationDialog
        show={confirmDocumentsToTrash}
        title={t("move_selected_documents_to_trash", {
          selectedDocuments: selectedDocuments.length,
        })}
        description={t("documents_trash_warning")}
        buttonText={t("move_to_trash")}
        sensitive
        onClose={(confirm?: boolean) => {
          setConfirmDocumentsToTrash(false);
          if (confirm && selectedDocuments.length > 0) {
            setTimeout(() => {
              moveDocumentsToTrashMutation.mutate(
                selectedDocuments.map((doc) => doc.id)
              );
              setSelectedDocuments([]);
              if (
                selectedDocuments.some((doc) => doc.id === documentToShow?.id)
              ) {
                setDocumentToShow(undefined);
              }
            }, 300);
          }
        }}
      />
      {documentsForMultiDocChat && (
        <AISessionDialog
          isMultiDoc
          organizationId={currentOrganizationId as number}
          documentIds={documentsForMultiDocChat}
          setOpen={(open: boolean, newSession) => {
            if (newSession) {
              navigate(routePaths.aiChat);
            }
            setDocumentsForMultiDocChat(undefined);
          }}
        />
      )}
      {documentsForAITable && (
        <AITableDialog
          documentIds={documentsForAITable}
          setOpen={(newTable) => {
            if (newTable) {
              navigate(`/browse/ai-table/${newTable.id}`);
            }
            setDocumentsForAITable(undefined);
          }}
        />
      )}
    </>
  );
};

export default DocumentActions;
