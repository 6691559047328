import { companyFeatures } from "company-config";
import i18n from "../i18n";

/* eslint-disable import/prefer-default-export */
export const individualFreePlan = {
  name: i18n.t("free"),
  price: 0,
  yearlyPrice: 0,
  oldPrice: 0,
  oldYearlyPrice: 0,
  lookupKey: "petal_free_plan",
  description: i18n.t("free_description"),
  yearlyKey: "",
  wechatKey: "",
  wechatYearlyKey: "",
  features: [
    [
      `1${i18n.t("gb")} ${i18n.t("cloud_storage")}`,
      `1 ${i18n.t("seats")}`,
      `3 ${i18n.t("guests")}`,
    ],
    [i18n.t("credits")],
    [i18n.t("unlimited_citation_lists"), `Trial--${i18n.t("trial_export")}`],
    [
      `2 ${i18n.t("collections")}`,
      `3 ${i18n.t("annotations_per_document")}`,
      i18n.t("single_doc_chat"),
      `Trial--${i18n.t("multi_doc_chat")}`,
      `Trial--${i18n.t("ai_table")}`,
      `Trial--${i18n.t("ai_create")}`,
      i18n.t("cross_workspace_sharing"),
    ],
  ],
  image: "/img/plans/free.svg",
};

const individualsPlans = [
  {
    name: i18n.t("plus"),
    price: 4.99,
    yearlyPrice: 4.25,
    oldPrice: 4.99,
    oldYearlyPrice: 4.25,
    description: i18n.t("edu_description", {
      startPrice: "$2.99",
      endPrice: "$4.99",
    }),
    features: [
      [
        `2${i18n.t("gb")} ${i18n.t("cloud_storage")}`,
        `1 ${i18n.t("seats")}`,
        `3 ${i18n.t("guests")}`,
      ],
      [i18n.t("credits_per_month")],
      [i18n.t("unlimited_citation_lists"), i18n.t("export")],
      [
        i18n.t("unlimited_collections"),
        i18n.t("unlimited_annotations"),
        i18n.t("single_doc_chat"),
        i18n.t("ai_table"),
        i18n.t("cross_workspace_sharing"),
      ],
    ],
    lookupKey: "studentindividualplan",
    yearlyKey: "yearlystudentindividualplan",
    wechatKey: "wechatplusplan",
    wechatYearlyKey: "yearlywechatplusplan",
    image: "/img/plans/Silver Tier.svg",
  },
  {
    name: i18n.t("advanced"),
    price: 13.99,
    yearlyPrice: 11.89,
    oldPrice: 9.99,
    oldYearlyPrice: 8.49,
    description: i18n.t("edu_description", {
      startPrice: "$9.99",
      endPrice: "$13.99",
    }),
    features: [
      [
        `10${i18n.t("gb")} ${i18n.t("cloud_storage")}`,
        `1 ${i18n.t("seats")}`,
        `3 ${i18n.t("guests")}`,
      ],
      [i18n.t("credits_per_month")],
      [i18n.t("unlimited_citation_lists"), i18n.t("export")],
      [
        i18n.t("unlimited_collections"),
        i18n.t("unlimited_annotations"),
        i18n.t("single_doc_chat"),
        i18n.t("multi_doc_chat"),
        i18n.t("ai_table"),
        i18n.t("ai_create"),
        i18n.t("cross_workspace_sharing"),
      ],
    ],
    lookupKey: "researcherindividualplan",
    yearlyKey: "yearlyresearcherindividualplan",
    wechatKey: "wechatadvplan",
    wechatYearlyKey: "yearlywechatadvplan",
    image: "/img/plans/Gold Tier.svg",
  },
  {
    name: i18n.t("premium"),
    price: 29.99,
    yearlyPrice: 25.49,
    oldPrice: 19.99,
    oldYearlyPrice: 16.99,
    description: i18n.t("premium_description"),
    features: [
      [
        `25${i18n.t("gb")} ${i18n.t("cloud_storage")}`,
        `1 ${i18n.t("seats")}`,
        `3 ${i18n.t("guests")}`,
      ],
      [i18n.t("credits_per_month")],
      [i18n.t("unlimited_citation_lists"), i18n.t("export")],
      [
        i18n.t("unlimited_collections"),
        i18n.t("unlimited_annotations"),
        i18n.t("single_doc_chat"),
        i18n.t("multi_doc_chat"),
        i18n.t("ai_table"),
        i18n.t("ai_create"),
        i18n.t("cross_workspace_sharing"),
      ],
    ],
    lookupKey: "academicindividualplan",
    yearlyKey: "yearlyacademicindividualplan",
    wechatKey: "wechatpremplan",
    wechatYearlyKey: "yearlywechatpremplan",
    image: "/img/plans/Business.svg",
  },
];

export const getIndividualsPlans = (isStudentEmail: boolean) => {
  if (isStudentEmail) {
    const result = [...individualsPlans];
    result[0] = {
      ...result[0],
      price: 2.99,
      yearlyPrice: 2.55,
      lookupKey: "studenteduemailplan",
      yearlyKey: "yearlystudenteduemailplan",
      wechatKey: "wechateduemailplan",
      wechatYearlyKey: "yearlywechateduemailplan",
    };
    result[1] = {
      ...result[1],
      price: 9.99,
      yearlyPrice: 8.49,
      lookupKey: "advancededuemailplan",
      yearlyKey: "yearlyadvancededuemailplan",
      wechatKey: "wechatadveduemailplan",
      wechatYearlyKey: "yearlywechatadveduemailplan",
    };
    return result;
  }
  return individualsPlans;
};

export const teamsPlans = [
  {
    name: i18n.t("team_starter"),
    price: 59.99,
    yearlyPrice: 50.99,
    oldPrice: 29.99,
    oldYearlyPrice: 25.49,
    description: i18n.t("team_starter_description"),
    features: [
      [
        `50${i18n.t("gb")} ${i18n.t("cloud_storage")}`,
        `5 ${i18n.t("seats")}`,
        `10 ${i18n.t("guests")}`,
      ],
      [i18n.t("credits_per_month")],
      [i18n.t("unlimited_citation_lists"), i18n.t("export")],
      [
        i18n.t("unlimited_collections"),
        i18n.t("unlimited_annotations"),
        i18n.t("single_doc_chat"),
        i18n.t("multi_doc_chat"),
        i18n.t("ai_table"),
        i18n.t("ai_create"),
        i18n.t("cross_workspace_sharing"),
      ],
    ],
    lookupKey: "starterteamplan",
    yearlyKey: "yearlystarterteamplan",
    wechatKey: "wechatstartplan",
    wechatYearlyKey: "yearlywechatstartplan",
    image: "/img/plans/Silver Tier.svg",
  },
  {
    name: i18n.t("team_pro"),
    price: 119.99,
    yearlyPrice: 101.99,
    oldPrice: 49.99,
    oldYearlyPrice: 42.49,
    description: i18n.t("team_pro_description"),
    features: [
      [
        `1${i18n.t("tb")} ${i18n.t("cloud_storage")}`,
        `20 ${i18n.t("seats")}`,
        `100 ${i18n.t("guests")}`,
      ],
      [i18n.t("credits_per_month")],
      [i18n.t("unlimited_citation_lists"), i18n.t("export")],
      [
        i18n.t("unlimited_collections"),
        i18n.t("unlimited_annotations"),
        i18n.t("single_doc_chat"),
        i18n.t("multi_doc_chat"),
        i18n.t("ai_table"),
        i18n.t("ai_create"),
        i18n.t("cross_workspace_sharing"),
      ],
    ],
    lookupKey: "proteamplan",
    yearlyKey: "yearlyproteamplan",
    wechatKey: "wechatproplan",
    wechatYearlyKey: "yearlywechatproplan",
    image: "/img/plans/Gold Tier.svg",
  },
];

export const teamEnterprisePlan = {
  name: i18n.t("enterprise"),
  description: i18n.t("enterprise_description"),
  price: 99999,
  features: [
    [
      i18n.t("custom_cloud_storage"),
      i18n.t("custom_seats"),
      i18n.t("custom_guests"),
    ],
    [i18n.t("custom_credit_limit")],
    [i18n.t("unlimited_citation_lists"), i18n.t("export")],
    [
      i18n.t("unlimited_collections"),
      i18n.t("unlimited_annotations"),
      i18n.t("single_doc_chat"),
      i18n.t("multi_doc_chat"),
      i18n.t("ai_table"),
      i18n.t("ai_create"),
      i18n.t("cross_workspace_sharing"),
    ],
  ],
  image: "/img/plans/Business.svg",
};

export const featureCatogeries = [
  i18n.t("general"),
  i18n.t("ai"),
  i18n.t("citation_generator"),
  i18n.t("reference_manager"),
];

export interface BillingPlan {
  name: string;
  price: number;
  oldPrice?: number;
  oldYearlyPrice?: number;
  yearlyPrice?: number;
  description: string;
  features: string[][];
  lookupKey?: string;
  yearlyKey?: string;
  wechatKey?: string;
  wechatYearlyKey?: string;
  image: string;
}

export const addons = [
  {
    name: i18n.t("seat"),
    lookup_keys: ["yearlyextramember", "extramember", "wechatextramember"],
    price: 3.99,
    yearlyPrice: 3.39,
  },
  {
    name: i18n.t("storage"),
    lookup_keys: ["extragb", "yearlyextragb", "yearlywechatextragb"],
    price: 0.99,
    yearlyPrice: 0.84,
  },
  {
    name: i18n.t("message_credits"),
    lookup_keys: ["400aicredits"],
    price: 4.99,
    yearlyPrice: 4.99,
  },
];

const defaultPlanKeys = [
  "academicindividualplan",
  "yearlyacademicindividualplan",
  "starterteamplan",
  "yearlystarterteamplan",
  "proteamplan",
  "yearlyproteamplan",
];

const customPlanKeys = [
  "studentindividualplan",
  "yearlystudentindividualplan",
  "researcherindividualplan",
  "yearlyresearcherindividualplan",
];

const customEduPlanKeys = [
  "studenteduemailplan",
  "yearlystudenteduemailplan",
  "advancededuemailplan",
  "yearlyadvancededuemailplan",
];

// we can get current plan base on ai credit limit
// notice any further changes on ai credit limit will
// reflect this object
export const AI_CREDIT_PLAN_KEY: { [key: number]: string } = {
  400: i18n.t("plus_plan"),
  1200: i18n.t("advanced_plan"),
  2000: i18n.t("premium_plan"),
  4000: i18n.t("starter_plan"),
  8000: i18n.t("pro_plan"),
  // free plan and enterprise handle separately
};

const defaultPriceOrder = [
  "academicindividualplan",
  "starterteamplan",
  "proteamplan",
];

const customPriceOrder = ["studentindividualplan", "researcherindividualplan"];

const customEduPriceOrder = ["studenteduemailplan", "advancededuemailplan"];

export const comparePlans = (
  isStudentEmail: boolean,
  key1?: string,
  key2?: string
) => {
  if (!key1 || !key2) {
    return ["error", "error"];
  }

  const customKeysToUse = isStudentEmail ? customEduPlanKeys : customPlanKeys;
  const planKeys = [...customKeysToUse, ...defaultPlanKeys];

  const customPriceOrderToUse = isStudentEmail
    ? customEduPriceOrder
    : customPriceOrder;
  const keyPriceOrder = [...customPriceOrderToUse, ...defaultPriceOrder];

  if (
    (key1.includes("yearly") && key2.includes("yearly")) ||
    (!key1.includes("yearly") && !key2.includes("yearly"))
  ) {
    // $ monthly to $$$ monthly - immediate upgrade prorate
    // $ yearly to $$$ yearly - prorate normally
    if (planKeys.indexOf(key1) < planKeys.indexOf(key2)) {
      return ["upgrade", "immediate"];
    }
    // $$$ monthly to $ monthly - $$$ service till end then $ service
    // $$$ yearly plan to $ yearly plan - $$$ service till end of period then $ service
    if (planKeys.indexOf(key1) > planKeys.indexOf(key2)) {
      return ["downgrade", "end of period"];
    }
  }

  const key1Index = keyPriceOrder.indexOf(key1.replace("yearly", ""));
  const key2Index = keyPriceOrder.indexOf(key2.replace("yearly", ""));
  if (!key1.includes("yearly") && key2.includes("yearly")) {
    // $ monthly to $$$ yearly - immediate upgrade prorate
    if (key1Index < key2Index) {
      return ["upgrade", "immediate"];
    }
    // $$$ monthly to $ yearly - $$$ until end then switch
    if (key1Index > key2Index) {
      return ["downgrade", "end of period"];
    }
    // $ monthly to $ yearly - end of month
    // $$$ monthly to $$$ yearly - start yearly plan at end of month
    if (key1Index === key2Index) {
      return ["switch", "end of period"];
    }
  }

  if (key1.includes("yearly") && !key2.includes("yearly")) {
    // $ yearly to $$$ monthly - contact us
    if (key1Index < key2Index) {
      return ["contact us", "contact us"];
    }
    // $$$ yearly plan to $ monthly plan - service till end then switch to monthly
    if (key1Index > key2Index) {
      return ["downgrade", "end of period"];
    }

    // $$$ yearly plan to $$$ monthly plan - service till end then switch to monthly
    // $ yearly to $ monthly - service till end then switch to monthly
    if (key1Index === key2Index) {
      return ["switch", "end of period"];
    }
  }
  return ["error", "error"];
};

export const isFreePlan = (orgPlans: string[]) => {
  return !orgPlans.some((plan) => plan.includes("plan"));
};

export const isCollectionLimitExceeded = (
  orgPlans: string[],
  customCollectionsLength: number
) => {
  return customCollectionsLength >= 2 && isFreePlan(orgPlans);
};

export const isAnnotationLimitExceeded = (
  orgPlans: string[],
  annotationLength: number,
  type: "create" | undefined
) => {
  return annotationLength >= 3 && isFreePlan(orgPlans) && type === "create";
};

export const canUseMultidocChat = (aiCreditLimit: number) => {
  return aiCreditLimit >= 1200;
};

export const canUseAiCreate = (aiCreditLimit: number) => {
  return aiCreditLimit >= 1200;
};

export const isTrialExist = (org_created_time?: string) => {
  // trial is not valid for white label projects
  if (!companyFeatures.app.isTrialExists) {
    return false;
  }

  const currentDate: Date = new Date();

  // check if org created time less than 7 days
  if (org_created_time) {
    const passedDate: Date = new Date(org_created_time);
    const timeDifferencePassed: number =
      currentDate.getTime() - passedDate.getTime();
    const daysDifferencePassed: number =
      timeDifferencePassed / (1000 * 60 * 60 * 24);
    if (daysDifferencePassed < 7) {
      return true;
    }
  }

  return false;
};
