import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { Organization } from "models/api/response.types";
import { useOrganizationUsage } from "api/organizationService";
import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import handleAxiosError from "utils/handleAxiosAlert";
import { useDispatch } from "react-redux";
import { addAlert } from "store/features/general/slice";
import { useTranslation } from "react-i18next";

const CancelSubscriptionDialog: React.FC<{
  organization: Organization;
  subscriptionId: string;
  isAddon?: boolean;
  open: boolean;
  close: () => void;
}> = ({ organization, isAddon, subscriptionId, close, open }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { organizationUsage, usageQueryKey } = useOrganizationUsage(
    organization.id
  );

  const onSubmit = () => {
    if (organizationUsage) {
      axios
        .post("/api/payment/cancel_subscription", {
          organization_id: organization.id,
          subscription_id: subscriptionId,
        })
        .then(() => {
          queryClient.invalidateQueries(usageQueryKey);
          dispatch(
            addAlert({
              severity: "success",
              autoHideDuration: 5000,
              alert: {
                message: t("subscription_canceled_success"),
              },
            })
          );
          close();
        })
        .catch((err) => {
          dispatch(
            addAlert({
              severity: "error",
              autoHideDuration: 5000,
              alert: {
                message: t("something_went_wrong_please"),
              },
            })
          );
          handleAxiosError(err, dispatch);
        });
    }
  };
  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth="sm">
      <DialogTitle>
        {t("cancel_confirmation", { type: isAddon ? t("add_on") : t("plan") })}
        <IconButton onClick={close}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" color="textSecondary">
          {t("cancel_confirmation_message", {
            type: isAddon ? t("add_on_lowercase") : t("plan_lowercase"),
          })}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onSubmit}
          color="error"
          size="medium"
          variant="contained"
        >
          {t("cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelSubscriptionDialog;
