/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo, useState } from "react";
import { DocumentReadResponse } from "models/api/response.types";
import { Box, Button, Checkbox, Typography, styled } from "@mui/material";
import SortDocumentMenu from "components/Browser/Documents/SortDocumentMenu";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import AddAIContextDialog from "components/Dialogs/AddAIContextDialog/AddAIContextDialog";
import AIContextDialogDocuments from "components/Dialogs/AddAIContextDialog/AIContextDialogDocument";
import {
  AutoSizer,
  ListRowProps,
  List as VirtualizedList,
} from "react-virtualized";
import { addAlert } from "store/features/general/slice";
import { useDispatch, useSelector } from "react-redux";
import { ISortOrder, sortOptions } from "utils/documentSort";
import AICreateConfirmDocumentDeletionDialog from "components/Dialogs/AICreate/AICreateConfirmDocumentDeletionDialog";
import aiCreateService, { useAICreateProjects } from "api/aiCreateService";
import { selectCurrentOrganizationId } from "store/features/session/slice";
import { useQueryClient } from "@tanstack/react-query";
import { AiCreateSelectReferencesOnboarding } from "components/Onboarding/AiCreateOnboarding";
import TableSourceDialog from "components/Dialogs/TableSourceDialog/TableSourceDialog";
import { useTranslation } from "react-i18next";

const Wrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "1500px",
  display: "flex",
  flexDirection: "column",
  flex: 1,
  paddingRight: "1rem",
  "& .table-header": {
    background: theme.background.light,
    padding: theme.spacing(1),
    gap: theme.spacing(1),
    border: `1px solid ${theme.grey.light}`,
    display: "flex",
    alignItems: "center",
    "& .document-actions": {
      marginLeft: "auto",
      "& .remove-context-button": {
        marginRight: "0.5rem",
      },
    },
  },
  "& .document-list": {
    flex: 1,
  },
  "& .error-message": {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.5rem",
    "& img": {
      width: "250px",
      [theme.breakpoints.down("lg")]: {
        width: "200px",
      },
      [theme.breakpoints.down("md")]: {
        width: "150px",
      },
    },
  },
}));

const ReferenceSelection: React.FC<{
  projectId: number;
  documentSources: DocumentReadResponse[];
}> = ({ projectId, documentSources }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const { upsertCachedAICreateProject } = useAICreateProjects(
    currentOrganizationId
  );
  const [selectedDocuments, setSelectedDocuments] = useState<
    DocumentReadResponse[]
  >([]);
  const [openAddContextDialog, setOpenAddContextDialog] =
    useState<boolean>(false);
  const [confirmDocumentDeletion, setConfirmDocumentDeletion] =
    useState<boolean>(false);
  const [documentSort, setDocumentSort] = useState<ISortOrder>({
    key: "date-created",
    order: "descending",
  });
  const [tableDocumentToUpdate, setTableDocumentToUpdate] = useState<
    DocumentReadResponse | undefined
  >(undefined);
  const exisitngDocIds = useMemo(() => {
    return documentSources.map((doc) => doc.id);
  }, [documentSources]);

  const documentsToUse = useMemo(() => {
    return documentSources.sort((a, b) =>
      sortOptions[documentSort.key].compare(a, b, documentSort.order)
    );
  }, [documentSort, documentSources]);

  const addSourcesToProject = (docsToAdd: DocumentReadResponse[]) => {
    const idsToAdd = docsToAdd.map((doc) => doc.id);
    aiCreateService
      .updateAICreateProject(projectId, {
        document_ids: [...exisitngDocIds, ...idsToAdd],
      })
      .then(({ data }) => {
        upsertCachedAICreateProject(data);
        setOpenAddContextDialog(false);
        // adding documents trigger refetch questions
        setTimeout(() => {
          queryClient.invalidateQueries([
            "ai_create_document_qas_status",
            projectId,
          ]);
        }, 500);
      })
      .catch(() => {
        dispatch(
          addAlert({
            severity: "error",
            autoHideDuration: 5000,
            alert: {
              message: t("something_went_wrong_please"),
            },
          })
        );
      });
  };

  // render rows from react-virtualized
  const rowRenderer = ({ index, key, style }: ListRowProps) => {
    if (documentsToUse) {
      return (
        <AIContextDialogDocuments
          key={key}
          style={style}
          document={documentsToUse[index]}
          setTableDocumentToUpdate={setTableDocumentToUpdate}
          selectedDocuments={selectedDocuments || []}
          setSelectedDocuments={(docs: DocumentReadResponse[]) => {
            setSelectedDocuments(docs);
          }}
          canSelect
        />
      );
    }
    return null;
  };

  return (
    <>
      <Wrapper>
        {documentsToUse && documentsToUse?.length > 0 ? (
          <>
            <Box className="table-header">
              <Checkbox
                color="primary"
                className="document-checkbox"
                size="small"
                checked={selectedDocuments.length > 0}
                indeterminate={
                  selectedDocuments.length > 0 &&
                  selectedDocuments.length < documentsToUse.length
                }
                sx={{
                  padding: "6px",
                }}
                onChange={() => {
                  if (selectedDocuments.length > 0) {
                    setSelectedDocuments([]);
                  } else {
                    setSelectedDocuments(documentsToUse);
                  }
                }}
              />
              <SortDocumentMenu
                documentSort={documentSort}
                setSortValue={setDocumentSort}
              />
              <Box className="document-actions">
                {selectedDocuments.length > 0 && (
                  <>
                    <Button
                      className="remove-context-button"
                      size="small"
                      startIcon={<DeleteOutlineOutlinedIcon fontSize="small" />}
                      onClick={() => {
                        setConfirmDocumentDeletion(true);
                      }}
                    >
                      {t("remove")}
                    </Button>
                  </>
                )}
                <Button
                  className="add-context-button"
                  size="small"
                  startIcon={<AddOutlinedIcon fontSize="small" />}
                  onClick={() => {
                    setOpenAddContextDialog(true);
                  }}
                >
                  {t("add_documents")}
                </Button>
              </Box>
            </Box>
            <Box className="document-list">
              <AutoSizer>
                {({ width, height }) => (
                  <VirtualizedList
                    height={height}
                    overscanRowCount={15}
                    noRowsRenderer={() => {
                      return (
                        <Box className="no-rows">
                          <Typography
                            variant="body1"
                            fontWeight={500}
                            textAlign="center"
                            color="textSecondary"
                          >
                            {t("no_documents_found")}
                          </Typography>
                        </Box>
                      );
                    }}
                    rowCount={documentsToUse?.length || 0}
                    rowHeight={60}
                    rowRenderer={rowRenderer}
                    width={width}
                  />
                )}
              </AutoSizer>
            </Box>
          </>
        ) : (
          <Box className="error-message">
            <img src="/img/add-documents.svg" alt="Add documents" />
            <Typography variant="h6" color="textSecondary">
              {t("no_documents_info")}
            </Typography>
            <Typography variant="body1" color="textSecondary" fontWeight={400}>
              {t("start_adding_documents")}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              onClick={() => {
                setOpenAddContextDialog(true);
              }}
            >
              {t("add_documents")}
            </Button>
          </Box>
        )}
      </Wrapper>
      {openAddContextDialog && (
        <AddAIContextDialog
          setOpen={setOpenAddContextDialog}
          addSelectedSources={addSourcesToProject}
        />
      )}
      <AICreateConfirmDocumentDeletionDialog
        projectId={projectId}
        currentDocuments={documentsToUse}
        open={confirmDocumentDeletion}
        documentsToDelete={selectedDocuments.map((doc) => doc.id)}
        close={(success?: boolean) => {
          if (success) {
            setSelectedDocuments([]);
          }
          setConfirmDocumentDeletion(false);
        }}
      />
      {tableDocumentToUpdate && (
        <TableSourceDialog
          document={tableDocumentToUpdate}
          close={() => {
            setTableDocumentToUpdate(undefined);
          }}
        />
      )}
      <AiCreateSelectReferencesOnboarding />
    </>
  );
};

export default ReferenceSelection;
