import React, { useState } from "react";
import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import aiCreateService, {
  useAICreateTemplateBlocks,
} from "api/aiCreateService";
import { useDispatch } from "react-redux";
import { addAlert } from "store/features/general/slice";
import { useTranslation } from "react-i18next";

const AICreateConfirmDocumentDeletionDialog: React.FC<{
  projectId: number;
  blockId?: number;
  close: () => void;
}> = ({ projectId, blockId, close }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const { removeCachedAICreateTemplateBlock } =
    useAICreateTemplateBlocks(projectId);

  const deleteTemplateBlock = () => {
    if (blockId) {
      setLoading(true);
      aiCreateService
        .deleteAICreateTemplateBlock(projectId, blockId)
        .then(() => {
          setLoading(false);
          removeCachedAICreateTemplateBlock(blockId);
          close();
        })
        .catch(() => {
          setLoading(false);
          dispatch(
            addAlert({
              severity: "error",
              autoHideDuration: 5000,
              alert: {
                message: t("something_went_wrong_please"),
              },
            })
          );
          close();
        });
    }
  };

  return (
    <Dialog open={!!blockId} onClose={close} fullWidth maxWidth="sm">
      <DialogTitle>
        {t("delete_block")}
        <IconButton onClick={close}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>{t("are_you_certain_delete_block")}</DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" size="medium" onClick={close}>
          {t("cancel")}
        </Button>
        <LoadingButton
          onClick={deleteTemplateBlock}
          color="primary"
          size="medium"
          variant="contained"
          loading={loading}
        >
          {t("delete")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AICreateConfirmDocumentDeletionDialog;
