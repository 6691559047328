import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import aiService, { useAITables, useTableCells } from "api/aiService";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { AITable } from "models/api/response.types";
import { useTranslation } from "react-i18next";

const AiTableCellDialog: React.FC<{
  table: AITable;
  open: boolean;
  documentId?: number;
  columnId?: number;
  cellId?: number;
  setOpen: (open: boolean) => void;
}> = ({ table, cellId, columnId, documentId, open, setOpen }) => {
  const { t } = useTranslation();
  const { updateAITableMutation } = useAITables(table.organization_id);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const { cells, upsertCachedCell } = useTableCells(table.id);

  useEffect(() => {
    setQuery("");
    if (cells && cellId) {
      const cell = cells.find((c) => c.id === cellId);
      if (cell) {
        setQuery(cell.response);
      }
    }
  }, [cells, cellId]);

  const onClose = () => {
    setQuery("");
    setOpen(false);
  };

  const handleCreate = () => {
    if (columnId && documentId) {
      setLoading(true);
      aiService
        .createTableCell(table.id, {
          ai_column_id: columnId,
          document_id: documentId,
          response: query,
        })
        .then((response) => {
          upsertCachedCell(response.data);
          onClose();
          updateAITableMutation.mutate({
            tableId: table.id,
            body: {
              meta: {
                ...table.meta,
                last_edited: new Date().toISOString(),
              },
            },
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleUpdate = () => {
    if (cellId) {
      setLoading(true);
      aiService
        .updateTableCell(table.id, cellId, { response: query })
        .then((response) => {
          upsertCachedCell(response.data);
          setQuery("");
          setOpen(false);
          updateAITableMutation.mutate({
            tableId: table.id,
            body: {
              meta: {
                ...table.meta,
                last_edited: new Date().toISOString(),
              },
            },
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (cellId) {
        handleUpdate();
      } else {
        handleCreate();
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        {cellId ? t("edit_cell_info") : t("add_cell_info")}
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          variant="outlined"
          color="primary"
          value={query}
          size="small"
          placeholder={t("enter_cell_info")}
          fullWidth
          multiline
          minRows={3}
          maxRows={12}
          onKeyDown={handleKeyPress}
          onChange={(e) => setQuery(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="contained"
          disabled={query.length === 0 || loading}
          size="medium"
          color="primary"
          loading={loading}
          onClick={() => {
            if (cellId) {
              handleUpdate();
            } else {
              handleCreate();
            }
          }}
        >
          {cellId ? t("edit") : t("add")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AiTableCellDialog;
