/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from "react";
import Wrapper from "components/Tabs/LookupTab/LookupTab-styles";
import SearchBox from "components/Tabs/LookupTab/SearchBox";
import getLocation from "utils/getLocation";
import LoadingOverlay from "components/helpers/LoadingOverlay";
import SnippetChartGenerator from "components/Tabs/LookupTab/SnippetChartGenerator";
import {
  PageLayoutResponse,
  Token,
  Snippet,
  DocumentReadResponse,
} from "models/api/response.types";
import { OpenInNew } from "@mui/icons-material";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { isMobile } from "react-device-detect";
import { JumpLocation } from "components/DocumentViewer/DocumentViewer-types";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

const colors = [
  [12, 51, 131],
  [217, 30, 30],
  [242, 143, 56],
  [220, 160, 56],
  [10, 136, 186],
  [199, 146, 223],
  [255, 242, 117],
];

interface LookupTabProps {
  view: "browse" | "document";
  document: DocumentReadResponse;
  pages: PageLayoutResponse[];
  setJumpLocation?: (jumpLocation: JumpLocation, query?: string) => void;
  setSearchHighlightRanges?: any;
  searchedQuery: string;
  setSearchedQuery: React.Dispatch<React.SetStateAction<string>>;
}

const LookupTab = React.memo(
  ({
    view,
    document,
    pages,
    setJumpLocation,
    setSearchHighlightRanges,
    searchedQuery,
    setSearchedQuery,
  }: LookupTabProps) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [searchResults, setSearchResults] = useState<{
      tokens?: Array<Token>;
      snippets?: Array<Snippet>;
    }>({
      tokens: undefined,
      snippets: undefined,
    });
    const [hoveredSnippet, setHoveredSnippet] = useState<string>("");

    useEffect(() => {
      if (setSearchHighlightRanges === undefined) {
        return;
      }
      if (
        searchResults.snippets === undefined ||
        searchResults.snippets.length === 0
      ) {
        setSearchHighlightRanges({});
      } else {
        const ranges: Record<string, { color: string }> = {};
        const searchHighlightRanges = [];
        for (let i = 0; i < searchResults.snippets.length; i += 1) {
          const { matches, start, end } = searchResults.snippets[i];
          const matchHighlights: Array<{
            start: number;
            end: number;
            color: string;
          }> = [];
          for (let j = 0; j < matches.length; j += 1) {
            const ci = matches[j].color % colors.length;
            const color = colors[ci];
            const colorStr = `rgba(${color[0]},${color[1]},${color[2]},0.5)`;
            ranges[`${matches[j].span[0]},${matches[j].span[1]}`] = {
              color: colorStr,
            };
            matchHighlights.push({
              start: matches[j].span[0],
              end: matches[j].span[1],
              color: colorStr,
            });
          }
          searchHighlightRanges.push({
            start,
            end,
            matches: matchHighlights,
          });
        }

        setSearchHighlightRanges(searchHighlightRanges);
      }
    }, [searchResults, setSearchHighlightRanges]);

    const handleSnippetClick = (location: {
      pageNumber: number;
      start: number;
    }) => {
      if (setJumpLocation) {
        setJumpLocation({ ...location, done: false, searchSnippet: true });
      }
    };

    const snippetsGenerator = () => {
      if (searchResults.snippets === undefined) return null;
      let curPage = 0;
      const snippetElements: {
        [key: string]: {
          startLocation: { pageNumber: number; start: number };
          text: string[];
        };
      } = {};

      searchResults.snippets
        .slice()
        .sort((a, b) => a.start - b.start)
        .forEach((snippet) => {
          const startLocation = getLocation(snippet.start, pages, undefined);
          if (startLocation.pageNumber > 0) {
            if (curPage === 0) {
              curPage = startLocation.pageNumber;
            }
            if (!Number.isNaN(startLocation.pageNumber)) {
              if (startLocation.pageNumber !== curPage) {
                curPage = startLocation.pageNumber;
              }
            }
          }
          let { matches } = snippet;
          matches = matches.sort(
            (a: { span: number[] }, b: { span: number[] }) =>
              a.span[0] - b.span[0]
          );
          // remove duplicates from matches
          matches = matches.filter((el, i, arr) => {
            return arr.findIndex((el2) => el2.text === el.text) === i;
          });
          let { text } = snippet;
          for (let i = matches.length - 1; i >= 0; i -= 1) {
            let start = matches[i].span[0] - snippet.start;
            let end = matches[i].span[1] - snippet.start;
            start = Math.max(0, start);
            end = Math.min(text.length - 1, end);
            const ci = matches[i].color % colors.length;
            const color = colors[ci];
            const colorStr = `rgba(${color[0]},${color[1]},${color[2]},1)`;
            text = [text.slice(0, end), "</strong>", text.slice(end)].join("");
            text = [
              text.slice(0, start),
              `<strong style="border-bottom-color:${colorStr};color:${colorStr}">`,
              text.slice(start),
            ].join("");
          }
          if (snippetElements[startLocation.pageNumber]) {
            snippetElements[startLocation.pageNumber] = {
              ...snippetElements[startLocation.pageNumber],
              text: [...snippetElements[startLocation.pageNumber].text, text],
            };
          } else {
            snippetElements[startLocation.pageNumber] = {
              startLocation,
              text: [text],
            };
          }
        });

      return Object.keys(snippetElements).map((key) => (
        <div
          className={clsx("snippet", {
            "browse-view": view === "browse",
          })}
          key={key}
          onMouseEnter={() => setHoveredSnippet(key)}
          onMouseLeave={() => setHoveredSnippet("")}
          onClick={() => {
            handleSnippetClick(snippetElements[key].startLocation);
          }}
        >
          {snippetElements[key].startLocation && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                height: "30px",
              }}
            >
              <Typography variant="body2">
                Page {snippetElements[key].startLocation.pageNumber}
              </Typography>
              {key === hoveredSnippet && view === "browse" && !isMobile && (
                <Tooltip
                  enterDelay={500}
                  title="Open snippet page in document viewer"
                >
                  <IconButton
                    className="go-to-page-button"
                    size="small"
                    color="secondary"
                    onClick={(e) => {
                      e.stopPropagation();
                      const newPageUrl = `/document/${document?.id}/view?organizationId=${document?.organization_id}#/page/${snippetElements[key].startLocation.pageNumber}`;
                      window.open(newPageUrl, "_blank");
                    }}
                  >
                    <OpenInNew fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          )}
          {/* eslint-disable-next-line react/no-danger */}
          {snippetElements[key].text.map((v: string) => (
            <div
              className="snippet-text"
              dangerouslySetInnerHTML={{ __html: v }}
            />
          ))}
        </div>
      ));
    };

    return (
      <Wrapper>
        <SearchBox
          setResults={setSearchResults}
          setLoading={setLoading}
          document={document}
          view={view}
          searchedQuery={searchedQuery}
          setSearchedQuery={setSearchedQuery}
        />
        {loading ? (
          <LoadingOverlay />
        ) : searchResults.snippets && searchResults.snippets.length > 0 ? (
          <>
            <SnippetChartGenerator
              setJumpLocation={setJumpLocation}
              colors={colors}
              pages={pages}
              searchResults={searchResults}
            />
            <div className="snippetsContainer">{snippetsGenerator()}</div>
          </>
        ) : (
          <Typography
            color="textSecondary"
            variant="body2"
            sx={{
              marginTop: "6vh",
            }}
            fontStyle="italic"
          >
            {t("no_results_found")}
          </Typography>
        )}
      </Wrapper>
    );
  }
);

export default LookupTab;
