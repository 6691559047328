/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import routePaths from "routes/routePaths";
import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

const UploadCollectionsLimitExceededDialog: React.FC<{
  setOpen: () => void;
  proceedWithFileUploadOnly: () => void;
}> = ({ setOpen, proceedWithFileUploadOnly }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Dialog fullWidth maxWidth="sm" onClose={setOpen} open>
      <DialogTitle>
        {t("collections_limit_exceeded")}
        <IconButton aria-label="close" onClick={setOpen}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Alert color="warning">
          <Trans
            i18nKey="collections_limit_warning"
            components={{
              a: (
                <Link
                  color="secondary"
                  fontWeight={500}
                  underline="hover"
                  onClick={() => {
                    navigate(routePaths.workspaceBilling);
                  }}
                />
              ),
            }}
          />
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          size="medium"
          onClick={proceedWithFileUploadOnly}
        >
          {t("proceed_with_file_upload_only")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadCollectionsLimitExceededDialog;
