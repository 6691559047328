/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/prefer-default-export */
import { QAListResponse, User } from "models/api/response.types";
import * as XLSX from "xlsx";
import i18n from "../i18n";

const getRowData = (
  qaMessages: QAListResponse,
  sessionName?: string,
  users?: User[]
) => {
  const rowData: string[][] = [];
  const excelColumns = [
    i18n.t("timestamp"),
    i18n.t("session_name"),
    i18n.t("role"),
    i18n.t("content"),
  ];
  rowData.push(excelColumns);
  qaMessages.forEach((qa) => {
    // user row
    const questioner = users?.find((user) => user.id === qa.user_id);
    rowData.push([
      qa.created_at,
      sessionName || i18n.t("unknown"),
      questioner?.email || i18n.t("user"),
      qa.query,
    ]);
    // assistant row
    rowData.push([
      qa.created_at,
      sessionName || i18n.t("unknown"),
      i18n.t("ai_assistant"),
      qa.response,
    ]);
  });
  return rowData;
};

export const exportAIChatAsExcel = (
  qaMessages: QAListResponse,
  sessionName?: string,
  users?: User[]
) => {
  const worksheet = XLSX.utils.json_to_sheet(
    getRowData(qaMessages, sessionName, users),
    { skipHeader: true }
  );
  const workbook = XLSX.utils.book_new();
  worksheet["!cols"] = [{ wpx: 150 }];
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.writeFile(workbook, `${sessionName || i18n.t("multi_doc_chat")}.xlsx`);
};
