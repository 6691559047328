/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import { Icon } from "@iconify/react";
import { Alert, Button, Container, Typography, styled } from "@mui/material";
import { APP_TITLE, LOGO_SRC, SUPPORT_URL, WWW_URL } from "company-config";
import { useTranslation } from "react-i18next";

const Wrapper = styled(Container)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: "15vh",
  gap: "1rem",
  "& .logo": {
    height: "56px",
    marginBottom: "2rem",
  },
  "@media (max-height:900px)": {
    marginTop: "10vh",
  },
  "@media (max-height:700px)": {
    marginTop: "5vh",
    "& .logo": {
      height: "46px",
    },
  },
  "& .alert": {
    width: "100%",
    maxWidth: "450px",
  },
}));

const EmergencyAlert: React.FC<{
  unSupportedDevice?: boolean;
  maintenance?: boolean;
}> = ({ unSupportedDevice, maintenance }) => {
  const { t } = useTranslation();
  return (
    <Wrapper maxWidth="sm">
      <img src={LOGO_SRC} className="logo" alt={APP_TITLE} />
      <Alert
        className="alert"
        color="warning"
        icon={<Icon icon="line-md:construction" />}
      >
        {unSupportedDevice && (
          <>
            <Typography fontWeight={500} variant="body1">
              {t("phone_device_not_supported")}
            </Typography>
            <Typography sx={{ marginTop: "1rem" }} variant="body2">
              {t("best_experience_device")}
            </Typography>
          </>
        )}
        {maintenance && (
          <>
            <Typography fontWeight={500} variant="body1">
              {t("platform_maintenance_notice")}
            </Typography>
            <Typography sx={{ marginTop: "1rem" }} variant="body2">
              {t("platform_maintenance_message")}
            </Typography>
            <Typography sx={{ marginTop: "1rem" }} variant="body2">
              {t("maintenance_apology")}
            </Typography>
          </>
        )}
      </Alert>
      <Button
        color="primary"
        variant="contained"
        fullWidth
        sx={{
          marginTop: "1rem",
          maxWidth: "450px",
        }}
        onClick={() => {
          window.open(WWW_URL, "_self");
        }}
      >
        {t("ok")}
      </Button>
      <Button
        color="primary"
        variant="outlined"
        fullWidth
        sx={{
          maxWidth: "450px",
        }}
        onClick={() => {
          if (SUPPORT_URL.type === "link") {
            window.open(SUPPORT_URL.link, "_blank");
          }
          if (SUPPORT_URL.type === "email") {
            window.location.href = SUPPORT_URL.link;
          }
        }}
      >
        {t("contact_us")}
      </Button>
    </Wrapper>
  );
};

export default EmergencyAlert;
