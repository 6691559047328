/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import { Close } from "@mui/icons-material";
import {
  Box,
  Chip,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useTags } from "api/tagService";
import { DocumentReadResponse } from "models/api/response.types";
import { selectCurrentOrganizationId } from "store/features/session/slice";
import hexToHSL from "utils/hexToHSL";
import { useTranslation } from "react-i18next";

const RemoveTagsDialog: React.FC<{
  setOpen: (open: boolean) => void;
  selectedDocuments: DocumentReadResponse[];
}> = ({ setOpen, selectedDocuments }) => {
  const { t } = useTranslation();
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const {
    tags: tagList,
    getCachedTagById,
    removeTagFromDocumentsMutation,
  } = useTags(currentOrganizationId);
  const [tagsToShow, setTagsToShow] = useState<
    { [key: number]: number[] } | undefined
  >(undefined);

  useEffect(() => {
    if (tagsToShow && Object.keys(tagsToShow).length === 0) {
      setOpen(false);
    }
  }, [tagsToShow]);

  useEffect(() => {
    if (tagList && !tagsToShow) {
      const tags: { [key: number]: number[] } = {};
      selectedDocuments.forEach((doc) => {
        doc.tag_ids.forEach((tagId) => {
          if (tags[tagId]) {
            tags[tagId] = [...tags[tagId], doc.id];
          } else {
            tags[tagId] = [doc.id];
          }
        });
      });
      setTagsToShow(tags);
    }
  }, [tagList]);

  const handleDeleteTag = (tagId: number, documentIds: number[]) => {
    const newTagList = { ...tagsToShow };
    const deletedValue = newTagList[tagId];
    delete newTagList[tagId];
    setTagsToShow(newTagList);
    removeTagFromDocumentsMutation.mutate(
      { tagId, documentIds },
      {
        onError: () => {
          setTagsToShow({
            ...newTagList,
            [tagId]: deletedValue,
          });
        },
      }
    );
  };

  if (!tagsToShow) {
    return null;
  }

  return (
    <Dialog
      open
      onClose={() => {
        setOpen(false);
      }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        {t("remove_tags")}
        <IconButton
          onClick={() => {
            setOpen(false);
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">{t("remove_tag_warning")}</Typography>
        <Box
          sx={{
            margin: "1rem 0",
            display: "flex",
            alignItems: "center",
            gap: "0.5rem 0.3rem",
            flexWrap: "wrap",
            maxHeight: "200px",
            overflowY: "auto",
            paddingRight: "0.5rem",
          }}
        >
          {Object.keys(tagsToShow).map((tagId) => {
            const [tag] = getCachedTagById(parseInt(tagId, 10));
            if (tag) {
              const { l } = hexToHSL(tag.color);
              const color = l < 60 ? "#fff" : "#000";
              return (
                <Chip
                  key={tag.id}
                  size="medium"
                  label={`${tag.name} (${tagsToShow[tag.id].length})`}
                  style={{ color, backgroundColor: tag.color }}
                  onDelete={() => handleDeleteTag(tag.id, tagsToShow[tag.id])}
                />
              );
            }
            return null;
          })}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default RemoveTagsDialog;
