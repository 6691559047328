import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  styled,
} from "@mui/material";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { AISession } from "models/api/response.types";
import { useDispatch, useSelector } from "react-redux";
import { addAlert } from "store/features/general/slice";
import { useAISessions } from "api/aiService";
import { selectCurrentOrganizationId } from "store/features/session/slice";
import { LoadingButton } from "@mui/lab";
import { PROD_URL } from "company-config";
import { useTranslation } from "react-i18next";

const DialogWrapper = styled(Dialog)(({ theme }) => ({
  "& .content": {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    "& pre": {
      flex: 1,
      overflow: "auto",
      fontSize: "12px",
      background: theme.grey.light,
      padding: " 0.5rem 1rem",
      borderRadius: "4px",
    },
  },
}));

const ShareAIChatSessionDialog: React.FC<{
  session?: AISession;
  setSelectedSession: (session: AISession) => void;
  close: () => void;
}> = ({ session, setSelectedSession, close }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const organizationId = useSelector(selectCurrentOrganizationId);
  const [loading, setLoading] = useState<boolean>(false);
  const { updateAISessionMutation } = useAISessions(organizationId, undefined);

  const link = `${PROD_URL}/browse/ai-chat?organizationId=${organizationId}&sessionId=${session?.id}`;

  const shareSession = () => {
    if (session) {
      setLoading(true);
      updateAISessionMutation.mutate(
        {
          sessionId: session?.id,
          body: {
            visibility: "shared",
          },
        },
        {
          onSuccess: ({ data }) => {
            setSelectedSession(data);
            setLoading(false);
          },
        }
      );
    }
  };

  return (
    <DialogWrapper open={!!session} onClose={close} fullWidth maxWidth="sm">
      <DialogTitle>{t("share_ai_chat")}</DialogTitle>
      <DialogContent>
        {session?.visibility === "shared" ? (
          <>
            <Typography variant="body1">{t("share_chat_link")}</Typography>
            <Box className="content">
              <pre>
                <code>{link}</code>
              </pre>
              <IconButton
                color="primary"
                size="medium"
                onClick={() => {
                  if ("clipboard" in navigator) {
                    navigator.clipboard.writeText(link);
                    document.execCommand("copy", true, link);
                    dispatch(
                      addAlert({
                        severity: "success",
                        autoHideDuration: 5000,
                        alert: {
                          message: t("link_copied_successfully"),
                        },
                      })
                    );
                    close();
                  }
                }}
              >
                <ContentCopyOutlinedIcon fontSize="small" />
              </IconButton>
            </Box>
          </>
        ) : (
          <>
            <Typography variant="body1">{t("private_chat_warning")}</Typography>
          </>
        )}
      </DialogContent>
      {session?.visibility === "private" && (
        <DialogActions>
          <Button size="medium" onClick={close} color="primary">
            {t("cancel")}
          </Button>
          <LoadingButton
            onClick={shareSession}
            color="primary"
            size="medium"
            variant="contained"
            loading={loading}
          >
            {t("share_chat")}
          </LoadingButton>
        </DialogActions>
      )}
    </DialogWrapper>
  );
};

export default ShareAIChatSessionDialog;
