import React, { useState } from "react";
import { Box, Button, Typography, styled } from "@mui/material";
import { addons } from "utils/plans";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import moment from "moment";
import CancelSubscriptionDialog from "components/Dialogs/CancelSubscriptionDialog";
import { useOrg } from "pages/WorkspaceSettings";
import { useTranslation } from "react-i18next";

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "1rem",
  border: `1px solid ${theme.grey.light}`,
  borderRadius: "4px",
  padding: "0.5rem 1rem",
  "& .color-border": {
    height: "50px",
    width: "5px",
    borderRadius: "50px",
    backgroundColor: theme.palette.warning.dark,
  },
  "& .actions": {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    "& .period": {
      padding: "0.5rem",
      border: "1px solid",
      borderRadius: "12px",
      borderColor: theme.palette.warning.dark,
      backgroundColor: `${theme.palette.warning.main}10`,
    },
  },
}));

const Addon: React.FC<{
  addon: any;
}> = ({ addon }) => {
  const { t } = useTranslation();
  const { org: currentOrg } = useOrg();
  const currentAddon = addons.find((ad) =>
    ad.lookup_keys.includes(addon.lookup_key)
  );
  const { at_period_end, quantity, period_end, subscription_id } = addon;
  const [cancelPlanDialog, setCancelPlanDialog] = useState<boolean>(false);

  if (!currentAddon) {
    return null;
  }

  // Determine the price per period (monthly or yearly)
  const price = (
    quantity *
    (addon.lookup_key.includes("yearly")
      ? currentAddon.yearlyPrice
      : currentAddon.price)
  ).toFixed(2);

  // Pluralization for seats
  const quantityPlural = quantity > 1 ? "seats" : "seat";

  // Format the expiration date
  const formattedExpirationDate = moment(period_end).format(
    "MMMM D, YYYY, hh:mm:ss A"
  );

  return (
    <>
      <Wrapper>
        <Box className="color-border" />
        <Box>
          <Typography variant="h6">{currentAddon.name}</Typography>
          <Typography variant="body1" color="textSecondary">
            ${price}
            {addon.lookup_key.includes("yearly")
              ? t("price_per_year")
              : t("price_per_month")}
            {currentAddon.name === "Seat" &&
              t("for_seats", { quantity, quantity_plural: quantityPlural })}
            {currentAddon.name === "Storage" && t("for_storage", { quantity })}
          </Typography>
        </Box>
        <Box className="actions">
          {at_period_end === "cancel" ? (
            <Typography fontSize="small" className="period">
              {t("expiring", { expirationDate: formattedExpirationDate })}
            </Typography>
          ) : (
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={() => setCancelPlanDialog(true)}
              startIcon={<ClearOutlinedIcon fontSize="small" />}
            >
              {t("cancel")}
            </Button>
          )}
        </Box>
      </Wrapper>
      {cancelPlanDialog && (
        <CancelSubscriptionDialog
          open={cancelPlanDialog}
          organization={currentOrg}
          subscriptionId={subscription_id}
          isAddon
          close={() => {
            setCancelPlanDialog(false);
          }}
        />
      )}
    </>
  );
};

export default Addon;
