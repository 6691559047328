import i18n from "../i18n";

/* eslint-disable import/prefer-default-export */
export const academicStatusList = [
  i18n.t("student_bachelor"),
  i18n.t("student_master"),
  i18n.t("student_doctoral_student"),
  i18n.t("student_phd_student"),
  i18n.t("student_postgraduate"),
  i18n.t("professor"),
  i18n.t("associate_professor"),
  i18n.t("researcher"),
  i18n.t("lecturer"),
  i18n.t("librarian"),
  i18n.t("unspecified"),
];

export const disciplineList = [
  i18n.t("agricultural_and_biological_sciences"),
  i18n.t("arts_and_humanities"),
  i18n.t("biochemistry_genetics_and_molecular_biology"),
  i18n.t("business_management_and_accounting"),
  i18n.t("chemical_engineering"),
  i18n.t("chemistry"),
  i18n.t("computer_science"),
  i18n.t("decision_sciences"),
  i18n.t("design"),
  i18n.t("earth_and_planetary_sciences"),
  i18n.t("economics_econometrics_and_finance"),
  i18n.t("energy"),
  i18n.t("engineering"),
  i18n.t("environmental_science"),
  i18n.t("immunology_and_microbiology"),
  i18n.t("linguistics"),
  i18n.t("materials_science"),
  i18n.t("mathematics"),
  i18n.t("medicine_and_dentistry"),
  i18n.t("neuroscience"),
  i18n.t("nursing_and_health_professions"),
  i18n.t("pharmacology_toxicology_and_pharmaceutical_science"),
  i18n.t("philosophy"),
  i18n.t("physics_and_astronomy"),
  i18n.t("psychology"),
  i18n.t("social_sciences"),
  i18n.t("sports_and_recreations"),
  i18n.t("veterinary_science_and_veterinary_medicine"),
  i18n.t("unspecified"),
];
