import React, { RefObject, useEffect, useRef, useState } from "react";
import { Box, IconButton, TextField, styled } from "@mui/material";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { addAlert } from "store/features/general/slice";
import { useTranslation } from "react-i18next";

const useOnClickAway = <T extends HTMLElement>(
  ref: RefObject<T>,
  handler: (event: MouseEvent) => void
): void => {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }

      handler(event);
    };

    document.addEventListener("mousedown", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [ref, handler]);
};

const Wrapper = styled(Box)(({ theme }) => ({
  minWidth: "240px",
  minHeight: "32px",
  width: "240px",
  height: "32px",
  border: "1px solid",
  borderColor: `${theme.background.secondary}`,
  borderRadius: "4px",
  display: "flex",
  alignItems: "center",
  fontSize: "14px",
  overflow: "hidden",
  position: "relative",
  "&.header": {
    background: theme.background.secondary,
  },
  "&.description": {
    background: theme.background.secondary,
    "&.description-empty": {
      opacity: 0.5,
    },
  },
  "&:not(.update-mode)": {
    padding: "0 0.5rem",
  },
  "&.update-mode": {
    borderColor: `${theme.palette.primary.main}`,
  },
  "& .isDisabled": {
    background: theme.grey.light,
  },
  "& .cell-value": {
    margin: 0,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "&.description": {
      fontStyle: "italic",
      paddingRight: "2px",
      "&.disabled": {
        userSelect: "none",
      },
    },
  },
  "& .textfield": {
    background: theme.background.light,
    width: "240px",
    height: "32px",
    "& fieldset": {
      border: "none",
    },
    "& input": {
      width: "240px",
      height: "32px",
      padding: "0 0.5rem",
    },
  },
  "&:hover": {
    "& .action-container": {
      display: "flex",
    },
  },
  "& .action-container": {
    position: "absolute",
    background: theme.background.light,
    top: 0,
    right: 0,
    height: "100%",
    borderRadius: "4px",
    width: "30px",
    display: "none",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const TableSourceCell: React.FC<{
  canDeleteRow?: boolean;
  deleteRow?: () => void;
  canDeleteColumn?: boolean;
  deleteColumn?: () => void;
  isReadMode?: boolean;
  allColumnHeaders?: string[];
  allRowHeaders?: string[];
  isColumnHeader?: boolean;
  isRowHeader?: boolean;
  isColumnDescription?: boolean;
  isRowDescription?: boolean;
  isDisabled?: boolean;
  value: string;
  setValue: (newValue: string) => void;
}> = ({
  canDeleteColumn,
  deleteColumn,
  canDeleteRow,
  deleteRow,
  isReadMode,
  allColumnHeaders,
  allRowHeaders,
  isColumnHeader,
  isRowHeader,
  isDisabled,
  isColumnDescription,
  isRowDescription,
  value,
  setValue,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [currentValue, setCurrentValue] = useState<string | undefined>(value);
  const [isUpdateMode, setIsUpdateMode] = useState<boolean>(false);
  const isDescription = isColumnDescription || isRowDescription;
  const isHeader = isColumnHeader || isRowHeader;
  const ref = useRef<HTMLDivElement>(null);

  useOnClickAway(ref, () => {
    setIsUpdateMode(false);
  });

  useEffect(() => {
    if (value !== currentValue) {
      setCurrentValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (
      !isUpdateMode &&
      value !== currentValue &&
      typeof currentValue === "string"
    ) {
      if (isHeader && currentValue.length === 0) {
        setCurrentValue(value);
        setTimeout(() => {
          dispatch(
            addAlert({
              severity: "error",
              autoHideDuration: 3000,
              alert: {
                message: t("column_header_empty"),
              },
            })
          );
        }, 300);
      } else if (
        isColumnHeader &&
        allColumnHeaders &&
        allColumnHeaders.includes(currentValue)
      ) {
        setCurrentValue(value);
        setTimeout(() => {
          dispatch(
            addAlert({
              severity: "warning",
              autoHideDuration: 3000,
              alert: {
                message: t("column_header_exists"),
              },
            })
          );
        }, 300);
      } else if (
        isRowHeader &&
        allRowHeaders &&
        allRowHeaders.includes(currentValue)
      ) {
        setCurrentValue(value);
        setTimeout(() => {
          dispatch(
            addAlert({
              severity: "warning",
              autoHideDuration: 3000,
              alert: {
                message: t("row_header_exists"),
              },
            })
          );
        }, 300);
      } else {
        setValue(currentValue);
      }
    }
  }, [isUpdateMode]);

  return (
    <Wrapper
      ref={ref}
      className={clsx("cell", {
        header: isHeader,
        description: isDescription,
        "description-empty": isDescription && !currentValue,
        "update-mode": isUpdateMode,
        disabled: isDisabled,
      })}
      onClick={() => {
        if (!isUpdateMode && !isDisabled && !isReadMode) {
          setIsUpdateMode(true);
        }
      }}
    >
      {isUpdateMode ? (
        <TextField
          className="textfield"
          value={currentValue || ""}
          autoFocus
          onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
            event.target.select();
          }}
          onChange={(e) => {
            setCurrentValue(e.target.value);
          }}
          onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
            if (event.key === "Enter") {
              event.preventDefault();
              setIsUpdateMode(false);
            }
          }}
        />
      ) : isDescription ? (
        <p
          className={clsx("cell-value description", {
            disabled: !currentValue,
          })}
        >
          {!currentValue
            ? isColumnDescription
              ? "Add column description"
              : "Add row description"
            : currentValue}
        </p>
      ) : (
        <p className="cell-value">{isDisabled ? "" : currentValue}</p>
      )}
      {(canDeleteColumn || canDeleteRow) && !isUpdateMode && (
        <Box className="action-container">
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              if (canDeleteColumn && deleteColumn) {
                deleteColumn();
              }

              if (canDeleteRow && deleteRow) {
                deleteRow();
              }
            }}
          >
            <DeleteOutlineOutlinedIcon fontSize="small" />
          </IconButton>
        </Box>
      )}
    </Wrapper>
  );
};

export default TableSourceCell;
