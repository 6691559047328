/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unstable-nested-components */
import React, { useContext, useState } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import Joyride, { CallBackProps } from "react-joyride";
import useTelemetry, { telemetryAction } from "utils/useTelemetry";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setUser } from "store/features/session/slice";
import { DocumentReadResponse } from "models/api/response.types";
import { DocumentsContext } from "pages/Documents";
import {
  isSidebarOpen,
  setIsSearch,
  setSelectedCollectionId,
  setSideBarOpen,
} from "store/features/browser/slice";
import { authService } from "api";
import { useTranslation } from "react-i18next";
import OnboardingTooltip from "./Tooltip";

const DocumentsOnboarding: React.FC<{
  documents: DocumentReadResponse[];
}> = ({ documents }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { setSelectedDocuments, setDocumentToShow } =
    useContext(DocumentsContext);
  const sideBarOpen = useSelector(isSidebarOpen);
  const smScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const user = useSelector(selectUser);
  const { logAction } = useTelemetry();
  const [stepIndex, setStepIndex] = useState<number>(0);

  const completeCurrentOnboarding = () => {
    if (user) {
      authService
        .updateUser(user.id, {
          meta: {
            ...user.meta,
            citeOnboarding: {
              ...user.meta.citeOnboarding,
              documentsCompleted: new Date().toISOString(),
            },
          },
        })
        .then(({ data }) => {
          dispatch(setUser({ ...user, ...data }));
        })
        .catch((err) => err);
    }
  };

  const steps = [
    {
      target: ".collection-selector",
      placement: "bottom" as const,
      disableBeacon: true,
      disableOverlayClose: true,
      disableCloseOnEsc: true,
      content: (
        <Box
          className="onboarding-step"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Typography variant="h6">{t("collections")}</Typography>
          <Typography variant="body1" color="textSecondary">
            {t("collections_instruction")}
          </Typography>
        </Box>
      ),
    },
    {
      target: ".search-input-container",
      placement: "bottom" as const,
      disableBeacon: true,
      disableOverlayClose: true,
      disableCloseOnEsc: true,
      content: (
        <Box
          className="onboarding-step"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Typography variant="h6">{t("search")}</Typography>
          <Typography variant="body1" color="textSecondary">
            {t("search_instruction")}
          </Typography>
        </Box>
      ),
    },
    {
      target: ".right-side-bar",
      placement: smScreen ? ("center" as const) : ("left-start" as const),
      disableBeacon: true,
      disableOverlayClose: true,
      disableCloseOnEsc: true,
      content: (
        <Box
          className="onboarding-step"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Typography variant="h6">{t("properties")}</Typography>
          <Typography variant="body1" color="textSecondary">
            {t("properties_instruction")}
          </Typography>
        </Box>
      ),
    },
    {
      target: ".ask-ai-button",
      placement: "left-start" as const,
      disableBeacon: true,
      disableOverlayClose: true,
      disableCloseOnEsc: true,
      content: (
        <Box
          className="onboarding-step"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Typography variant="h6">{t("ai_chat_single_document")}</Typography>
          <Typography variant="body1" color="textSecondary">
            {t("ai_chat_single_document_instruction")}
          </Typography>
        </Box>
      ),
    },
    {
      target: ".document-container-actions",
      placement: "bottom-start" as const,
      disableBeacon: true,
      disableOverlayClose: true,
      disableCloseOnEsc: true,
      content: (
        <Box
          className="onboarding-step"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Typography variant="h6">{t("document_options")}</Typography>
          <Typography variant="body1" color="textSecondary">
            {t("document_options_instruction")}
          </Typography>
        </Box>
      ),
    },
  ];

  const stepCallback = (data: CallBackProps) => {
    const { action, lifecycle, index } = data;
    if (action === "start" && lifecycle === "init" && index === 0) {
      if (sideBarOpen && smScreen) {
        dispatch(setSideBarOpen(false));
      }
      dispatch(setSelectedCollectionId(undefined));
      dispatch(setIsSearch(false));
    }
    if (action === "close" && lifecycle === "complete") {
      if (index === 1) {
        setDocumentToShow(documents[0]);
        Array.from(
          document.getElementsByClassName(
            "__floater__body"
          ) as HTMLCollectionOf<HTMLElement>
        )[0].style.display = "none";
        setTimeout(() => {
          setStepIndex(index + 1);
        }, 500);
      } else if (index === 3) {
        setDocumentToShow(undefined);
        setSelectedDocuments([documents[0]]);
        setStepIndex(index + 1);
      } else if (index === 4) {
        logAction(telemetryAction.onboarding_flow, {
          method: "documents_completed",
        });
        setSelectedDocuments([]);
        completeCurrentOnboarding();
        setStepIndex(index + 1);
      } else {
        setStepIndex(index + 1);
      }
    }
    if (action === "prev" && lifecycle === "complete") {
      setStepIndex(index - 1);
    }
  };

  return (
    <Joyride
      run
      stepIndex={stepIndex}
      steps={steps}
      tooltipComponent={OnboardingTooltip}
      callback={stepCallback}
      floaterProps={{
        styles: {
          wrapper: {
            zIndex: 1501,
          },
        },
      }}
      styles={{
        options: {
          zIndex: 1500,
        },
      }}
    />
  );
};

export default DocumentsOnboarding;
