/* eslint-disable react/jsx-props-no-spreading */
import { Box, Button, Typography, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { TooltipRenderProps } from "react-joyride";

const OnboardingTooltip: React.FC<TooltipRenderProps> = ({
  index,
  primaryProps,
  backProps,
  step,
  size,
  tooltipProps,
  isLastStep,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Box
      {...tooltipProps}
      sx={{
        background: theme.background.light,
        borderRadius: "4px",
        padding: "1rem 2rem",
        "& .onboarding-step": {
          width: "400px",
        },
      }}
    >
      {step.content}
      {!isLastStep && (
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            marginTop: "1rem",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {index < size - 1 && (
            <>
              <Button
                variant="text"
                color="primary"
                size="medium"
                {...backProps}
                sx={{
                  ...(index < 1 && {
                    opacity: 0,
                  }),
                }}
              >
                {t("back")}
              </Button>
              <Typography variant="body2" color="primary" fontWeight={500}>
                {`${index + 1}/${size}`}
              </Typography>
            </>
          )}
          <Button
            variant="contained"
            color="primary"
            size="medium"
            {...primaryProps}
          >
            {index < size - 1 ? t("next") : t("got_it")}
          </Button>
        </Box>
      )}
      {isLastStep && (
        <Box
          sx={{
            display: "flex",
            marginTop: "1rem",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            size="medium"
            {...primaryProps}
          >
            {t("got_it")}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default OnboardingTooltip;
