import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import aiCreateService, { useAICreateQuestions } from "api/aiCreateService";
import { AICreateQuestion } from "models/api/response.types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { addAlert } from "store/features/general/slice";

const EditExtractedKnowledgeQuestionDialog: React.FC<{
  question?: AICreateQuestion;
  close: () => void;
}> = ({ question, close }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [questionResponse, setQuestionResponse] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { upsertCachedAICreateQuestion } = useAICreateQuestions(
    question?.project_id
  );

  useEffect(() => {
    if (question) {
      setQuestionResponse(question.response);
    }
  }, [question]);

  const handleClose = () => {
    close();
  };

  const handleUpdateQuestion = () => {
    if (question) {
      setLoading(true);
      const { project_id, id } = question;
      aiCreateService
        .updateAICreateQAResponse(project_id, id, questionResponse)
        .then(({ data }) => {
          setLoading(false);
          upsertCachedAICreateQuestion(data);
          handleClose();
        })
        .catch(() => {
          setLoading(false);
          dispatch(
            addAlert({
              severity: "error",
              autoHideDuration: 5000,
              alert: {
                message: t("something_went_wrong_please"),
              },
            })
          );
        });
    }
  };

  return (
    <Dialog open={!!question} onClose={close} fullWidth maxWidth="sm">
      <DialogTitle>
        {t("edit_response")}
        <IconButton onClick={close}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          value={questionResponse}
          color="primary"
          variant="outlined"
          fullWidth
          type="text"
          placeholder={t("response_to_question")}
          multiline
          minRows={3}
          maxRows={12}
          size="small"
          onChange={(e) => {
            setQuestionResponse(e.target.value);
          }}
          onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
            if (event.key === "Enter" && questionResponse.length > 0) {
              event.preventDefault();
              handleUpdateQuestion();
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          color="primary"
          size="medium"
          onClick={handleClose}
        >
          {t("cancel")}
        </Button>
        <LoadingButton
          onClick={handleUpdateQuestion}
          color="primary"
          size="medium"
          variant="contained"
          loading={loading}
          disabled={questionResponse.length === 0}
        >
          {t("edit")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditExtractedKnowledgeQuestionDialog;
