/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setUser } from "store/features/session/slice";
import Joyride, { CallBackProps } from "react-joyride";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { isSidebarOpen, setSideBarOpen } from "store/features/browser/slice";
import useTelemetry, { telemetryAction } from "utils/useTelemetry";
import { authService } from "api";
import { useTranslation } from "react-i18next";
import OnboardingTooltip from "./Tooltip";

export const AiCreateProjectDashboardOnboarding: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const sideBarOpen = useSelector(isSidebarOpen);
  const user = useSelector(selectUser);
  const smScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const { logAction } = useTelemetry();

  const completeCurrentOnboarding = (action: string) => {
    if (user) {
      authService
        .updateUser(user.id, {
          meta: {
            ...user.meta,
            citeOnboarding: {
              ...user.meta.citeOnboarding,
              aiCreate: {
                ...user.meta.citeOnboarding.aiCreate,
                [action]: new Date().toISOString(),
              },
            },
          },
        })
        .then(({ data }) => {
          dispatch(setUser({ ...user, ...data }));
        })
        .catch((err) => err);
    }
  };

  const dashboardSteps = [
    {
      target: ".main-container",
      placement: "center" as const,
      disableBeacon: true,
      disableOverlayClose: true,
      disableCloseOnEsc: true,
      content: (
        <Box
          className="onboarding-step"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Typography variant="h6">{t("welcome_to_ai_create")}</Typography>
          <Typography variant="body1" color="textSecondary">
            {t("ai_create_description")}
          </Typography>
        </Box>
      ),
    },
    {
      target: ".templates-container",
      placement: "center" as const,
      disableBeacon: true,
      disableOverlayClose: true,
      disableCloseOnEsc: true,
      content: (
        <Box
          className="onboarding-step"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Typography variant="h6">{t("start_new_project")}</Typography>
          <Typography variant="body1" color="textSecondary">
            {t("start_new_project_instruction")}
          </Typography>
        </Box>
      ),
    },
  ];

  const dashboardCallback = (data: CallBackProps) => {
    const { action, lifecycle, index } = data;

    // start with the sidebar open, so that on the second step,
    // the tooltip can reference it. Then close it for the last step.
    if (
      action === "start" &&
      lifecycle === "init" &&
      index === 0 &&
      sideBarOpen &&
      smScreen
    ) {
      dispatch(setSideBarOpen(false));
    }

    if (
      action === "close" &&
      lifecycle === "complete" &&
      index === dashboardSteps.length - 1
    ) {
      logAction(telemetryAction.onboarding_flow, {
        method: "ai_create_dashboard_onboarding_completed",
      });
      completeCurrentOnboarding("dashboard");
    }
  };

  if (user?.meta?.citeOnboarding?.aiCreate?.dashboard) {
    return null;
  }

  return (
    <Joyride
      run
      steps={dashboardSteps}
      tooltipComponent={OnboardingTooltip}
      callback={dashboardCallback}
      floaterProps={{
        styles: {
          wrapper: {
            zIndex: 1501,
          },
        },
      }}
      styles={{
        options: {
          zIndex: 1500,
        },
      }}
    />
  );
};

export const AiCreateSelectReferencesOnboarding = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const sideBarOpen = useSelector(isSidebarOpen);
  const user = useSelector(selectUser);
  const smScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const { logAction } = useTelemetry();

  const completeCurrentOnboarding = (action: string) => {
    if (user) {
      authService
        .updateUser(user.id, {
          meta: {
            ...user.meta,
            citeOnboarding: {
              ...user.meta.citeOnboarding,
              aiCreate: {
                ...user.meta.citeOnboarding.aiCreate,
                [action]: new Date().toISOString(),
              },
            },
          },
        })
        .then(({ data }) => {
          dispatch(setUser({ ...user, ...data }));
        })
        .catch((err) => err);
    }
  };

  const referencesSteps = [
    {
      target: "body",
      placement: "center" as const,
      disableBeacon: true,
      disableOverlayClose: true,
      disableCloseOnEsc: true,
      content: (
        <Box
          className="onboarding-step"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Typography variant="h6">{t("review_sources")}</Typography>
          <Typography variant="body1" color="textSecondary">
            {t("review_sources_instruction")}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {t("next_step_instruction")}
          </Typography>
        </Box>
      ),
    },
  ];

  const referencesCallback = (data: CallBackProps) => {
    const { action, lifecycle, index } = data;
    if (
      action === "start" &&
      lifecycle === "init" &&
      index === 0 &&
      sideBarOpen &&
      smScreen
    ) {
      dispatch(setSideBarOpen(false));
    }
    if (
      action === "close" &&
      lifecycle === "complete" &&
      index === referencesSteps.length - 1
    ) {
      logAction(telemetryAction.onboarding_flow, {
        method: "ai_create_select_references_onboarding_completed",
      });
      completeCurrentOnboarding("selectReferences");
    }
  };

  if (user?.meta?.citeOnboarding?.aiCreate?.selectReferences) {
    return null;
  }

  return (
    <Joyride
      run
      steps={referencesSteps}
      tooltipComponent={OnboardingTooltip}
      callback={referencesCallback}
      floaterProps={{
        styles: {
          wrapper: {
            zIndex: 1501,
          },
        },
      }}
      styles={{
        options: {
          zIndex: 1500,
        },
      }}
    />
  );
};

export const AiCreateExtractKnowledgeOnboarding = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const sideBarOpen = useSelector(isSidebarOpen);
  const user = useSelector(selectUser);
  const smScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const { logAction } = useTelemetry();

  const completeCurrentOnboarding = (action: string) => {
    if (user) {
      authService
        .updateUser(user.id, {
          meta: {
            ...user.meta,
            citeOnboarding: {
              ...user.meta.citeOnboarding,
              aiCreate: {
                ...user.meta.citeOnboarding.aiCreate,
                [action]: new Date().toISOString(),
              },
            },
          },
        })
        .then(({ data }) => {
          dispatch(setUser({ ...user, ...data }));
        })
        .catch((err) => err);
    }
  };

  const steps = [
    {
      target: "body",
      placement: "center" as const,
      disableBeacon: true,
      disableOverlayClose: true,
      disableCloseOnEsc: true,
      content: (
        <Box
          className="onboarding-step"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Typography variant="h6">{t("extract_knowledge")}</Typography>
          <Typography variant="body1" color="textSecondary">
            {t("extract_knowledge_instruction")}
          </Typography>
        </Box>
      ),
    },
  ];

  const callback = (data: CallBackProps) => {
    const { action, lifecycle, index } = data;
    if (
      action === "start" &&
      lifecycle === "init" &&
      index === 0 &&
      sideBarOpen &&
      smScreen
    ) {
      dispatch(setSideBarOpen(false));
    }
    if (
      action === "close" &&
      lifecycle === "complete" &&
      index === steps.length - 1
    ) {
      logAction(telemetryAction.onboarding_flow, {
        method: "ai_create_extract_knowledge_onboarding_completed",
      });
      completeCurrentOnboarding("extractKnowledge");
    }
  };

  if (user?.meta?.citeOnboarding?.aiCreate?.extractKnowledge) {
    return null;
  }

  return (
    <Joyride
      run
      steps={steps}
      tooltipComponent={OnboardingTooltip}
      callback={callback}
      floaterProps={{
        styles: {
          wrapper: {
            zIndex: 1501,
          },
        },
      }}
      styles={{
        options: {
          zIndex: 1500,
        },
      }}
    />
  );
};

export const AiCreateWriteOnboarding = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const sideBarOpen = useSelector(isSidebarOpen);
  const user = useSelector(selectUser);
  const smScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const { logAction } = useTelemetry();

  const completeCurrentOnboarding = (action: string) => {
    if (user) {
      authService
        .updateUser(user.id, {
          meta: {
            ...user.meta,
            citeOnboarding: {
              ...user.meta.citeOnboarding,
              aiCreate: {
                ...user.meta.citeOnboarding.aiCreate,
                [action]: new Date().toISOString(),
              },
            },
          },
        })
        .then(({ data }) => {
          dispatch(setUser({ ...user, ...data }));
        })
        .catch((err) => err);
    }
  };

  useEffect(() => {
    if (
      user?.meta?.citeOnboarding?.aiCreate?.dashboard &&
      user?.meta?.citeOnboarding?.aiCreate?.selectReferences &&
      user?.meta?.citeOnboarding?.aiCreate?.extractKnowledge &&
      user?.meta?.citeOnboarding?.aiCreate?.write
    ) {
      completeCurrentOnboarding("completed");
    }
  }, [user]);

  const steps = [
    {
      target: "body",
      placement: "center" as const,
      disableBeacon: true,
      disableOverlayClose: true,
      disableCloseOnEsc: true,
      content: (
        <Box
          className="onboarding-step"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Typography variant="h6">{t("write")}</Typography>
          <Typography variant="body1" color="textSecondary">
            {t("write_instruction")}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {t("ai_block_instruction")}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {t("export_document")}
          </Typography>
        </Box>
      ),
    },
  ];

  const callback = (data: CallBackProps) => {
    const { action, lifecycle, index } = data;
    if (
      action === "start" &&
      lifecycle === "init" &&
      index === 0 &&
      sideBarOpen &&
      smScreen
    ) {
      dispatch(setSideBarOpen(false));
    }
    if (
      action === "close" &&
      lifecycle === "complete" &&
      index === steps.length - 1
    ) {
      logAction(telemetryAction.onboarding_flow, {
        method: "ai_create_write_onboarding_completed",
      });
      completeCurrentOnboarding("write");
    }
  };

  if (user?.meta?.citeOnboarding?.aiCreate?.write) {
    return null;
  }

  return (
    <Joyride
      run
      steps={steps}
      tooltipComponent={OnboardingTooltip}
      callback={callback}
      floaterProps={{
        styles: {
          wrapper: {
            zIndex: 1501,
          },
        },
      }}
      styles={{
        options: {
          zIndex: 1500,
        },
      }}
    />
  );
};
