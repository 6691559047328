import React, { useEffect, useState } from "react";
import { Box, TextField } from "@mui/material";
import { AICreateTemplateBlock } from "models/api/response.types";
import aiCreateService, {
  useAICreateTemplateBlocks,
} from "api/aiCreateService";
import { useTranslation } from "react-i18next";

const TextBlockContentType: React.FC<{ block: AICreateTemplateBlock }> = ({
  block,
}) => {
  const { t } = useTranslation();
  const { upsertCachedAICreateTemplateBlock } = useAICreateTemplateBlocks(
    block.project_id
  );
  const [text, setText] = useState<string>(block.content);

  useEffect(() => {
    const saver = setTimeout(() => {
      if (text !== block.content) {
        aiCreateService
          .updateAICreateTemplateBlock(block.project_id, block.id, {
            content: text,
          })
          .then(({ data }) => {
            upsertCachedAICreateTemplateBlock(data);
          });
      }
    }, 1000);

    return () => {
      clearTimeout(saver);
    };
  }, [text]);

  return (
    <Box className="block-content">
      <TextField
        variant="outlined"
        color="primary"
        value={text}
        size="small"
        placeholder={t("enter_text")}
        fullWidth
        multiline
        onChange={(e) => {
          setText(e.target.value);
        }}
      />
    </Box>
  );
};

export default TextBlockContentType;
