/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Skeleton,
  Tab,
  Tabs,
  Typography,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  DocumentCollection,
  DocumentReadResponse,
  Tag,
} from "models/api/response.types";
import CollectionSelector from "components/helpers/CollectionSelector";
import { useDocuments } from "api/documentService";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentOrganizationId } from "store/features/session/slice";
import { useDocumentCollections } from "api/documentCollectionService";
import DocumentFilter, {
  DocumentFilters,
  defaultDocumentFiltersData,
} from "components/Browser/Documents/DocumentFilter";
import {
  SearchType,
  selectIsSearch,
  selectTagsModeBrowse,
  setIsSearch,
} from "store/features/browser/slice";
import { ISortOrder, sortOptions } from "utils/documentSort";
import { tagsModes } from "utils/tagsModes";
import SortDocumentMenu from "components/Browser/Documents/SortDocumentMenu";
import {
  AutoSizer,
  ListRowProps,
  List as VirtualizedList,
} from "react-virtualized";
import { getDocumentStatus, isStub } from "utils/documentStatus";
import AIContextDialogDocument from "components/Dialogs/AddAIContextDialog/AIContextDialogDocument";
import { useNavigate } from "react-router-dom";
import routePaths from "routes/routePaths";
import { useTranslation } from "react-i18next";
import ContextSearchInput from "./ContextSearchInput";
import TableSourceDialog from "../TableSourceDialog/TableSourceDialog";

interface IDocumentResult {
  count: number;
  documents: DocumentReadResponse[];
}

const DialogWrapper = styled(Dialog)(({ theme }) => ({
  padding: "2rem 1rem",
  "& .skeleton": {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    "& .skeleton-secondary": {
      display: "flex",
      flexDirection: "column",
      gap: "0.5rem",
    },
  },
  "& .MuiPaper-root": {
    height: "100%",
    maxHeight: "800px",
    "& .MuiDialogContent-root": {
      display: "flex",
      flexDirection: "column",
      "& .collection-container": {
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
      },
      "& .document-container": {
        flex: 1,
        marginTop: "1rem",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        "& .tabs": {
          marginBottom: "1rem",
          minHeight: "30px",
          "& .MuiTabs-flexContainer": {
            justifyContent: "center",
            "& .tab": {
              textTransform: "none",
              flex: 1,
              padding: 0,
              minHeight: "30px",
            },
          },
        },
        "& .document-container-actions": {
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
          background: theme.background.light,
          padding: "0.5rem 1rem 0.5rem 0.5rem",
          display: "flex",
          gap: `${theme.spacing(1)}`,
          alignItems: "center",
          border: `1px solid ${theme.grey.light}`,
          "& .document-checkbox": {
            padding: "6px",
          },
        },
        "& .document-list-container": {
          flexGrow: 1,
          outline: "none",
          position: "relative",
          "& .ReactVirtualized__List": {
            outline: "none",
          },
          "& .no-rows": {
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "0.5rem",
          },
        },
      },
    },
  },
}));

const AddAIContextDialog: React.FC<{
  setOpen: (open: boolean) => void;
  addSelectedSources: (sources: DocumentReadResponse[]) => void;
}> = ({ setOpen, addSelectedSources }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const tagsModeBrowse = useSelector(selectTagsModeBrowse);
  const isSearch = useSelector(selectIsSearch);
  const { documents } = useDocuments(currentOrganizationId);
  const { collections, getCachedCollectionById } = useDocumentCollections(
    currentOrganizationId
  );
  const [tabValue, setTabValue] = useState<"available" | "unavailable">(
    "available"
  );
  const [documentSort, setDocumentSort] = useState<ISortOrder>({
    key: "date-created",
    order: "descending",
  });
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchTypeQuery, setSearchTypeQuery] = useState(SearchType.fulltext);
  const [searchedDocuments, setSearchedDocuments] = useState<
    DocumentReadResponse[] | undefined
  >(undefined);
  const [selectedCollectionId, setSelectedCollectionId] = useState<number>(-1);
  const [selectedDocuments, setSelectedDocuments] = useState<
    DocumentReadResponse[]
  >([]);
  const [tableDocumentToUpdate, setTableDocumentToUpdate] = useState<
    DocumentReadResponse | undefined
  >(undefined);
  // all filters options
  const [documentFilters, setDocumentFilters] = useState<DocumentFilters>(
    defaultDocumentFiltersData
  );
  // selected filters options
  const [selectedDocumentFilters, setSelectedDocumentFilters] =
    useState<DocumentFilters>(defaultDocumentFiltersData);
  const [documentsForFilter, setDocumentsForFilter] = useState<
    DocumentReadResponse[]
  >([]);
  const defaultRowHeight = 60;
  const defaultSearchRowHeight = 80;

  const onClose = () => {
    setOpen(false);
  };

  const filteredDocuments: DocumentReadResponse[] = useMemo(() => {
    if (documents) {
      const filterByCase = (documentList: DocumentReadResponse[]) => {
        let result: DocumentReadResponse[] = documentList;
        // 1 sort by collections
        if (selectedCollectionId > -1 && collections) {
          // deep search for child nodes
          const searchCollectionTree = (
            currentId: number
          ): DocumentCollection[] => {
            if (collections) {
              const [collection] = getCachedCollectionById(currentId);
              if (collection) {
                let array = [collection];
                const search = (c: DocumentCollection) => {
                  const childs = collections.filter(
                    (coll) => coll.parent_id === c.id
                  );
                  if (childs.length > 0) {
                    array = [...array, ...childs];
                    childs.map((coll) => search(coll));
                  }
                };
                search(collection);
                return array;
              }
              return [];
            }

            return [];
          };
          const collectionTree = searchCollectionTree(selectedCollectionId);
          let treeCollectionsDocumentIds: number[] = [];
          collectionTree.forEach((collection) => {
            treeCollectionsDocumentIds = [
              ...treeCollectionsDocumentIds,
              ...collection.document_ids,
            ];
          });

          result = result.filter((item) =>
            treeCollectionsDocumentIds.includes(item.id)
          );
        }
        // 2. sort by sortOptions
        result = result.sort((a, b) =>
          sortOptions[documentSort.key].compare(a, b, documentSort.order)
        );
        return result;
      };

      if (searchedDocuments) {
        dispatch(setIsSearch(true));

        return filterByCase([...searchedDocuments]);
      }
      dispatch(setIsSearch(false));
      return filterByCase(documents);
    }
    return [];
  }, [
    searchedDocuments,
    documents,
    isSearching,
    documentSort,
    tagsModeBrowse,
    selectedCollectionId,
    collections,
  ]);

  const applyFilters = (docs: DocumentReadResponse[]) => {
    const result = docs
      .filter((item) =>
        selectedDocumentFilters.titles.length > 0
          ? selectedDocumentFilters.titles.includes(item?.meta?.title)
          : `${item}`
      )
      .filter((item) => {
        if (selectedDocumentFilters.authors.length) {
          const authors = new Set<string>();
          if (Array.isArray(item.meta.author)) {
            item.meta.author.forEach((author) => authors.add(author));
          } else if (item.meta.author) {
            if (item.meta.author.includes(" and ")) {
              (item.meta.author as string)
                .split(" and ")
                .forEach((value) => value && authors.add(value));
            } else if (item.meta.author.includes(" AND ")) {
              (item.meta.author as string)
                .split(" AND ")
                .forEach((value) => value && authors.add(value));
            } else {
              authors.add(item.meta.author);
            }
          }
          return selectedDocumentFilters.authors.filter((author) => {
            return Array.from(authors).includes(author);
          }).length;
        }
        return item;
      })
      .filter(
        (item) =>
          selectedDocumentFilters.journals.length
            ? selectedDocumentFilters.journals.includes(item.meta.journal)
            : `${item}`,
        false
      )
      .filter((item) => {
        if (selectedDocumentFilters.years.length) {
          return selectedDocumentFilters.years.includes(
            parseInt(item.meta.year, 10)
          );
        }
        return item;
      })
      .filter((item: DocumentReadResponse) => {
        if (selectedDocumentFilters?.tags?.length) {
          const documentTagIds = new Set(item.tag_ids);
          const intersection = selectedDocumentFilters.tags.filter((tag: Tag) =>
            documentTagIds.has(tag.id)
          );
          if (tagsModeBrowse === tagsModes.or && intersection.length === 0) {
            return false;
          }
          if (
            tagsModeBrowse === tagsModes.and &&
            intersection.length < selectedDocumentFilters.tags.length
          ) {
            return false;
          }
          return true;
        }
        return item;
      });
    return result;
  };

  const availableDocuments: IDocumentResult = useMemo(() => {
    if (filteredDocuments && filteredDocuments.length > 0) {
      const result: IDocumentResult = {
        count: 0,
        documents: [],
      };
      result.documents = filteredDocuments.filter((doc) => {
        const { ready } = getDocumentStatus(doc);
        if (ready && doc.doctype !== "stub" && !doc.is_trash) {
          return true;
        }
        return false;
      });
      result.count = result.documents.length;
      if (tabValue === "available") {
        setDocumentsForFilter(result.documents);
      }
      result.documents = applyFilters(result.documents);
      return result;
    }
    return {
      count: 0,
      documents: [],
    };
  }, [filteredDocuments, tabValue, selectedDocumentFilters]);

  const unavailableDocuments: IDocumentResult = useMemo(() => {
    if (filteredDocuments && filteredDocuments.length > 0) {
      const result: IDocumentResult = {
        count: 0,
        documents: [],
      };
      result.documents = filteredDocuments.filter((doc) => {
        const { ready } = getDocumentStatus(doc);
        if (
          !ready ||
          isStub(doc) ||
          doc.status.embeddings !== 2 ||
          doc.is_trash
        ) {
          return true;
        }
        return false;
      });
      result.count = result.documents.length;
      if (tabValue === "unavailable") {
        setDocumentsForFilter(result.documents);
      }
      result.documents = applyFilters(result.documents);
      if (result.count === 0) {
        setTabValue("available");
      }
      return result;
    }
    setTabValue("available");
    return {
      count: 0,
      documents: [],
    };
  }, [filteredDocuments, tabValue, selectedDocumentFilters]);

  const multiSelectDocuments = (documentId: number, e: any) => {
    if (e.shiftKey && selectedDocuments) {
      let lastIndex;
      let firstIndex;
      const lastSelectedIndex = availableDocuments.documents
        .map((item) => item.id)
        .indexOf(documentId);
      let firstSelectedIndex = availableDocuments.documents
        .map((item) => item.id)
        .indexOf(selectedDocuments[0]?.id);
      if (firstSelectedIndex > lastSelectedIndex) {
        firstSelectedIndex = availableDocuments.documents
          .map((item) => item.id)
          .indexOf(selectedDocuments[selectedDocuments.length - 1]?.id);
        firstIndex = lastSelectedIndex;
        lastIndex = firstSelectedIndex;
      } else {
        firstIndex = firstSelectedIndex;
        lastIndex = lastSelectedIndex;
      }
      let newArray = availableDocuments.documents.slice(
        firstIndex,
        lastIndex + 1
      );
      // filter broken documents and doc in progress
      newArray = newArray.filter((doc) => {
        const { failed, in_progress, ocr_in_progress } = getDocumentStatus(doc);
        return !failed && !in_progress && !ocr_in_progress;
      });
      setSelectedDocuments(newArray);
    }
    if (e.metaKey || (e.ctrlKey && selectedDocuments)) {
      const indexDoc = availableDocuments.documents
        .map((item) => item.id)
        .indexOf(documentId);
      if (indexDoc !== -1) {
        const doc = availableDocuments.documents[indexDoc];
        setSelectedDocuments([
          ...(selectedDocuments as DocumentReadResponse[]),
          doc,
        ]);
      }
    }
  };

  // render rows from react-virtualized
  const rowRenderer = ({ index, key, style }: ListRowProps) => {
    return (
      <AIContextDialogDocument
        key={key}
        style={style}
        searchQuery={searchedDocuments ? searchQuery : ""}
        searchTypeQuery={searchTypeQuery}
        document={
          tabValue === "available"
            ? availableDocuments.documents[index]
            : unavailableDocuments.documents[index]
        }
        selectedDocuments={selectedDocuments || []}
        setTableDocumentToUpdate={setTableDocumentToUpdate}
        setSelectedDocuments={(docs: DocumentReadResponse[]) => {
          setSelectedDocuments(docs);
        }}
        multiSelectDocuments={multiSelectDocuments}
        showErrorIfExist={tabValue === "unavailable"}
        canSelect={tabValue === "available"}
      />
    );
  };

  return (
    <>
      <DialogWrapper open onClose={onClose} fullWidth maxWidth="md">
        <DialogTitle>
          {t("add_source_document")}
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {documents ? (
          <DialogContent>
            <ContextSearchInput
              setSearching={setIsSearching}
              searching={isSearching}
              setSearchedDocuments={(docs) => {
                setSearchedDocuments(docs);
                setSelectedDocuments([]);
              }}
              setSearchQuery={setSearchQuery}
              setSearchTypeQuery={setSearchTypeQuery}
              setSelectedCollectionId={setSelectedCollectionId}
              searchQuery={searchQuery}
              searchTypeQuery={searchTypeQuery}
            />
            {!searchedDocuments && (
              <Box className="collection-container">
                <Typography variant="body2">
                  {t("collections_colon")}
                </Typography>
                <CollectionSelector
                  includeDefaultCollections
                  size="small"
                  currentParentId={selectedCollectionId}
                  collectionToUse={(id) => {
                    setSelectedDocuments([]);
                    setSelectedCollectionId(id || -1);
                  }}
                />
              </Box>
            )}
            <Box className="document-container">
              {unavailableDocuments.count > 0 && (
                <Tabs
                  className="tabs"
                  value={tabValue}
                  onChange={(_e, value) => {
                    // we need to clear filters
                    setSelectedDocumentFilters(defaultDocumentFiltersData);
                    setDocumentFilters(defaultDocumentFiltersData);
                    setSelectedDocuments([]);
                    setTabValue(value);
                  }}
                  aria-label="documents to show"
                >
                  <Tab
                    className="tab"
                    value="available"
                    label={t("available_documents", {
                      count: availableDocuments.count,
                    })}
                  />
                  <Tab
                    className="tab"
                    value="unavailable"
                    label={t("unavailable_documents", {
                      count: unavailableDocuments.count,
                    })}
                  />
                </Tabs>
              )}
              <Box className="document-container-actions">
                {tabValue === "available" && (
                  <Checkbox
                    color="primary"
                    className="document-checkbox"
                    size="small"
                    disabled={availableDocuments.documents.length === 0}
                    checked={selectedDocuments.length > 0}
                    indeterminate={
                      selectedDocuments.length > 0 &&
                      selectedDocuments.length <
                        availableDocuments.documents.length
                    }
                    onChange={() => {
                      if (selectedDocuments.length > 0) {
                        setSelectedDocuments([]);
                      } else {
                        setSelectedDocuments(availableDocuments.documents);
                      }
                    }}
                  />
                )}
                <SortDocumentMenu
                  documentSort={documentSort}
                  setSortValue={setDocumentSort}
                  searchEnabled={!!searchedDocuments}
                />
                <DocumentFilter
                  selectedFilters={selectedDocumentFilters}
                  filteredDocuments={documentsForFilter}
                  filters={documentFilters}
                  setFilters={setDocumentFilters}
                  setSelectedDocumentFilters={setSelectedDocumentFilters}
                  setSelectedDocuments={setSelectedDocuments}
                />
              </Box>
              <Box className="document-list-container">
                <AutoSizer>
                  {({ width, height }) => (
                    <VirtualizedList
                      height={height}
                      overscanRowCount={15}
                      noRowsRenderer={() => {
                        if (documents.length > 0) {
                          return (
                            <Box className="no-rows">
                              <Typography variant="h6" color="textSecondary">
                                {t("no_documents_found")}
                              </Typography>
                            </Box>
                          );
                        }
                        return (
                          <Box className="no-rows">
                            <Typography variant="h6" color="textSecondary">
                              {t("no_items_in_workspace")}
                            </Typography>
                            <Typography
                              variant="body1"
                              color="textSecondary"
                              fontWeight={400}
                            >
                              {t("start_adding_documents")}
                            </Typography>
                            <Button
                              variant="contained"
                              color="primary"
                              size="medium"
                              onClick={() => {
                                navigate(routePaths.add);
                              }}
                            >
                              {t("add_documents")}
                            </Button>
                          </Box>
                        );
                      }}
                      rowCount={
                        tabValue === "available"
                          ? availableDocuments.documents.length
                          : unavailableDocuments.documents.length
                      }
                      rowHeight={
                        isSearch && searchTypeQuery === SearchType.fulltext
                          ? defaultSearchRowHeight
                          : defaultRowHeight
                      }
                      rowRenderer={rowRenderer}
                      width={width}
                    />
                  )}
                </AutoSizer>
              </Box>
            </Box>
          </DialogContent>
        ) : (
          <DialogContent className="skeleton">
            <Skeleton variant="rounded" width="100%" height={36} />
            <Skeleton variant="rounded" width="100%" height={36} />
            <Box className="skeleton-secondary">
              <Skeleton variant="rounded" width="100%" height={50} />
              <Skeleton variant="rounded" width="100%" height={58} />
              <Skeleton variant="rounded" width="100%" height={58} />
              <Skeleton variant="rounded" width="100%" height={58} />
              <Skeleton variant="rounded" width="100%" height={58} />
              <Skeleton variant="rounded" width="100%" height={58} />
              <Skeleton variant="rounded" width="100%" height={58} />
              <Skeleton variant="rounded" width="100%" height={58} />
            </Box>
          </DialogContent>
        )}
        <DialogActions>
          <Button size="medium" onClick={onClose}>
            {t("cancel")}
          </Button>
          <Button
            color="primary"
            variant="contained"
            size="medium"
            disabled={selectedDocuments.length === 0}
            onClick={() => {
              addSelectedSources(selectedDocuments);
            }}
          >
            {t("add_as_a_source_count", {
              doc_count:
                selectedDocuments.length > 0
                  ? `(${selectedDocuments.length})`
                  : "",
            })}
          </Button>
        </DialogActions>
      </DialogWrapper>
      {tableDocumentToUpdate && (
        <TableSourceDialog
          document={tableDocumentToUpdate}
          close={() => {
            setTableDocumentToUpdate(undefined);
          }}
        />
      )}
    </>
  );
};

export default AddAIContextDialog;
