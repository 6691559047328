/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  ClickAwayListener,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popper,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FlipToBack from "@mui/icons-material/FlipToBack";
import { useDocumentCollections } from "api/documentCollectionService";
import {
  getSelectedCollectionId,
  setSelectedCollectionId,
} from "store/features/browser/slice";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { TreeItem, TreeItemProps, TreeView } from "@mui/lab";
import { DocumentCollection } from "models/api/response.types";
import clsx from "clsx";
import {
  searchChildCollectionTree,
  searchParentCollectionTreeName,
} from "utils/collections";
import {
  selectCurrentOrganizationId,
  selectUser,
} from "store/features/session/slice";
import { DocumentsContext } from "pages/Documents";
import { isGuest } from "models/components/Permissions.models";
import CollectionDialog from "components/Dialogs/CollectionDialog";
import { useUsers } from "api/userService";
import { useOrganizations } from "api/organizationService";
import RestrictionPopover from "components/Dialogs/RestrictionPopover";
import { useDragLayer } from "react-dnd";
import { isCollectionLimitExceeded } from "utils/plans";
import ConfirmationDialog from "components/Dialogs/ConfirmationDialog";
import { useTranslation } from "react-i18next";
import CollectionTreeItem from "./CollectionTreeItem";

type IStateUpdate = "collection" | "trash" | "no-collection" | "default";

const Container = styled(Box)(({ theme }) => ({
  minWidth: "350px",
  background: theme.background.light,
  border: `1px solid ${theme.grey.light}`,
  padding: "5px 10px",
  display: "flex",
  justifyContent: "space-between",
  flex: 0.3,
  alignItems: "center",
  borderRadius: "4px",
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    flex: 1,
  },
  "&:hover": {
    border: `1px solid ${theme.grey.main}`,
  },
  "&.selected": {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  "& .skeleton": {
    width: "100%",
    fontSize: "14px",
  },
  "@keyframes dragging": {
    "0%": {
      boxShadow: `0 0 0 0.5px ${theme.palette.primary.main}`,
    },
    "50%": {
      boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
    },
    "100%": {
      boxShadow: `0 0 0 0.5px ${theme.palette.primary.main}`,
    },
  },
}));

const CustomTreeItem = (props: TreeItemProps) => (
  <TreeItem ContentComponent={CollectionTreeItem} {...props} />
);

const ToolbarCollectionSelector: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    trashMode,
    setTrashMode,
    noCollectionMode,
    setNoCollectionMode,
    selectedDocuments,
    setSelectedDocuments,
    documentToShow,
    setDocumentToShow,
  } = useContext(DocumentsContext);
  const user = useSelector(selectUser);
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const selectedCollectionId = useSelector(getSelectedCollectionId);
  const { users } = useUsers(currentOrganizationId);
  const {
    defaultCollections,
    customCollections,
    getCachedCollectionById,
    removeCollectionMutation,
  } = useDocumentCollections(currentOrganizationId);
  const { getCachedOrganizationById } = useOrganizations(user?.id);
  const customInputRef = useRef<HTMLDivElement>(null);
  const [editCollectionId, setEditCollectionId] = useState<number>(-1);
  const [openCollectionDialog, setOpenCollectionDialog] =
    useState<boolean>(false);
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] =
    useState<boolean>(false);
  const [collectionToDelete, setCollectionToDelete] = useState<
    DocumentCollection | undefined
  >(undefined);
  const [currentOrganization] = getCachedOrganizationById(
    currentOrganizationId || 0
  );
  const currentUserRole = users?.find((u) => u.id === user?.id)?.role;
  const [collectionExpanded, setCollectionExpanded] = useState<string[]>([]);
  const [menuOpen, setMenuOpen] = useState<null | HTMLElement>(null);
  const [collectionExceededPopover, setCollectionExceededPopover] =
    useState<Element | null>(null);
  const collections =
    defaultCollections && customCollections
      ? [...defaultCollections, ...customCollections]
      : undefined;
  // unlimited collections are only comes with paid tier
  const isCollectionsExceeded = isCollectionLimitExceeded(
    currentOrganization?.meta?.plans || [],
    customCollections?.length || 0
  );
  const [collection] = getCachedCollectionById(selectedCollectionId || 0);

  const { isDragging, currentOffset } = useDragLayer((monitor) => ({
    isDragging: monitor.isDragging(),
    currentOffset: monitor.getClientOffset(),
  }));

  // trigger menu open on dragging
  useEffect(() => {
    if (isDragging && customInputRef?.current && currentOffset && !menuOpen) {
      const minY = customInputRef.current.offsetTop;
      const maxY = customInputRef.current.offsetHeight + minY;
      const minX = customInputRef.current.offsetLeft;
      const maxX = customInputRef.current.offsetWidth + minX;
      if (
        currentOffset.x >= minX &&
        currentOffset.x <= maxX &&
        currentOffset.y >= minY &&
        currentOffset.y <= maxY
      ) {
        customInputRef.current.click();
      }
    }
  }, [currentOffset]);

  useEffect(() => {
    setTrashMode(false);
    setNoCollectionMode(false);
  }, [currentOrganizationId]);

  const triggerStateUpdate = (action: IStateUpdate, itemId?: number) => {
    if (action === "trash") {
      setTrashMode(true);
    } else {
      setTrashMode(false);
    }

    if (action === "no-collection") {
      setNoCollectionMode(true);
    } else {
      setNoCollectionMode(false);
    }

    // deselect collection on each action except collection
    if (action !== "collection") {
      dispatch(setSelectedCollectionId(undefined));
    } else if (itemId) {
      dispatch(setSelectedCollectionId(itemId));
    }

    // deselect documents on each state update
    if (selectedDocuments.length > 0) {
      setSelectedDocuments([]);
    }
    if (documentToShow) {
      setDocumentToShow(undefined);
    }
    setMenuOpen(null);
  };

  const triggerCollectionUpdate = (id: number) => {
    setEditCollectionId(id);
    setOpenCollectionDialog(true);
  };

  const triggerCollectionDeletion = (id: number) => {
    const [deleteCollection] = getCachedCollectionById(id);
    if (deleteCollection) {
      setCollectionToDelete(deleteCollection);
      setShowDeleteConfirmationDialog(true);
    }
  };

  const expandCollection = (nodeId: string) => {
    if (collectionExpanded.includes(nodeId)) {
      setCollectionExpanded(
        collectionExpanded.filter((node: string) => node !== nodeId)
      );
    } else {
      setCollectionExpanded([...collectionExpanded, nodeId]);
    }
  };

  const renderTreeItem = (item: DocumentCollection, lvl: number) => {
    if (collections) {
      const children = collections.filter((coll) => {
        return coll.parent_id === item.id;
      });

      const generateSubLogic = () => {
        return children.map((child) => renderTreeItem(child, lvl + 1));
      };

      if (item.parent_id > 0 && lvl === 0) {
        return null;
      }

      return (
        <CustomTreeItem
          key={item.id}
          nodeId={item.id.toString()}
          ContentProps={
            {
              setMenuOpen,
              triggerCollectionUpdate,
              triggerCollectionDeletion,
              triggerStateUpdate,
              expandCollection,
              collectionLabel: item.name,
              selectedCollection: collection,
            } as any
          }
        >
          {generateSubLogic()}
        </CustomTreeItem>
      );
    }
    return null;
  };

  const title = useMemo(() => {
    if (collection && collections) {
      const path = searchParentCollectionTreeName(collection, collections);
      return `${path.join(" > ")}`;
    }

    if (trashMode) {
      return t("trash");
    }

    if (noCollectionMode) {
      return t("documents_without_collections");
    }

    return t("all_documents");
  }, [collection, collections]);

  return (
    <>
      <Container
        className={clsx("collection-selector", {
          selected: menuOpen,
        })}
        ref={customInputRef}
        onClick={(e) => {
          setMenuOpen(e.currentTarget);
        }}
        sx={
          isDragging && !menuOpen
            ? {
                animation: "dragging 2s linear infinite",
              }
            : {}
        }
      >
        <Typography
          variant="body2"
          color="textPrimary"
          sx={{
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          {title}
        </Typography>
        <KeyboardArrowDownIcon
          color="action"
          sx={{
            marginLeft: "0.5rem",
            ...(menuOpen && {
              transform: "rotate(180deg)",
            }),
          }}
        />
      </Container>
      {menuOpen && (
        <ClickAwayListener onClickAway={() => setMenuOpen(null)}>
          <Popper
            open={!!menuOpen}
            anchorEl={menuOpen}
            placement="bottom-start"
            sx={{
              padding: "0.5rem 0",
              borderRadius: "4px",
              border: `1px solid #E9E9E9`,
              boxShadow: `0 0.5rem 1rem rgba(149, 157, 165, 0.2)`,
              maxHeight: "300px",
              minWidth: "350px",
              width: customInputRef.current
                ? `${customInputRef.current.offsetWidth}px`
                : "500px",
              zIndex: 1000,
              backgroundColor: theme.background.light,
              overflow: "auto",
            }}
          >
            <TreeView
              defaultCollapseIcon={<ExpandMoreIcon color="primary" />}
              defaultExpandIcon={<ChevronRightIcon color="primary" />}
              expanded={collectionExpanded}
            >
              {!isGuest(currentUserRole) && (
                <ListItemButton
                  sx={{
                    padding: "6px 16px",
                    height: "32px",
                    background: theme.background.secondary,
                  }}
                  disabled={isDragging}
                  onClick={() => {
                    if (isCollectionsExceeded) {
                      setCollectionExceededPopover(customInputRef.current);
                      setMenuOpen(null);
                    } else {
                      setOpenCollectionDialog(true);
                      setMenuOpen(null);
                    }
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      marginRight: "1.2rem",
                    }}
                  >
                    <AddIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant="body2">
                      {t("add_new_collection")}
                    </Typography>
                  </ListItemText>
                </ListItemButton>
              )}
              <ListItemButton
                selected={
                  !selectedCollectionId && !trashMode && !noCollectionMode
                }
                disabled={isDragging}
                sx={{
                  padding: "6px 16px",
                  height: "32px",
                  "&.Mui-selected": {
                    background: theme.grey.light,
                  },
                }}
                onClick={() => {
                  triggerStateUpdate("default");
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    marginRight: "1.2rem",
                  }}
                >
                  <AutoStoriesOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="body2">{t("all_documents")}</Typography>
                </ListItemText>
              </ListItemButton>
              {collections?.map((item) => renderTreeItem(item, 0))}
              <ListItemButton
                selected={noCollectionMode}
                disabled={isDragging}
                sx={{
                  padding: "6px 16px",
                  height: "32px",
                  "&.Mui-selected": {
                    background: theme.grey.light,
                  },
                }}
                onClick={() => {
                  triggerStateUpdate("no-collection");
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    marginRight: "1.2rem",
                  }}
                >
                  <FlipToBack fontSize="small" />
                </ListItemIcon>
                <Typography variant="body2">
                  {t("documents_without_collections")}
                </Typography>
              </ListItemButton>
              {!isGuest(currentUserRole) && (
                <ListItemButton
                  disabled={isDragging}
                  selected={trashMode}
                  sx={{
                    padding: "6px 16px",
                    height: "32px",
                    "&.Mui-selected": {
                      background: theme.grey.light,
                    },
                  }}
                  onClick={() => {
                    triggerStateUpdate("trash");
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      marginRight: "1.2rem",
                    }}
                  >
                    <DeleteOutlineOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant="body2">{t("trash")}</Typography>
                  </ListItemText>
                </ListItemButton>
              )}
            </TreeView>
          </Popper>
        </ClickAwayListener>
      )}
      {collectionExceededPopover && (
        <RestrictionPopover
          organizationId={currentOrganizationId || 0}
          accessMessage={t("add_more_collections")}
          upgradeMessage="add"
          planTier={400}
          anchor={collectionExceededPopover}
          onClose={() => {
            setCollectionExceededPopover(null);
          }}
        />
      )}
      {openCollectionDialog && (
        <CollectionDialog
          editCollectionId={editCollectionId}
          setOpen={() => {
            setOpenCollectionDialog(false);
            setEditCollectionId(-1);
          }}
        />
      )}
      <ConfirmationDialog
        show={showDeleteConfirmationDialog}
        title={t("delete_collection", {
          collectionName: collectionToDelete?.name,
        })}
        description={t("delete_collection_warning")}
        buttonText={t("delete")}
        sensitive
        onClose={(confirm?: boolean) => {
          setShowDeleteConfirmationDialog(false);
          if (confirm && collectionToDelete) {
            setTimeout(() => {
              const treeCollection = searchChildCollectionTree(
                collectionToDelete.id,
                collections
              );
              removeCollectionMutation.mutate({
                collection_ids: [collectionToDelete.id],
              });
              if (treeCollection.includes(collection?.id || 0)) {
                dispatch(setSelectedCollectionId(undefined));
              }
              setCollectionToDelete(undefined);
            }, 300);
          }
        }}
      />
    </>
  );
};

export default ToolbarCollectionSelector;
