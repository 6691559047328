import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import { DocumentsContext } from "pages/Documents";
import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";
import CopyAllOutlinedIcon from "@mui/icons-material/CopyAllOutlined";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import LibraryAddCheckOutlinedIcon from "@mui/icons-material/LibraryAddCheckOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import RestoreFromTrashOutlinedIcon from "@mui/icons-material/RestoreFromTrashOutlined";
import RemoveDoneOutlinedIcon from "@mui/icons-material/RemoveDoneOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SavedSearchOutlinedIcon from "@mui/icons-material/SavedSearchOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CloseIcon from "@mui/icons-material/Close";
import downloadDocuments from "utils/downloadDocuments";
import { searchChildCollectionTree } from "utils/collections";
import { isGuest } from "models/components/Permissions.models";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentOrganizationId,
  selectUser,
} from "store/features/session/slice";
import documentService, { useDocuments } from "api/documentService";
import { useDocumentCollections } from "api/documentCollectionService";
import {
  SearchType,
  getSelectedCollectionId,
  selectIsSearch,
  selectSearchQuery,
  selectSearchTypeQuery,
} from "store/features/browser/slice";
import {
  DocumentReadResponse,
  PageLayoutResponse,
} from "models/api/response.types";
import { useUsers } from "api/userService";
import DocumentPropertyTab from "components/Tabs/DocumentPropertyTab/DocumentPropertyTab";
import { useOrganizations } from "api/organizationService";
import { getDocumentStatus, isStub, isTable } from "utils/documentStatus";
import LookupTab from "components/Tabs/LookupTab/LookupTab";
import { companyFeatures } from "company-config";
import AiTab from "components/Tabs/AiTab/AiTab";
import ConfirmationDialog from "components/Dialogs/ConfirmationDialog";
import { useTranslation } from "react-i18next";

type View = "properties" | "search" | "ai-tab";

const Wrapper = styled(Box)(({ theme }) => ({
  background: theme.background.secondary,
  padding: "1rem 0.5rem",
  border: `1px solid ${theme.grey.light}`,
  borderTop: "0",
  height: "100%",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  "& .header": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 0.5rem",
    marginBottom: "0.5rem",
    "& .header-box": {
      display: "flex",
      alignItems: "center",
      gap: "0.5rem",
    },
  },
}));

const SideBarProperties: React.FC<{
  document: DocumentReadResponse;
  onClose: () => void;
}> = ({ document, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const selectedCollectionId = useSelector(getSelectedCollectionId);
  const isSearch = useSelector(selectIsSearch);
  const searchQuery = useSelector(selectSearchQuery);
  const user = useSelector(selectUser);
  const {
    triggerSpecialInputFile,
    selectedDocuments,
    setSelectedDocuments,
    setOpenCopyDocumentsDialog,
    setOpenAddToCollectionDialog,
    loadFile,
    setOpenBibMergeDialog,
    setOpenBibTeXExportDialog,
    trashMode,
  } = useContext(DocumentsContext);
  const {
    documents,
    moveDocumentsToTrashMutation,
    restoreDocumentsFromTrashMutation,
    deleteMultipleDocumentsMutation,
  } = useDocuments(currentOrganizationId);
  const { users } = useUsers(currentOrganizationId);
  const { updateCollectionMutation, collections, getCachedCollectionById } =
    useDocumentCollections(currentOrganizationId);
  const searchTypeQuery = useSelector(selectSearchTypeQuery);
  const { getCachedOrganizationById } = useOrganizations(user?.id);
  const [currentOrganization] = getCachedOrganizationById(
    currentOrganizationId || 0
  );
  const documentStatus = getDocumentStatus(document);
  const [menuAnchorEl, setMenuAnchorEl] = useState<Element | null>(null);
  const [confirmDocumentToTrash, setConfirmDocumentToTrash] =
    useState<boolean>(false);
  const currentUserRole = users?.find((u) => u.id === user?.id)?.role;
  const [pageLayouts, setPageLayouts] = useState<{
    documentId: number | undefined;
    pages: PageLayoutResponse[];
  }>({
    documentId: undefined,
    pages: [],
  });
  const isDocViewerEnabled =
    !isGuest(currentUserRole) ||
    companyFeatures.app.guests.enableDocumentViewer;
  const enableDocumentActions =
    companyFeatures.app.guests.enableDocumentActions ||
    !isGuest(currentUserRole);
  const enableLookUpTab =
    (!companyFeatures.app.guests.enableLookUpTab ||
      !isGuest(currentUserRole)) &&
    searchTypeQuery === SearchType.fulltext;
  const [currentView, setCurrentView] = useState<View>(
    isSearch && enableLookUpTab ? "search" : "properties"
  );

  useEffect(() => {
    if (
      currentView === "search" &&
      isSearch &&
      documentStatus.can_be_viewed &&
      enableLookUpTab
    ) {
      documentService.fetchDocumentLayout(document.id).then((response) => {
        setPageLayouts({
          documentId: document.id,
          pages: response.data,
        });
      });
    }
    if (currentView === "ai-tab" && isStub(document)) {
      setCurrentView("properties");
    }
    if (
      (!isSearch && currentView === "search") ||
      (isSearch && currentView === "search" && !documentStatus.can_be_viewed)
    ) {
      setCurrentView("properties");
    }
  }, [currentView, isSearch, document]);

  const hideSelectedDocument = () => {
    onClose();
    if (selectedDocuments.length > 0) {
      setSelectedDocuments(
        selectedDocuments.filter((doc) => doc.id !== document.id)
      );
    }
  };

  const menuOptions = [
    {
      index: 0,
      icon: <CopyAllOutlinedIcon fontSize="small" />,
      label: t("copy_to"),
      onClick: () => {
        setOpenCopyDocumentsDialog({
          open: true,
          type: "document_to_show",
        });
      },
    },
    {
      index: 1,
      icon: <LibraryAddCheckOutlinedIcon fontSize="small" />,
      label: t("add_to_collection"),
      onClick: () => {
        setOpenAddToCollectionDialog({
          open: true,
          type: "document_to_show",
        });
      },
    },
    {
      index: 2,
      icon: <RemoveDoneOutlinedIcon fontSize="small" />,
      label: t("remove_from_collection"),
      onClick: () => {
        if (selectedCollectionId) {
          const collectionTree = searchChildCollectionTree(
            selectedCollectionId,
            collections
          );
          collectionTree.forEach((collectionId: number) => {
            const [collection] = getCachedCollectionById(collectionId);
            if (collection) {
              updateCollectionMutation.mutate({
                collections: [
                  {
                    id: collection.id,
                    document_ids: collection.document_ids.filter(
                      (docId) => document.id !== docId
                    ),
                  },
                ],
              });
            }
          });
        }
        hideSelectedDocument();
      },
    },
    {
      index: 3,
      icon: <SyncOutlinedIcon fontSize="small" />,
      label: t("update_with_doi_pmid_arxivid"),
      onClick: () => {
        setOpenBibMergeDialog({
          open: true,
          type: "document_to_show",
        });
      },
    },
    {
      index: 4,
      icon: <ExitToAppOutlinedIcon fontSize="small" />,
      label: t("export_bibtex"),
      onClick: () => {
        setOpenBibTeXExportDialog({
          open: true,
          type: "document_to_show",
        });
      },
    },
    {
      index: 5,
      icon: <DownloadOutlinedIcon fontSize="small" />,
      label: t("download"),
      onClick: () => {
        downloadDocuments({
          selectedDocumentIds: [document.id],
          documentListOfCurrentOrganization: documents || [],
          dispatch,
          loadFile,
        });
      },
    },
    {
      index: 6,
      icon: <DeleteOutlineOutlinedIcon fontSize="small" />,
      label: t("move_to_trash"),
      onClick: () => {
        setConfirmDocumentToTrash(true);
      },
    },
    {
      index: 7,
      icon: <RestoreFromTrashOutlinedIcon fontSize="small" />,
      label: t("restore"),
      onClick: () => {
        restoreDocumentsFromTrashMutation.mutate([document.id]);
        hideSelectedDocument();
      },
    },
    {
      index: 8,
      icon: <DeleteForeverOutlinedIcon fontSize="small" />,
      label: t("delete"),
      onClick: () => {
        deleteMultipleDocumentsMutation.mutate([document.id]);
        hideSelectedDocument();
      },
    },
  ];

  const menuItems = menuOptions.filter((option) => {
    // options that user guest can't see
    const guestExclude = [0, 1, 2, 3, 5, 6, 7, 8];
    const tableExclude = [3, 4];

    if (isGuest(currentUserRole) && guestExclude.includes(option.index)) {
      return false;
    }

    if (isTable(document) && tableExclude.includes(option.index)) {
      return false;
    }

    if (isStub(document) && option.index === 5) {
      return false;
    }

    if (trashMode && (option.index === 6 || option.index === 1)) {
      return false;
    }

    if (!trashMode && (option.index === 7 || option.index === 8)) {
      return false;
    }

    if (option.index === 2 && !selectedCollectionId) {
      return false;
    }

    return true;
  });

  return (
    <>
      {currentView === "ai-tab" ? (
        <AiTab
          document={document}
          browse
          onClose={() => {
            setCurrentView("properties");
          }}
        />
      ) : (
        <Wrapper>
          <Box className="header">
            <Typography variant="body1" fontWeight={500}>
              {currentView === "properties" && t("properties")}
              {currentView === "search" && t("search_results")}
            </Typography>
            <Box className="header-box">
              {currentView !== "properties" && (
                <Tooltip
                  enterDelay={500}
                  title={t("see_document_properties")}
                  placement="top"
                >
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={() => {
                      setCurrentView("properties");
                    }}
                  >
                    <ArticleOutlinedIcon />
                  </IconButton>
                </Tooltip>
              )}
              {currentView !== "search" &&
                enableLookUpTab &&
                isSearch &&
                documentStatus.can_be_viewed && (
                  <Tooltip
                    enterDelay={500}
                    title={t("see_search_results")}
                    placement="top"
                  >
                    <IconButton
                      color="primary"
                      size="small"
                      onClick={() => {
                        setCurrentView("search");
                      }}
                    >
                      <SavedSearchOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                )}
              {!isStub(document) &&
                isDocViewerEnabled &&
                !document.is_trash && (
                  <Button
                    className="ask-ai-button"
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => {
                      setCurrentView("ai-tab");
                    }}
                  >
                    {t("ask_ai")}
                  </Button>
                )}
              {enableDocumentActions && (
                <Tooltip
                  enterDelay={500}
                  placement="top"
                  title={t("document_options")}
                >
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      setMenuAnchorEl(e.currentTarget);
                    }}
                  >
                    <MoreVertIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
              <IconButton
                size="small"
                onClick={() => {
                  onClose();
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          </Box>
          {currentView === "properties" && (
            <DocumentPropertyTab
              organization={currentOrganization}
              document={document}
              triggerSpecialInputFile={triggerSpecialInputFile}
            />
          )}
          {currentView === "search" && (
            <LookupTab
              document={selectedDocuments[0]}
              view="browse"
              pages={pageLayouts.pages}
              searchedQuery={searchQuery}
              setSearchedQuery={() => {}}
              setJumpLocation={() => {}}
              setSearchHighlightRanges={() => {}}
            />
          )}
          <Menu
            anchorEl={menuAnchorEl}
            open={!!menuAnchorEl}
            onClose={() => {
              setMenuAnchorEl(null);
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{
              zIndex: 1500,
            }}
          >
            {menuItems.map((option: any) => (
              <MenuItem
                key={option.index}
                onClick={() => {
                  option.onClick();
                  setMenuAnchorEl(null);
                }}
              >
                <ListItemIcon>{option.icon}</ListItemIcon>
                <ListItemText>
                  <Typography variant="body2">{option.label}</Typography>
                </ListItemText>
              </MenuItem>
            ))}
          </Menu>
        </Wrapper>
      )}
      <ConfirmationDialog
        show={confirmDocumentToTrash}
        title={t("move_document_to_trash")}
        description={t("document_to_trash_warning")}
        buttonText={t("move_to_trash")}
        sensitive
        onClose={(confirm?: boolean) => {
          setConfirmDocumentToTrash(false);
          if (confirm) {
            moveDocumentsToTrashMutation.mutate([document.id]);
            hideSelectedDocument();
          }
        }}
      />
    </>
  );
};

export default SideBarProperties;
