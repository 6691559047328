import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { UploadTask } from "utils/upload.helpers";
import { useTranslation } from "react-i18next";

const UploadManagerDialogPrompt: React.FC<{
  uploads: UploadTask[];
  onConfirm: () => void;
  onCancel: () => void;
}> = ({ uploads, onConfirm, onCancel }) => {
  const { t } = useTranslation();
  return (
    <Dialog open scroll="paper" fullWidth maxWidth="sm">
      <DialogTitle>
        {uploads.length > 0 ? "Uploading documents" : "Unsaved changes"}
      </DialogTitle>
      <DialogContent>
        {uploads.length > 0 ? (
          <Typography variant="body1">{t("upload_warning")}</Typography>
        ) : (
          <Typography variant="body1">{t("navigate_away_warning")}</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm} variant="text" color="primary">
          {t("discard_and_switch")}
        </Button>
        <Button onClick={onCancel} variant="contained" color="primary">
          {t("stay_and_continue")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadManagerDialogPrompt;
