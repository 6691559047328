/* eslint-disable no-restricted-globals */
/* eslint-disable react/no-array-index-key */
import React, { useState } from "react";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  IconButton,
  MenuItem,
  Select,
  SortDirection,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";
import { selectUser } from "store/features/session/slice";
import { Organization } from "models/api/response.types";
import { isGuest } from "models/components/Permissions.models";
import ConfirmationDialog from "components/Dialogs/ConfirmationDialog";
import { useTranslation } from "react-i18next";
import { getComparator, ITableRow, stableSort } from "./table-utils";

interface IHeadCell {
  id: string;
  label: string;
}

interface OrgUserTableProps {
  minWidth: number;
  organization: Organization;
  maxHeight: number;
  headCells: IHeadCell[];
  tableRows: ITableRow[];
  membersSeatsRemaining: number;
  guestsSeatsRemaining: number;
  updateUserRole: (id: number, role: string) => void;
  deleteRow: (id: number) => void;
}

const OrganizationUsersTableHead = (props: {
  order: "asc" | "desc";
  orderBy: string;
  onRequestSort: (event: any, property: any) => void;
  headCells: IHeadCell[];
}) => {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property: any) => (event: any) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={
              orderBy === headCell.id ? (order as SortDirection) : undefined
            }
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

// custom MuiTableComponent for use in different pages
// covers custom cells and custom sort
const OrganizationUsersTable: React.FC<OrgUserTableProps> = ({
  organization,
  membersSeatsRemaining,
  guestsSeatsRemaining,
  minWidth,
  headCells,
  tableRows,
  maxHeight,
  updateUserRole,
  deleteRow,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const currentUser = useSelector(selectUser);
  const [order, setOrder] = React.useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = React.useState(headCells[0].id);
  const [userToDelete, setUserToDelete] = useState<ITableRow | undefined>(
    undefined
  );
  const [confirmUserDeletion, setConfirmUserDeletion] =
    useState<boolean>(false);
  const [rowHovered, setRowHovered] = useState<number | undefined>(undefined);

  const handleRequestSort = (event: any, property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <>
      <TableContainer
        sx={{
          maxHeight,
          "& .MuiTable-root": {
            border: `1px solid ${theme.grey.light}`,
            "& .MuiTableCell-root": {
              borderBottom: `1px solid ${theme.grey.light}`,
            },
          },
        }}
      >
        <Table size="small" sx={{ minWidth, maxHeight }}>
          <OrganizationUsersTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={headCells}
          />
          <TableBody>
            {stableSort(tableRows, getComparator(order, orderBy)).map(
              (row: ITableRow, index: number) => {
                return (
                  <TableRow
                    hover
                    key={`${row[index]}+${index}`}
                    onMouseEnter={() => {
                      setRowHovered(index);
                    }}
                    onMouseLeave={() => {
                      setRowHovered(undefined);
                    }}
                  >
                    {Object.values(row).map(
                      (cell: string | number, key: number) => {
                        if (
                          headCells.some(
                            (c, idx) => c.id === "role" && idx === key
                          )
                        ) {
                          return (
                            <TableCell
                              sx={{
                                minWidth: "150px",
                              }}
                              component="th"
                              key={`${cell}+${key}+${index}`}
                            >
                              <Select
                                value={
                                  cell === "previousadmin" ||
                                  cell === "previousmember" ||
                                  cell === "guest"
                                    ? "guest"
                                    : cell
                                }
                                onChange={(e) => {
                                  updateUserRole(
                                    row.id as number,
                                    e.target.value as string
                                  );
                                }}
                                size="small"
                                color="primary"
                                disabled={
                                  organization.user_id === row.id ||
                                  row.id === currentUser?.id.toString()
                                }
                                sx={{
                                  width: "100%",
                                }}
                              >
                                <MenuItem
                                  disabled={
                                    membersSeatsRemaining === 0 &&
                                    isGuest(row.role as string)
                                  }
                                  value="admin"
                                >
                                  <Typography variant="body2">
                                    {t("admin")}
                                  </Typography>
                                </MenuItem>
                                <MenuItem
                                  disabled={
                                    membersSeatsRemaining === 0 &&
                                    isGuest(row.role as string)
                                  }
                                  value="member"
                                >
                                  <Typography variant="body2">
                                    {t("member")}
                                  </Typography>
                                </MenuItem>
                                <MenuItem
                                  value="guest"
                                  disabled={
                                    guestsSeatsRemaining === 0 &&
                                    !isGuest(row.role as string)
                                  }
                                >
                                  <Typography variant="body2">
                                    {t("guest")}
                                  </Typography>
                                </MenuItem>
                              </Select>
                            </TableCell>
                          );
                        }
                        return (
                          <TableCell
                            key={`${cell}+${key}+${index}`}
                            component="th"
                            sx={{
                              position: "relative",
                            }}
                          >
                            {/* disable - do not show users id */}
                            {key !== 4 && cell}
                            {rowHovered === index &&
                              organization.user_id !== row.id &&
                              row.id !== currentUser?.id.toString() &&
                              key === Object.keys(row).length - 1 && (
                                <IconButton
                                  onClick={() => {
                                    setUserToDelete(row);
                                    setConfirmUserDeletion(true);
                                  }}
                                  size="small"
                                  sx={{
                                    marginLeft: "16px",
                                    top: "0.6rem",
                                    position: "absolute",
                                    right: "2rem",
                                  }}
                                >
                                  <DeleteOutlineOutlinedIcon fontSize="small" />
                                </IconButton>
                              )}
                          </TableCell>
                        );
                      }
                    )}
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmationDialog
        show={confirmUserDeletion}
        title={t("delete_user_from_workspace", { user: userToDelete?.email })}
        description={t("delete_user_warning")}
        sensitive
        buttonText="Delete"
        onClose={(confirm?: boolean) => {
          setConfirmUserDeletion(false);
          if (confirm && userToDelete) {
            setTimeout(() => {
              deleteRow(userToDelete.id as number);
            }, 300);
          }
        }}
      />
    </>
  );
};

export default OrganizationUsersTable;
