/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import Joyride, { CallBackProps } from "react-joyride";
import useTelemetry, { telemetryAction } from "utils/useTelemetry";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setUser } from "store/features/session/slice";
import { authService } from "api";
import { isSidebarOpen, setSideBarOpen } from "store/features/browser/slice";
import { useTranslation } from "react-i18next";
import OnboardingTooltip from "./Tooltip";

const AiChatOnboarding: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const sideBarOpen = useSelector(isSidebarOpen);
  const user = useSelector(selectUser);
  const { logAction } = useTelemetry();
  const smScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const [isAddContainerRendered, setIsAddContainerRendered] = useState(false);

  const completeCurrentOnboarding = () => {
    if (user) {
      authService
        .updateUser(user.id, {
          meta: {
            ...user.meta,
            citeOnboarding: {
              ...user.meta.citeOnboarding,
              aiChatCompleted: new Date().toISOString(),
            },
          },
        })
        .then(({ data }) => {
          dispatch(setUser({ ...user, ...data }));
        })
        .catch((err) => err);
    }
  };

  useEffect(() => {
    const checkIfAddContainerRendered = () => {
      const addContainer = document.querySelector(".manage-sources");
      setIsAddContainerRendered(!!addContainer);
    };

    // Run the check on component mount and on every render
    checkIfAddContainerRendered();
  });

  const steps = [
    {
      target: ".manage-sources",
      placement: "top" as const,
      disableBeacon: true,
      disableOverlayClose: true,
      disableCloseOnEsc: true,
      content: (
        <Box
          className="onboarding-step"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Typography variant="h6">{t("manage_sources")}</Typography>
          <Typography variant="body1" color="textSecondary">
            {t("manage_sources_instruction")}
          </Typography>
        </Box>
      ),
    },
    {
      target: ".input-container",
      placement: "top" as const,
      disableBeacon: true,
      disableOverlayClose: true,
      disableCloseOnEsc: true,
      content: (
        <Box
          className="onboarding-step"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Typography variant="h6">{t("ask_question")}</Typography>
          <Typography variant="body1" color="textSecondary">
            {t("ask_question_instruction")}
          </Typography>
        </Box>
      ),
    },
  ];

  const stepCallback = (data: CallBackProps) => {
    const { action, lifecycle, index } = data;
    if (action === "start" && lifecycle === "init" && index === 0) {
      if (sideBarOpen && smScreen) {
        dispatch(setSideBarOpen(false));
      }
    }
    if (action === "close" && lifecycle === "complete") {
      if (index === 1) {
        logAction(telemetryAction.onboarding_flow, {
          method: "ai_chat_completed",
        });
        completeCurrentOnboarding();
      }
    }
  };

  if (isAddContainerRendered) {
    return (
      <Joyride
        run
        steps={steps}
        tooltipComponent={OnboardingTooltip}
        callback={stepCallback}
        floaterProps={{
          styles: {
            wrapper: {
              zIndex: 1501,
            },
          },
        }}
        styles={{
          options: {
            zIndex: 1500,
          },
        }}
      />
    );
  }
  return null;
};

export default AiChatOnboarding;
