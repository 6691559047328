/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useMemo, useState } from "react";
import {
  Alert,
  Box,
  Button,
  IconButton,
  Step,
  StepIconProps,
  StepLabel,
  Stepper,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import WestOutlinedIcon from "@mui/icons-material/WestOutlined";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { saveAs } from "file-saver";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { setSideBarOpen } from "store/features/browser/slice";
import { selectCurrentOrganizationId } from "store/features/session/slice";
import { useDocuments } from "api/documentService";
import { AICreateProject } from "models/api/response.types";
import LoadingOverlay from "components/helpers/LoadingOverlay";
import aiCreateService, {
  useAICreateQuestions,
  useAICreateTemplateBlocks,
} from "api/aiCreateService";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Document, Packer, Paragraph, TextRun } from "docx";
import { terminal_status_names } from "utils/aiHelpers";
import { useOrganizationUsage } from "api/organizationService";
import { useTranslation } from "react-i18next";
import ReferenceSelection from "./ReferenceSelection";
import ExtractedKnowledge from "./ExtractedKnowledge/ExtractedKnowledge";
import Write from "./Write/Write";

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 35,
  height: 35,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    background: theme.palette.primary.main,
  }),
  ...(ownerState.completed && {
    background: theme.palette.primary.main,
  }),
}));

const ColorlibStepIcon = (props: StepIconProps) => {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <LibraryBooksIcon fontSize="small" />,
    2: <SchoolOutlinedIcon fontSize="small" />,
    3: <CreateOutlinedIcon fontSize="small" />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
};

const Wrapper = styled(Box)(({ theme }) => ({
  overflowY: "auto",
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingLeft: "1rem",
  "& .header": {
    width: "100%",
    paddingTop: "1rem",
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    [theme.breakpoints.up("lg")]: {
      marginBottom: "2rem",
    },
    [theme.breakpoints.down("lg")]: {
      marginBottom: "1rem",
    },
    "& .stepper-container": {
      flex: 1,
      "& .MuiStepper-root": {
        maxWidth: "900px",
        margin: "0 auto",
      },
    },
  },
  "& .description-container": {
    width: "100%",
    maxWidth: "1500px",
    paddingRight: "1rem",
    marginBottom: "1rem",
  },
  "& .alert-banner": {
    margin: "2rem auto 0 auto",
    maxWidth: "900px",
  },
  "& .stepper-actions": {
    padding: "1rem",
    width: "100%",
    maxWidth: "1500px",
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    justifyContent: "center",
  },
}));

const AICreateSelectedProject: React.FC<{
  project: AICreateProject;
  goBack: () => void;
}> = ({ project, goBack }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const theme = useTheme();
  const dispatch = useDispatch();
  const smScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const [loading, setLoading] = useState<boolean>(true);
  const [activeStep, setActiveStep] = useState(0);
  const { documents } = useDocuments(currentOrganizationId);
  const { usageQueryKey } = useOrganizationUsage(currentOrganizationId);
  const { aiCreateQuestions, aiCreateQuestionsQueryKey } = useAICreateQuestions(
    project.id
  );
  const { aiCreateBlocks, aiCreateBlocksQueryKey } = useAICreateTemplateBlocks(
    project.id
  );

  const steps = [
    {
      label: t("review_sources"),
    },
    {
      label: t("extract_knowledge"),
    },
    {
      label: t("write"),
    },
  ];

  const pollingBlocks = useMemo(() => {
    return (
      aiCreateBlocks &&
      aiCreateBlocks.some(
        (block) =>
          !terminal_status_names.includes(block.response_task_status) &&
          block.block_type === "ai"
      )
    );
  }, [aiCreateBlocks]);

  const pollingQuestions = useMemo(() => {
    return (
      aiCreateQuestions &&
      aiCreateQuestions.some(
        (qa) => !terminal_status_names.includes(qa.response_task_status)
      )
    );
  }, [aiCreateQuestions]);

  // polling data , await to finish all tasks
  useEffect(() => {
    let intervalId: any;
    if (pollingBlocks) {
      intervalId = setInterval(() => {
        queryClient.invalidateQueries(aiCreateBlocksQueryKey);
      }, 1000);
    }
    if (pollingQuestions) {
      intervalId = setInterval(() => {
        queryClient.invalidateQueries(aiCreateQuestionsQueryKey);
      }, 1000);
    }
    return () => {
      queryClient.invalidateQueries(usageQueryKey);
      clearInterval(intervalId);
    };
  }, [pollingBlocks, pollingQuestions]);

  // initial check
  useEffect(() => {
    if (documents && aiCreateQuestions && loading) {
      setLoading(false);
    }
  }, [documents, aiCreateQuestions]);

  const { data: documentStatuses } = useQuery({
    queryKey: ["ai_create_document_qas_status", project.id],
    queryFn: () => {
      queryClient.invalidateQueries(aiCreateQuestionsQueryKey);
      return aiCreateService.fetchAICreateDocumentQAsStatusByProjectId(
        project.id
      );
    },
    refetchInterval: (data) => {
      if (data?.data) {
        const statuses = data.data;
        if (
          statuses.some(
            (status) =>
              !terminal_status_names.includes(
                status.generate_queries_task_status
              )
          )
        ) {
          return 5_000;
        }
      }
      return false;
    },
  });

  // content of all template blocks
  const aiCreateBlocksContentResult = useMemo(() => {
    if (aiCreateBlocks && aiCreateBlocks.length > 0) {
      const text: string[] = [];
      aiCreateBlocks.forEach((block) => {
        if (block.content) {
          text.push(block.content);
        }
      });
      return text.join("\n\n");
    }
    return "";
  }, [aiCreateBlocks]);

  const canShowNextStepButton = useMemo(() => {
    return (
      (activeStep === 0 && project.document_ids.length > 0) ||
      activeStep === 1 ||
      (activeStep === 2 && aiCreateBlocksContentResult.length > 0)
    );
  }, [activeStep, aiCreateQuestions, project, aiCreateBlocksContentResult]);

  const documentsToUse = useMemo(() => {
    if (documents) {
      return documents.filter((doc) => project.document_ids.includes(doc.id));
    }
    return [];
  }, [documents, project]);

  const exportWriteResult = async () => {
    const doc = new Document({
      sections: [
        {
          children: [
            new Paragraph({
              children: [new TextRun(aiCreateBlocksContentResult)],
            }),
          ],
        },
      ],
    });

    // Generate the docx file as a blob
    Packer.toBlob(doc).then((blob) => {
      // Use FileSaver to save the generated file
      saveAs(blob, `${project.title}.docx`);
    });
  };

  if (loading) {
    return <LoadingOverlay />;
  }

  return (
    <Wrapper>
      <Box className="header">
        {smScreen && (
          <IconButton
            className="sidebar-close-icon"
            size="medium"
            onClick={() => dispatch(setSideBarOpen(true))}
            color="primary"
          >
            <MenuOutlinedIcon fontSize="medium" color="action" />
          </IconButton>
        )}
        <Button
          className="back-button"
          size="small"
          startIcon={<ArrowBackOutlinedIcon fontSize="small" />}
          onClick={goBack}
        >
          {t("back_to_projects")}
        </Button>
        <Box className="stepper-container">
          <Stepper sx={{ flex: 1 }} activeStep={activeStep}>
            {steps.map((label) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              if (activeStep === 3) {
                stepProps.completed = false;
              }
              return (
                <Step key={label.label} {...stepProps}>
                  <StepLabel
                    {...labelProps}
                    StepIconComponent={ColorlibStepIcon}
                  >
                    {label.label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Box>
      </Box>
      {((activeStep === 0 && documentsToUse.length > 0) ||
        (activeStep === 1 && documentsToUse.length > 0) ||
        (activeStep === 2 && aiCreateBlocks && aiCreateBlocks.length > 0)) && (
        <Box className="description-container">
          <Alert severity="info">
            {activeStep === 0 && t("ai_relevant_references_info")}
            {activeStep === 1 && t("ai_interrogate_references_info")}
            {activeStep === 2 && t("build_template_info")}
          </Alert>
        </Box>
      )}
      {activeStep === 0 && (
        <ReferenceSelection
          projectId={project.id}
          documentSources={documentsToUse}
        />
      )}
      {activeStep === 1 && (
        <ExtractedKnowledge
          projectId={project.id}
          projectDocuments={documentsToUse}
          questions={aiCreateQuestions}
          docStatuses={documentStatuses?.data}
        />
      )}
      {activeStep === 2 && (
        <Write projectId={project.id} blocks={aiCreateBlocks} />
      )}
      <Box className="stepper-actions">
        {activeStep > 0 && (
          <Button
            color="primary"
            variant="outlined"
            size="large"
            startIcon={<WestOutlinedIcon fontSize="large" />}
            onClick={() => {
              setActiveStep(activeStep - 1);
            }}
          >
            {t("back")}
          </Button>
        )}
        <Button
          color="primary"
          variant="contained"
          size="large"
          disabled={!canShowNextStepButton}
          onClick={() => {
            if (activeStep === 2) {
              exportWriteResult();
            } else {
              setActiveStep(activeStep + 1);
            }
          }}
          endIcon={
            activeStep === 2 ? (
              <FileUploadOutlinedIcon fontSize="large" />
            ) : (
              <EastOutlinedIcon fontSize="large" />
            )
          }
        >
          {activeStep === 2 ? t("export") : t("next")}
        </Button>
      </Box>
    </Wrapper>
  );
};

export default AICreateSelectedProject;
