import { Alert } from "store/features/general/slice";
import i18n from "../i18n";

interface IError {
  need_to_redirect: boolean;
  need_to_reset_organization: boolean;
  message: string;
}

export const ErrorMessages = {
  NETWORK_ERROR_MESSAGE: i18n.t("cannot_reach_server"),
};

// axiosErrors
const UNEXPECTED_ERROR = i18n.t("something_went_wrong_please", { code: 500 });
const BAD_REQUEST = i18n.t("something_went_wrong_please", { code: 400 });
const NOT_FOUD = i18n.t("something_went_wrong_please", { code: 404 });
const NETWORK_ERROR = i18n.t("network_error");
// backendErrors
const ORGANIZATION_NOT_FOUND = i18n.t("workspace_not_found");

const axiosErrors = [UNEXPECTED_ERROR, BAD_REQUEST, NOT_FOUD];
const organizationErrors = [ORGANIZATION_NOT_FOUND];

// checking if errors are coming from API requests or no
// check if need to change route
// check if need to reload anything
const errorCheck = (error: Alert) => {
  const errorTypes: IError = {
    need_to_redirect: false,
    need_to_reset_organization: false,
    message: i18n.t("something_went_wrong_please"),
  };
  const { message, response } = error.alert;

  // if no response nothing that we can check
  // just return error
  if (message === NETWORK_ERROR) {
    errorTypes.need_to_redirect = true;
    errorTypes.message = ErrorMessages.NETWORK_ERROR_MESSAGE;
  } else if (response && response.data) {
    const { data } = response;
    if (organizationErrors.includes(data.error)) {
      errorTypes.need_to_reset_organization = true;
    } else if (axiosErrors.includes(message)) {
      errorTypes.message = message;
    }
  } else if (message) {
    errorTypes.message = message;
  }

  return errorTypes;
};

export default errorCheck;
