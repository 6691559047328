/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable prefer-template */
import React from "react";
import { Box, Button, Divider, Typography, useTheme } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import WorkspacePlan from "components/WorkspaceSettings/WorkspaceOverview/WorkspacePlan/WorkspacePlan";
import formatBytes from "utils/formatBytes";
import LoadingOverlay from "components/helpers/LoadingOverlay";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import { useOrganizationUsage } from "api/organizationService";
import { companyFeatures } from "company-config";
import axios from "axios";
import { addAlert } from "store/features/general/slice";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import UsageBar from "components/helpers/UsageBar";
import routePaths from "routes/routePaths";
import { useTranslation } from "react-i18next";
import { useOrg } from "../../../pages/WorkspaceSettings";

export interface PaymentSubscriptionInfoResponse {
  usage: {
    [k: string]: any;
  };
  usage_limits: {
    [k: string]: any;
  };
  plans?: any[];
}

const WorkspaceOveriew: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { org: currentOrg } = useOrg();
  const theme = useTheme();
  const { organizationUsage } = useOrganizationUsage(currentOrg.id);

  const createPortalSession = () => {
    axios
      .post("/api/payment/create_portal_session", {
        organization_id: currentOrg?.id,
      })
      .then(
        (res) => {
          const { data } = res;
          const { url } = data;
          window.location.href = url;
        },
        () => {
          dispatch(
            addAlert({
              severity: "error",
              autoHideDuration: 10000,
              alert: {
                message: t("contact_support"),
              },
            })
          );
        }
      );
  };

  if (!organizationUsage) {
    return <LoadingOverlay />;
  }

  return (
    <Box
      sx={{
        padding: "1rem",
        overflow: "auto",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {companyFeatures.settings.canUpgradePlans && (
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6">{t("plan")}</Typography>
            <div>
              {organizationUsage?.plans.length > 0 && (
                <Button
                  color="primary"
                  variant="outlined"
                  size="small"
                  sx={{
                    mr: "0.5rem",
                  }}
                  onClick={createPortalSession}
                  startIcon={<CreditCardOutlinedIcon fontSize="small" />}
                >
                  {t("billing_button")}
                </Button>
              )}
              <Button
                color="primary"
                component={RouterLink}
                variant="outlined"
                size="small"
                to={routePaths.workspaceBilling}
                startIcon={<LocalMallOutlinedIcon fontSize="small" />}
              >
                {t("purchase_button")}
              </Button>
            </div>
          </Box>
          <Divider sx={{ margin: "1rem 0 2rem 0" }} />
          <WorkspacePlan />
        </Box>
      )}
      <Box
        sx={{
          marginTop: "1rem",
        }}
      >
        <Box className="header">
          <Typography variant="h6">{t("usage")}</Typography>
          <Divider sx={{ margin: "1rem 0 2rem 0" }} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: "1rem",
            gap: "2rem",
          }}
        >
          <UsageBar
            loading={false}
            label={t("seats_label")}
            usingLabel={`${organizationUsage.usage.members}`}
            totalLabel={`${organizationUsage.usage_limits.members}`}
            data={[
              {
                label: `${t("seats_in_use")} (${
                  organizationUsage.usage.members
                })`,
                percent:
                  (100 * organizationUsage.usage.members) /
                  organizationUsage.usage_limits.members,
                color: theme.palette.info.dark,
              },
              {
                label: `${t("available_seats")} (${
                  organizationUsage.usage_limits.members -
                  organizationUsage.usage.members
                })`,
                percent:
                  100 -
                  (100 * organizationUsage.usage_limits.members) /
                    organizationUsage.usage.members,
                color: theme.palette.grey[200],
              },
            ]}
          />
          <UsageBar
            loading={false}
            label={t("storage_label")}
            usingLabel={`${formatBytes(
              organizationUsage.usage.storage.total_bytes
            )}`}
            totalLabel={`${formatBytes(
              organizationUsage.usage_limits.storage_bytes
            )}`}
            data={[
              {
                label: `${t("used_space")} (${formatBytes(
                  organizationUsage.usage.storage.total_bytes
                )})`,
                percent:
                  (100 * organizationUsage.usage.storage.total_bytes) /
                  organizationUsage.usage_limits.storage_bytes,
                color: theme.palette.info.dark,
              },
              {
                label: `${t("available_space")} (${formatBytes(
                  organizationUsage.usage_limits.storage_bytes -
                    organizationUsage.usage.storage.total_bytes
                )})`,
                percent:
                  (100 *
                    (organizationUsage.usage_limits.storage_bytes -
                      organizationUsage.usage.storage.total_bytes)) /
                  organizationUsage.usage_limits.storage_bytes,
                color: theme.palette.grey[200],
              },
            ]}
          />
          <UsageBar
            loading={false}
            label={t("message_credits")}
            usingLabel={`${
              organizationUsage.usage_limits.ai_credit_limit -
                organizationUsage.usage_limits.ai_credits <
              0
                ? 0
                : organizationUsage.usage_limits.ai_credit_limit -
                  organizationUsage.usage_limits.ai_credits
            }`}
            totalLabel={`${organizationUsage.usage_limits.ai_credit_limit}`}
            data={[
              {
                label: `${t("used_credits")} (${
                  organizationUsage.usage_limits.ai_credit_limit -
                    organizationUsage.usage_limits.ai_credits <
                  0
                    ? 0
                    : organizationUsage.usage_limits.ai_credit_limit -
                      organizationUsage.usage_limits.ai_credits
                })`,
                percent:
                  ((organizationUsage.usage_limits.ai_credit_limit -
                    organizationUsage.usage_limits.ai_credits) /
                    organizationUsage.usage_limits.ai_credit_limit) *
                  100,
                color: theme.palette.info.dark,
              },
              {
                label: t("available_credits", {
                  availableCredits: organizationUsage.usage_limits.ai_credits,
                }),
                percent:
                  100 -
                  ((organizationUsage.usage_limits.ai_credit_limit -
                    organizationUsage.usage_limits.ai_credits) /
                    organizationUsage.usage_limits.ai_credit_limit) *
                    100,
                color: theme.palette.grey[200],
              },
            ]}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default WorkspaceOveriew;
