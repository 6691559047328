import React, { forwardRef, useContext, useEffect, useRef } from "react";
import {
  Box,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  styled,
} from "@mui/material";
import { DocumentsContext } from "pages/Documents";
import { useSelector } from "react-redux";
import {
  selectCurrentOrganizationId,
  selectUser,
} from "store/features/session/slice";
import { useTreeItem } from "@mui/lab";
import { useDocumentCollections } from "api/documentCollectionService";
import { useUsers } from "api/userService";
import {
  DragTypes,
  defaultCollectionsTypes,
} from "models/components/Browse.models";
import { useDragLayer, useDrop } from "react-dnd";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import {
  BookmarkBorderOutlined,
  StarOutlineOutlined,
} from "@mui/icons-material";
import { isAdmin, isGuest } from "models/components/Permissions.models";
import clsx from "clsx";
import { companyFeatures } from "company-config";
import { useTranslation } from "react-i18next";

const CollectionContainer = styled(ListItemButton)(({ theme }) => ({
  "&.collection-item": {
    padding: "0 16px",
    minHeight: "30px",
    "&.Mui-selected": {
      background: theme.grey.light,
    },
    "& .MuiListItemIcon-root": {
      minWidth: "auto",
      marginRight: "1rem",
      paddingTop: 0,
      paddingBottom: 0,
      "& .MuiSvgIcon-root.MuiSvgIcon-colorPrimary": {
        height: "20px",
        width: "20px",
        marginRight: "3px",
        color: "rgba(0, 0, 0, 0.54)",
      },
    },
    "&.no-icon": {
      "& .MuiListItemIcon-root": {
        marginRight: "2.5rem",
      },
    },
    "& .special-icon": {
      marginRight: "1.25rem",
    },
    "& .context-menu": {
      opacity: 0,
      display: "flex",
      alignItems: "center",
      gap: "0.5rem",
      "&.open": {
        backgroundColor: theme.menu.selected,
        opacity: 1,
      },
    },
    "&:hover": {
      "& .context-menu": {
        opacity: 1,
      },
    },
  },
}));

const CollectionTreeItem = forwardRef(function CustomContent(props: any, ref) {
  const {
    classes,
    className,
    collectionLabel,
    nodeId,
    icon: iconProp,
    expansionIcon,
    displayIcon,
    expandCollection,
    selectedCollection,
    triggerStateUpdate,
    triggerCollectionUpdate,
    triggerCollectionDeletion,
    setMenuOpen,
  } = props;
  const { t } = useTranslation();
  const { selectedDocuments, draggableDocumentId, setDraggableDocumentId } =
    useContext(DocumentsContext);
  const user = useSelector(selectUser);
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const { expanded, handleSelection } = useTreeItem(nodeId);
  const { updateCollectionMutation, getCachedCollectionById } =
    useDocumentCollections(currentOrganizationId);
  const { users } = useUsers(currentOrganizationId);
  const currentUserRole = users?.find((u) => u.id === user?.id)?.role;
  const [currentCollection] = getCachedCollectionById(parseInt(nodeId, 10));
  const id = parseInt(nodeId, 10);
  const icon = iconProp || expansionIcon || displayIcon;
  const isCustom = defaultCollectionsTypes.includes(collectionLabel);
  const iconRef = useRef<HTMLDivElement>(null);
  const enableDocumentActions =
    companyFeatures.app.guests.enableDocumentActions ||
    !isGuest(currentUserRole);

  const handleSelectionClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    handleSelection(event);
    triggerStateUpdate("collection", id);
  };

  const { currentOffset } = useDragLayer((monitor) => ({
    currentOffset: monitor.getClientOffset(),
  }));

  const [{ isOver, canDrop }, dropRef] = useDrop({
    accept: DragTypes.DOCUMENT,
    drop: () => {
      if (canDrop && draggableDocumentId) {
        const [collection] = getCachedCollectionById(parseInt(nodeId, 10));
        if (collection && selectedDocuments) {
          // eslint-disable-next-line prefer-const
          let newDocumentIds: number[] = [];
          const selectedDocIds: number[] = selectedDocuments.map(
            (document) => document.id
          );
          // check if document in multiple document list, add all documents, otherwise add selected document
          if (selectedDocuments.length > 1) {
            if (selectedDocIds.some((docId) => docId === draggableDocumentId)) {
              newDocumentIds = [...newDocumentIds, ...selectedDocIds];
            } else {
              newDocumentIds = [...newDocumentIds, draggableDocumentId];
            }
          } else {
            newDocumentIds = [...newDocumentIds, draggableDocumentId];
          }
          updateCollectionMutation.mutate({
            collections: [
              {
                id: collection.id,
                document_ids: [...collection.document_ids, ...newDocumentIds],
              },
            ],
          });
          setDraggableDocumentId(undefined);
        }
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  // trigger collection expand on icon hover
  useEffect(() => {
    if (isOver && !expanded && iconRef.current && currentOffset) {
      const { top, left } = iconRef.current.getBoundingClientRect();
      const minY = top;
      const maxY = 20 + minY; // icon height 20px
      const minX = left;
      const maxX = 20 + minX; // icon width 20px
      if (
        currentOffset.x >= minX &&
        currentOffset.x <= maxX &&
        currentOffset.y >= minY &&
        currentOffset.y <= maxY
      ) {
        expandCollection(nodeId);
      }
    }
  }, [isOver, expanded, currentOffset]);

  const collectionMenu = [
    {
      icon: <ModeEditOutlineOutlinedIcon fontSize="small" />,
      label: t("edit"),
      index: 0,
      onClick: (event: any) => {
        event.stopPropagation();
        setMenuOpen(null);
        triggerCollectionUpdate(parseInt(nodeId, 10));
      },
    },
    {
      icon: <DeleteOutlineOutlinedIcon fontSize="small" />,
      label: t("delete"),
      index: 1,
      onClick: (event: any) => {
        event.stopPropagation();
        setMenuOpen(null);
        triggerCollectionDeletion(parseInt(nodeId, 10));
      },
    },
  ];

  const menuToUse = collectionMenu.filter((menu) => {
    if (
      menu.index === 1 &&
      currentCollection?.user_id !== user?.id &&
      !isAdmin(currentUserRole)
    ) {
      return false;
    }
    return true;
  });

  if (!enableDocumentActions && collectionLabel === t("read_later")) {
    return null;
  }

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <CollectionContainer
      className={clsx(className, "collection-item", {
        [classes.expanded]: expanded,
        "no-icon": !icon,
      })}
      key={id}
      selected={selectedCollection?.id === id}
      onClick={handleSelectionClick}
      ref={dropRef}
      style={canDrop && isOver ? { backgroundColor: "#ACE7D6" } : {}}
    >
      {!isCustom ? (
        <ListItemIcon
          onClick={(e: any) => {
            e.stopPropagation();
            expandCollection(nodeId);
          }}
          ref={iconRef}
        >
          {icon}
        </ListItemIcon>
      ) : (
        <>
          {collectionLabel === t("favorites") ? (
            <StarOutlineOutlined
              className="special-icon"
              fontSize="small"
              color="warning"
            />
          ) : collectionLabel === t("read_later") ? (
            <BookmarkBorderOutlined
              color="action"
              className="special-icon"
              fontSize="small"
            />
          ) : null}
        </>
      )}
      <ListItemText>
        <Typography variant="body2">{collectionLabel}</Typography>
      </ListItemText>
      {!isGuest(currentUserRole) && !isCustom && (
        <Box className="context-menu">
          {menuToUse.map((item) => (
            <IconButton
              size="small"
              onClick={(e) => {
                item.onClick(e);
              }}
            >
              {item.icon}
            </IconButton>
          ))}
        </Box>
      )}
    </CollectionContainer>
  );
});

export default CollectionTreeItem;
