import React, { useState } from "react";
import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { AICreateQuestion } from "models/api/response.types";
import aiCreateService, { useAICreateQuestions } from "api/aiCreateService";
import { useDispatch } from "react-redux";
import { addAlert } from "store/features/general/slice";
import { useTranslation } from "react-i18next";

const DeleteExtractedKnowledgeQuestionDialog: React.FC<{
  question?: AICreateQuestion;
  close: () => void;
}> = ({ question, close }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { removeCachedAICreateQuestion } = useAICreateQuestions(
    question?.project_id
  );
  const [loading, setLoading] = useState<boolean>(false);

  const deleteQuestion = () => {
    if (question) {
      setLoading(true);
      const { project_id, id } = question;
      aiCreateService
        .deleteAICreateQA(project_id, id)
        .then(() => {
          setLoading(false);
          removeCachedAICreateQuestion(question.id);
          close();
        })
        .catch(() => {
          setLoading(false);
          dispatch(
            addAlert({
              severity: "error",
              autoHideDuration: 5000,
              alert: {
                message: t("something_went_wrong_please"),
              },
            })
          );
        });
    }
  };

  return (
    <Dialog open={!!question} onClose={close} fullWidth maxWidth="sm">
      <DialogTitle>
        {t("delete_qa")}
        <IconButton onClick={close}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>{t("proceeding_with_deletion_warning")}</DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" size="medium" onClick={close}>
          {t("cancel")}
        </Button>
        <LoadingButton
          onClick={deleteQuestion}
          color="primary"
          size="medium"
          variant="contained"
          loading={loading}
        >
          {t("delete")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteExtractedKnowledgeQuestionDialog;
