import { Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import hexToHSL from "utils/hexToHSL";
import InlineTag from "components/Tags/InlineTagComponent";
import TagsEditDialog from "components/Dialogs/Tags/TagsEditDialog";
import { useTranslation } from "react-i18next";

type Tags = Array<{ id: number; color: string; name: string }>;

export interface TagProps {
  id: number;
  color: string;
  name: string;
}

const TagsListStandalone: React.FC<{
  tags: Tags;
  tagsCount: number;
  isUserPermitted: boolean;
  onEnter?: () => void;
  onLeave?: () => void;
}> = ({ tags, onEnter, onLeave, tagsCount, isUserPermitted }) => {
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);
  const [currentTag, setCurrentTag] = useState<TagProps>({
    id: 0,
    color: "",
    name: "",
  });
  const inlineTags = useMemo(() => {
    if (tags && tags.length > 0) {
      return tags
        .slice(0, tagsCount)
        .map((tag: { id: number; color: string; name: string }) => {
          if (tag) {
            const { l: luminosity } = hexToHSL(tag.color);
            const foreground = luminosity < 60 ? "#fff" : "#303030";
            return (
              <InlineTag
                key={tag.id}
                style={{
                  color: foreground,
                  backgroundColor: tag.color,
                  cursor: isUserPermitted ? "pointer" : "default",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (isUserPermitted) {
                    setOpenDialog(true);
                    setCurrentTag(tag);
                  }
                }}
                onMouseEnter={() => {
                  if (onEnter) {
                    onEnter();
                  }
                }}
                onMouseLeave={() => {
                  if (onLeave) {
                    onLeave();
                  }
                }}
              >
                <Typography sx={{ whiteSpace: "nowrap" }} variant="body2">
                  {tag.name}
                </Typography>
              </InlineTag>
            );
          }
          return <></>;
        });
    }
    return <></>;
  }, [tags]);
  return (
    <>
      {inlineTags}{" "}
      <Typography
        variant="body2"
        color="textSecondary"
        style={{
          whiteSpace: "nowrap",
          display: "inline-block",
        }}
      >
        {tags && tags.length > tagsCount
          ? `+ ${tags.length - tagsCount} ${t("tags_lowercase")}`
          : ""}
      </Typography>
      {openDialog && (
        <TagsEditDialog setOpenDialog={setOpenDialog} currentTag={currentTag} />
      )}
    </>
  );
};

export default TagsListStandalone;
