/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import Joyride, { CallBackProps } from "react-joyride";
import useTelemetry, { telemetryAction } from "utils/useTelemetry";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentOrganizationId,
  selectUser,
  setUser,
} from "store/features/session/slice";
import { authService } from "api";
import { useDocuments } from "api/documentService";
import { isSidebarOpen, setSideBarOpen } from "store/features/browser/slice";
import { useTranslation } from "react-i18next";
import OnboardingTooltip from "./Tooltip";

const AddDocumentsOnboarding: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const sideBarOpen = useSelector(isSidebarOpen);
  const { documents } = useDocuments(currentOrganizationId);
  const [runPageOnboarding, setRunPageOnboarding] = useState(
    !user?.meta?.citeOnboarding?.addDocuments?.pageOnboardingCompleted
  );
  const [runNextStepOnboarding, setRunNextStepOnboarding] = useState(false);
  const { logAction } = useTelemetry();
  const smScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const completeCurrentOnboarding = (action: string) => {
    if (user) {
      authService
        .updateUser(user.id, {
          meta: {
            ...user.meta,
            citeOnboarding: {
              ...user.meta.citeOnboarding,
              addDocuments: {
                ...user.meta.citeOnboarding.addDocuments,
                [action]: new Date().toISOString(),
              },
            },
          },
        })
        .then(({ data }) => {
          dispatch(setUser({ ...user, ...data }));
        })
        .catch((err) => err);
    }
  };

  useEffect(() => {
    if (
      user?.meta?.citeOnboarding?.addDocuments?.pageOnboardingCompleted &&
      user?.meta?.citeOnboarding?.addDocuments?.nextStepCompleted
    ) {
      completeCurrentOnboarding("completed");
    }
  }, [user]);

  useEffect(() => {
    if (
      user?.meta?.citeOnboarding?.addDocuments?.pageOnboardingCompleted &&
      documents &&
      documents.length > 0
    ) {
      if (smScreen && !sideBarOpen) {
        dispatch(setSideBarOpen(true));
        setTimeout(() => {
          setRunNextStepOnboarding(true);
        }, 400);
      } else {
        setRunNextStepOnboarding(true);
      }
    }
  }, [user, documents]);

  const pageOnboardingSteps = [
    {
      target: ".add-container",
      placement: "center" as const,
      disableBeacon: true,
      disableOverlayClose: true,
      disableCloseOnEsc: true,
      content: (
        <Box
          className="onboarding-step"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Typography variant="h6">{t("upload_import")}</Typography>
          <Typography variant="body1" fontWeight={500}>
            {t("upload_instruction")}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {t("import_options")}
          </Typography>
        </Box>
      ),
    },
  ];

  const pageOnboardingStepCallback = (data: CallBackProps) => {
    const { action, lifecycle } = data;
    if (action === "close" && lifecycle === "complete") {
      logAction(telemetryAction.onboarding_flow, {
        method: "add_document_page_onboarding_completed",
      });
      setRunPageOnboarding(true);
      completeCurrentOnboarding("pageOnboardingCompleted");
    }
  };

  const nextOnboardingSteps = [
    {
      target: ".petal-actions",
      placement: "right" as const,
      disableBeacon: true,
      disableOverlayClose: true,
      disableCloseOnEsc: true,
      content: (
        <Box
          className="onboarding-step"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Typography variant="body1" color="textSecondary">
            {t("ai_analysis_instruction")}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {t("check_other_features")}
          </Typography>
        </Box>
      ),
    },
  ];

  const nextStepCallback = (data: CallBackProps) => {
    const { action, lifecycle } = data;
    if (action === "close" && lifecycle === "complete") {
      logAction(telemetryAction.onboarding_flow, {
        method: "add_document_next_step__completed",
      });
      completeCurrentOnboarding("nextStepCompleted");
    }
  };

  return (
    <>
      <Joyride
        run={runPageOnboarding}
        steps={pageOnboardingSteps}
        tooltipComponent={OnboardingTooltip}
        callback={pageOnboardingStepCallback}
        floaterProps={{
          styles: {
            wrapper: {
              zIndex: 1501,
            },
          },
        }}
        styles={{
          options: {
            zIndex: 1500,
          },
        }}
      />
      <Joyride
        run={runNextStepOnboarding}
        steps={nextOnboardingSteps}
        tooltipComponent={OnboardingTooltip}
        callback={nextStepCallback}
        floaterProps={{
          styles: {
            wrapper: {
              zIndex: 1501,
            },
          },
        }}
        styles={{
          options: {
            zIndex: 1500,
          },
        }}
      />
    </>
  );
};

export default AddDocumentsOnboarding;
