import React, { useEffect, useState } from "react";
import {
  Box,
  Collapse,
  IconButton,
  InputBase,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import {
  SearchOutlined,
  ClearOutlined,
  HelpOutline,
  KeyboardArrowDown,
  Search,
} from "@mui/icons-material";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentOrganizationId } from "store/features/session/slice";
import {
  DocumentReadResponse,
  MultiDocumentSearchResponse,
} from "models/api/response.types";
import { SearchType, setIsSearch } from "store/features/browser/slice";
import { useTranslation } from "react-i18next";

const Wrapper = styled(Box)(({ theme }) => ({
  "& .search-input-container": {
    borderRadius: "4px",
    width: "100%",
    height: "36px",
    "& form": {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      flex: 1,
      padding: "0 0.5rem",
    },
    "& .button": {
      minWidth: 0,
    },
    border: `1px solid ${theme.grey.light}`,
    "&:hover": {
      border: `1px solid ${theme.grey.main}`,
    },
    background: theme.background.light,
    "& .search-input": {
      margin: "0 0.5rem",
      flex: 1,
      color: theme.palette.text.primary,
      "& input::placeholder": {
        opacity: 0.7,
      },
    },
  },
  "& .search-box": {
    width: "100%",
    marginTop: "1rem",
    borderLeft: `1px solid ${theme.grey.light}`,
    borderRight: `1px solid ${theme.grey.light}`,
    borderTopLeftRadius: "4px",
    "& .MuiCollapse-wrapperInner": {
      padding: "0.25rem 0.5rem",
      background: theme.notice.main,
      display: "flex",
      alignItems: "center",
      gap: "0.5rem",
      "& .search-text": {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
  },
  "& .search-type": {
    width: "6rem",
    fontSize: "0.875rem",
    height: "20px",
    margin: "7px 0 0",
    borderRadius: "0",
    borderLeft: `1px solid ${theme.grey.main}`,
    background: "none",
    color: theme.palette.primary.main,
    "& fieldset": {
      border: "none",
    },
    "& .MuiSelect-select": {
      padding: "0 0.5rem",
      textOverflow: "clip",
      lineHeight: "1.5",
    },
    "& .MuiSelect-icon": {
      right: "0",
      color: theme.palette.primary.main,
    },
  },
}));

const ContextSearchInput: React.FC<{
  setSearchedDocuments: (documents: DocumentReadResponse[] | undefined) => void;
  setSearching: (searching: boolean) => void;
  setSelectedCollectionId: (id: number) => void;
  setSearchTypeQuery: (type: SearchType) => void;
  setSearchQuery: (query: string) => void;
  searchQuery: string;
  searchTypeQuery: SearchType;
  searching: boolean;
}> = ({
  setSearchedDocuments,
  setSearching,
  setSelectedCollectionId,
  searchQuery,
  setSearchQuery,
  searchTypeQuery,
  setSearchTypeQuery,
  searching,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const [searchString, setSearchString] = useState("");
  const [searchType, setSearchType] = useState(SearchType.fulltext);
  const [meta, setMeta] = useState(true);
  const [text, setText] = useState(searchType === SearchType.meta);

  const cancelSearch = () => {
    setSearchString("");
    setSearchedDocuments(undefined);
    setSearchQuery("");
    setSearchType(SearchType.fulltext);
    setSearchTypeQuery(SearchType.fulltext);
    dispatch(setIsSearch(false));
  };

  const handleSubmit = (event?: React.FormEvent<HTMLFormElement>): void => {
    event?.preventDefault();
    if (searchString.length > 0 && currentOrganizationId) {
      if (searchType !== SearchType.semantic) {
        const url = `/api/document/search?q=${encodeURIComponent(
          searchString
        )}&organization_id=${currentOrganizationId}&meta=${meta}&text=${text}`;
        setSearching(true);
        axios
          .get<MultiDocumentSearchResponse>(url)
          .then(({ data }) => {
            setSearching(false);
            setSearchedDocuments(data.documents);
            setSearchQuery(searchString);
            setSearchTypeQuery(searchType);
            setSelectedCollectionId(-1);
            dispatch(setIsSearch(true));
          })
          .catch(() => {
            setSearching(false);
            setSearchedDocuments([]);
            setSelectedCollectionId(-1);
            dispatch(setIsSearch(true));
          });
      } else {
        const url = `/api/document/semantic_search?q=${encodeURIComponent(
          searchString
        )}&organization_id=${currentOrganizationId}`;
        setSearching(true);
        axios
          .get<MultiDocumentSearchResponse>(url)
          .then(({ data }) => {
            setSearching(false);
            setSearchedDocuments(data.documents);
            setSearchQuery(searchString);
            setSearchTypeQuery(searchType);
            setSelectedCollectionId(-1);
            dispatch(setIsSearch(true));
          })
          .catch(() => {
            setSearching(false);
            setSearchedDocuments([]);
            setSelectedCollectionId(-1);
            dispatch(setIsSearch(true));
          });
      }
    }
  };

  useEffect(() => {
    if (searchString.length > 0 && searchType !== searchTypeQuery) {
      handleSubmit();
    }
  }, [searchType]);

  return (
    <Wrapper>
      <Box className="search-input-container">
        <Box
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            padding: "0 0.5rem",
          }}
        >
          <form onSubmit={handleSubmit}>
            <IconButton
              size="small"
              onClick={() => handleSubmit()}
              aria-label="search"
            >
              <SearchOutlined fontSize="small" />
            </IconButton>
            <InputBase
              className="search-input"
              placeholder="Search documents"
              value={searchString}
              onChange={(e) => {
                if (e.target.value.length < 400) {
                  setSearchString(e.target.value);
                }
              }}
              margin="dense"
            />
            {searchString.length > 0 && (
              <IconButton
                className="button clear-button"
                type="button"
                aria-label="clear"
                size="small"
                onClick={cancelSearch}
              >
                <ClearOutlined fontSize="small" />
              </IconButton>
            )}
          </form>
          <Select
            className="search-type"
            value={searchType}
            IconComponent={KeyboardArrowDown}
            renderValue={(value: string) => value}
            onChange={(e) => {
              const newSearchType = e.target.value as SearchType;
              setSearchType(newSearchType);
              setText(newSearchType === SearchType.meta);
            }}
          >
            <MenuItem
              value={SearchType.fulltext}
              sx={{
                width: "9rem",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="body2">{t(SearchType.fulltext)}</Typography>
              <Tooltip
                title={t("full_text_search_description")}
                placement="bottom-start"
                arrow
              >
                <HelpOutline
                  fontSize="small"
                  sx={{
                    color: "#3d5a80",
                  }}
                />
              </Tooltip>
            </MenuItem>
            <MenuItem
              value={SearchType.meta}
              sx={{
                width: "9rem",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="body2">{t(SearchType.meta)}</Typography>
              <Tooltip
                title={t("metadata_search_description")}
                placement="bottom-start"
                arrow
              >
                <HelpOutline
                  fontSize="small"
                  sx={{
                    color: "#3d5a80",
                  }}
                />
              </Tooltip>
            </MenuItem>
            <MenuItem
              value={SearchType.semantic}
              sx={{
                width: "9rem",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="body2">{t(SearchType.semantic)}</Typography>
              <Tooltip
                title={t("semantic_search_description")}
                placement="bottom-start"
                arrow
              >
                <HelpOutline
                  fontSize="small"
                  sx={{
                    color: "#3d5a80",
                  }}
                />
              </Tooltip>
            </MenuItem>
          </Select>
        </Box>
      </Box>
      {searching ? (
        <Collapse className="search-box search-loading" in={searching}>
          <Typography variant="body2">{t("searching")}</Typography>
          <Box sx={{ padding: "5px", height: "30px" }}>
            <Search fontSize="small" />
          </Box>
        </Collapse>
      ) : (
        <Collapse className="search-box" in={!!searchQuery}>
          <Typography variant="body2" className="search-text">
            {t("results_for", { resultData: `"${searchQuery}"` })}
          </Typography>
          <IconButton size="small" onClick={cancelSearch}>
            <ClearOutlined fontSize="small" />
          </IconButton>
        </Collapse>
      )}
    </Wrapper>
  );
};

export default ContextSearchInput;
